import { observable, action, computed } from 'mobx'
import axios from 'axios';
import { API_HOST } from '../config/_entrypoint';
import uuidv4 from 'uuid/v4'
import history from '../history';
import moment from 'moment'

export class loanFilesStore {
    @observable loading = false
    @observable loadingID = false
    @observable loadingCreateLoanFile = false
    @observable loadingStatus = false
    @observable loadingUpload = false
    @observable loadingUpdate = false
    @observable loadingBillings = false
    @observable loadingFrontOffice = false
    @observable loadingBackOffice = false
    @observable loadingClient = false
    @observable loadingContributors = false
    @observable loadingLinks = false
    @observable loadingDocumentManagers = false
    @observable loadingBanks = false
    @observable loadingNotes = false
    @observable dossiersRegistry = new Map()
    @observable dossiersSuggestions = new Map()
    @observable loanFileId = {}
    @observable loanFileBillings = {}
    @observable loanFileFrontOffice = {}
    @observable loanFileBackOffice = {}
    @observable loanFileClient = {}
    @observable loanFileContributor = {}
    @observable loanFileLinks = {}
    @observable loanFileDocumentMangers = {}
    @observable loanFileBanks = {}
    @observable loanFileBankMeeting = {}
    @observable loanFileNotes = {}
    @observable sizeListAll = 0
    @observable sizeListTypes = 0
    @observable pagination = 1
    @observable nbContributor = 0
    @observable newContact = false
    @observable precision = false
    @observable FilterTypeDossiers = new Map()
    @observable searchResult = new Map()
    @observable statuses = new Map()
    @observable allStatuses = new Map()
    @observable typeId = ''
    @observable mainType = ''
    @observable stepId = ''
    @observable mainStep = ''
    @observable dateBefore = ''
    @observable dateAfter = ''
    @observable filter = ''
    @observable lastNumber = ''
    @observable paymentType = ''
    searchText = ''
    lastParams = ''
    @observable filterOrder = 0
    sizeListOnePage = 30;
    page = []

    constructor(stores) {
        this.stores = stores
    }

    @computed get getDossiers() {
        return Array.from(this.dossiersRegistry.values())
    }

    @computed get getSuggestions() {
        return Array.from(this.dossiersSuggestions.values())
    }

    @computed get getLoanFileId() {
        return this.loanFileId;
    }

    @computed get getSizeListAll() {
        return this.sizeListAll
    }

    @computed get getSizeListTypes() {
        return this.sizeListTypes
    }

    @computed get getPagination() {
        return this.pagination
    }

    @computed get getFilterTypeDossiers() {
        return Array.from(this.FilterTypeDossiers.values())
    }

    @computed get getSearchResultDossier() {
        return Array.from(this.searchResult.values())
    }

    @computed get getStatuses() {
        return Array.from(this.statuses.values())
    }

    @computed get getAllStatuses() {
        return Array.from(this.allStatuses.values())
    }

    @computed get getStepId() {
      return this.stepId
    }

    @computed get getTypeId() {
      return this.typeId
    }

    @action contactRadio = (val) => {
        this.newContact = val;
    }

    @action otherPrecision = (val) => {
        this.otherPrecision = val;
    }

    @action setDossiers = (dossiers) => {
        this.dossiersRegistry = new Map()
        dossiers && dossiers.forEach(data => {
            this.dossiersRegistry.set(data['@id'], data)
        })
    }

    @action setSuggestions = (dossiers) => {
      dossiers && dossiers.forEach(data => {
            this.dossiersSuggestions.set(data['@id'], data)
        })
    }

    @action setSizeListAll = (nb) => {
        this.sizeListAll = nb;
    }

    @action setPagination = (page) => {
        this.pagination = page;
        this.list();
    }

    @action setLoanFileId = (data) => {
        this.loanFileId = data
    }

    @action setSearchResult = (result) => {
      result && result.forEach(data => {
            this.searchResult.set(data['@id'], data);
        })
    }
    @action setStatuses = (result) => {
      result && result.forEach(data => {
            this.statuses.set(data['@id'], data);
        })
    }

    @action setAllStatuses = (result) => {
      result && result.forEach(data => {
           this.allStatuses.set(data['@id'], data);
      })
    }

    @action setTypeId = (id) => {
        this.typeId = id
        this.stepId = ''
        this.pagination = 1
        this.list()
    }

    @action setStepId = (id) => {
        this.stepId = id
        this.typeId = ''
        this.mainType = null
        this.pagination = 1
        this.list()
    }

    @action addSizeListypes = (nb) => {
      this.sizeListTypes += nb;
    }

    @action resetSizeListTypes = () => {
      this.sizeListTypes = 0;
    }


    @action clearSearchResult() {
        this.searchResult = new Map();
    }

    @action resetLoanFileId() {
        this.loanFileId = {}
        this.loanFileBillings = {}
        this.loanFileFrontOffice = {}
        this.loanFileBackOffice = {}
        this.loanFileClient = {}
        this.loanFileContributor = {}
        this.loanFileLinks = {}
        this.loanFileDocumentMangers = {}
        this.loanFileBanks = {}
        this.loanFileBankMeeting = {}
        this.loanFileNotes = {}
    }

    @action resetCache() {
        this.lastParams = ''
    }

    @action setPaymentType = (type) => {
      this.paymentType = type;
    }

    @action list = () => {
        this.loading = true
        this.params = ''
        if(this.stores.authStore.level === 3) {
          this.stores.picklist.bankResponse && this.stores.picklist.bankResponse.forEach(data => {
                if(this.stores.picklist.bankStateResponse[data.label]) {
                    this.params += 'banks.banks.response[]=' + data.label + '&'
                }
            })
            if (this.params === '') {
                this.params = 'banks.banks.response[]=null&'
            }
            if(this.stores.picklist.valueBankStateResponse) {
                this.params = ''
            }
            if (this.stores.authStore.values.bankId !== '') {
                this.params += 'banks.banks.bank.id='+this.stores.authStore.values.bankId+'&groups=role-bank&'
            } else {
                this.stores.authStore.getBankId()
            }
        }
        if (this.typeId !== '') {
            this.params += 'loanFileStatus.id=' + this.typeId + '&'
        }
        if (this.stepId !== '') {
            this.params += 'loanFileStatus.step.id=' + this.stepId + '&'
        }
        if (this.dateBefore !== '') {
            this.params += 'salesforceCreatedDate[before]='+moment(this.dateBefore).format("DD-MM-YYYY HH:mm:ss")+'&'
        }
        if (this.dateAfter !== '') {
            this.params += 'salesforceCreatedDate[after]='+moment(this.dateAfter).format("DD-MM-YYYY HH:mm:ss")+'&'
        }
        if (this.filter !== '') {
            this.params += 'isIndieOrGroup='+this.filter+'&'
        }
        if (this.searchText !== '') {
            this.params += 'MultiSearch_name='+this.searchText+'&'
        }
        if (this.paymentType !== '') {
          if (this.paymentType == 'Dossier payé'){
            this.params += 'billings.paymentSteps='+this.paymentType+'&'
          }else{
              this.params += 'billings.paymentSteps_not=Dossier payé'+ '&'
          }
        }
        if (this.filterOrder !== '') {
            switch (this.filterOrder) {
                case '0':
                  this.params += 'order[salesforceLastModifiedDate]=DESC&'
                  break;
                case '1':
                  this.params += 'order[salesforceLastModifiedDate]=ASC&'
                  break;
                case '2':
                  this.params += 'order[salesforceCreatedDate]=DESC&'
                  break;
                case '3':
                  this.params += 'order[salesforceCreatedDate]=ASC&'
                  break;
                case '4':
                  this.params += 'order[referenceNumber]=DESC&'
                  break;
                case '5':
                  this.params += 'order[referenceNumber]=ASC&'
                  break;
                default:
                  break;
              }
        }
        this.params += 'page='+this.pagination
        if (this.params !== this.lastParams) {
            this.stores.authStore.checkToken()
            axios.get(`${API_HOST}/loan_files?${this.params}`, { headers : this.stores.agent.headers()})
                .then(res => {
                    this.setSizeListAll(res.data['hydra:totalItems'])
                    this.setDossiers(res.data['hydra:member'])
                })
                .catch(err => { /* throw err */ })
                .finally(action(() => { this.loading = false }))
        } else {
            this.loading = false
        }
        this.lastParams = this.params
    }

    @action setFilterTypeDossiers(data) {
        this.resetSizeListTypes()
        this.FilterTypeDossiers.forEach(item=>{
          item.statuses = []
        })
        data && data.forEach(item => {
            this.FilterTypeDossiers.set(item['@id'], item)
            item.statuses.forEach( status =>{
              this.addSizeListypes(status['loanFilesCount']);
            })
        })
    }
    @action setLoaderOnLoanFile = (value) => {
        this.loadingID = value
        this.loadingBillings = value
        this.loadingFrontOffice = value
        this.loadingBackOffice = value
        this.loadingClient = value
        this.loadingContributors = value
        this.loadingLinks = value
        this.loadingDocumentManagers = value
        this.loadingBanks = value
        this.loadingNotes = value
    }

    @action onLoanFile = (id, i = 0) => {
        this.setLoaderOnLoanFile(true)
        this.stores.authStore.checkToken()
        if (this.stores.authStore.level >= 8) {
            axios.get(`${API_HOST}/loan_files/${id}`, { headers: this.stores.agent.headers() })
                .then(res => {
                  this.setLoanFileId(res.data)
                })
                .catch(err => {
                  this.setLoanFileId({
                    error: true,
                    name: "Erreur"
                  })
                })
                .finally(action(() => { this.loadingID = false }))
            axios.get(`${API_HOST}/loan_files/${id}/billings`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBillings = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBillings = false }))
            axios.get(`${API_HOST}/loan_files/${id}/front_office`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileFrontOffice = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingFrontOffice = false }))
            axios.get(`${API_HOST}/loan_files/${id}/back_office`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBackOffice = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingBackOffice = false }))
            axios.get(`${API_HOST}/loan_files/${id}/client`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileClient = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingClient = false }))
            axios.get(`${API_HOST}/loan_files/${id}/contributors`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileContributor = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingContributors = false }))
            axios.get(`${API_HOST}/loan_files/${id}/links`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileLinks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingLinks = false }))
            axios.get(`${API_HOST}/loan_files/${id}/document_managers`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileDocumentMangers = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingDocumentManagers = false }))
            axios.get(`${API_HOST}/loan_files/${id}/banks`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBanks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBanks = false }))
            axios.get(`${API_HOST}/loan_files/${id}/notes`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileNotes = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingNotes = false }))

        } else if (this.stores.authStore.level >= 4 && this.stores.authStore.level <= 6) {

            axios.get(`${API_HOST}/loan_files/${id}`, { headers: this.stores.agent.headers() })
                .then(res => { this.setLoanFileId(res.data) })
                .catch(err => { })
                .finally(action(() => { this.loadingID = false }))
            axios.get(`${API_HOST}/loan_files/${id}/billings`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBillings = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBillings = false }))
            axios.get(`${API_HOST}/loan_files/${id}/front_office`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileFrontOffice = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingFrontOffice = false }))
            axios.get(`${API_HOST}/loan_files/${id}/client`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileClient = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingClient = false }))
            axios.get(`${API_HOST}/loan_files/${id}/contributors`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileContributor = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingContributors = false }))
            axios.get(`${API_HOST}/loan_files/${id}/links`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileLinks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingLinks = false }))
            axios.get(`${API_HOST}/loan_files/${id}/document_managers`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileDocumentMangers = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingDocumentManagers = false }))
            axios.get(`${API_HOST}/loan_files/${id}/banks`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBanks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBanks = false }))

        } else if (this.stores.authStore.level === 3) {

            axios.get(`${API_HOST}/loan_files/${id}`, { headers: this.stores.agent.headers() })
                .then(res => { this.setLoanFileId(res.data) })
                .catch(err => { })
                .finally(action(() => { this.loadingID = false }))
            axios.get(`${API_HOST}/loan_files/${id}/front_office`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileFrontOffice = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingFrontOffice = false }))
            axios.get(`${API_HOST}/loan_files/${id}/client`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileClient = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingClient = false }))
            axios.get(`${API_HOST}/loan_files/${id}/links`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileLinks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingLinks = false }))
            axios.get(`${API_HOST}/loan_files/${id}/document_managers`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileDocumentMangers = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingDocumentManagers = false }))
            axios.get(`${API_HOST}/loan_files/${id}/banks`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBanks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBanks = false }))

        } else if (this.stores.authStore.level === 2) {

            axios.get(`${API_HOST}/loan_files/${id}`, { headers: this.stores.agent.headers() })
                .then(res => { this.setLoanFileId(res.data) })
                .catch(err => { })
                .finally(action(() => { this.loadingID = false }))
            axios.get(`${API_HOST}/loan_files/${id}/front_office`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileFrontOffice = res.data })
                .catch(err => { })
                .finally(action(() => { this.loadingFrontOffice = false }))
            axios.get(`${API_HOST}/loan_files/${id}/document_managers`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileDocumentMangers = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingDocumentManagers = false }))
            axios.get(`${API_HOST}/loan_files/${id}/banks`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBanks = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBanks = false }))
            axios.get(`${API_HOST}/loan_files/${id}/billings`, { headers: this.stores.agent.headers() })
                .then(res => { this.loanFileBillings = res.data['hydra:member'] })
                .catch(err => { })
                .finally(action(() => { this.loadingBillings = false }))
        }
    }

    @action createLoanFile = (newLoanFile, contact, contactId) => {
        this.loading = true
        this.loadingCreateLoanFile = true

        this.IDcontact = "/v2/contacts/"+contactId

        if (newLoanFile.$("IndeOrFran").value === "Indépendant") {
            if (contact !== null) {
                this.name = newLoanFile.$("IndeOrFran").value + ' - ' + newLoanFile.$("city").value + ' - ' + contact.data.contactTitle.label + ' ' + contact.data.firstName + ' ' + contact.data.lastName
            } else {
                this.name = newLoanFile.$("IndeOrFran").value + ' - ' + newLoanFile.$("city").value + ' - ' + newLoanFile.$("contactTitle").value + ' ' + newLoanFile.$("firstName").value + ' ' + newLoanFile.$("lastName").value
            }
            this.groupName = null
        } else {
            if (contact !== null) {
                this.name = newLoanFile.$("nomReseau").value + ' - ' + newLoanFile.$("city").value + ' - ' + contact.data.contactTitle.label + ' ' + contact.data.firstName + ' ' + contact.data.lastName
            } else {
                this.name = newLoanFile.$("nomReseau").value + ' - ' + newLoanFile.$("city").value + ' - ' + newLoanFile.$("contactTitle").value + ' ' + newLoanFile.$("firstName").value + ' ' + newLoanFile.$("lastName").value
            }
            this.groupName = newLoanFile.$("nomReseau").value
        }
        if (newLoanFile.$("TypeProject").value === "Autre") {
            this.loanFileType = newLoanFile.$("IndeOrFran").value + ' ' + newLoanFile.$("OtherTypeProject").value
        } else {
            this.loanFileType = newLoanFile.$("IndeOrFran").value + ' ' + newLoanFile.$("TypeProject").value
        }

        if (!newLoanFile.$("FrontOffice").value) {
            this.FrontOffice = "/v2/contacts/"+this.stores.authStore.id
        } else {
            this.FrontOffice = newLoanFile.$("FrontOffice").value
        }
        if (newLoanFile.$("StateLoanFile").value !== null) {
            this.status = newLoanFile.$("StateLoanFile").value
        } else {
            this.status = 'Candidat(e) à contacter' //Label du status par défaut lors de la création d'un dossier.
        }
        if (newLoanFile.$("DateBank").value !== null) {
            this.dateBank = newLoanFile.$("DateBank").value
        } else {
            this.dateBank = null
        }
        if (newLoanFile.$("contributors").value !== null) {
            this.contributors = Object.values(newLoanFile.$("contributors").value)
        } else {
            this.contributors = null
        }

        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_file_statuses?label=${this.status}`, { headers : this.stores.agent.headers()})
            .then(res => {
                axios.post(`${API_HOST}/loan_files`,
                    {
                        name: this.name,
                        loanFileType: this.loanFileType,
                        step: 1,
                        isDeleted: false,
                        bankGreetingEmail: false,
                        clientMutationId: uuidv4(),
                        salesforceId: null,
                        postalCode: newLoanFile.$("departmentLoanFile").value,
                        client: this.IDcontact,
                        loanFileStatus: res.data['hydra:member'][0]['@id'],
                        frontOffice: this.FrontOffice,
                        backOffice: null,
                        isIndieOrGroup: newLoanFile.$("IndeOrFran").value,
                        groupName: this.groupName,
                        projectType: newLoanFile.$("TypeProject").value,
                        projectTypeOther: newLoanFile.$("OtherTypeProject").value,
                        activityInfos: newLoanFile.$("SecteurActivity").value,
                        investGlobalAmount: parseInt(newLoanFile.$("AmountInvestment").value, 10),
                        contributionAmount: parseInt(newLoanFile.$("AmountContribution").value, 10),
                        loanAmount: parseInt(newLoanFile.$("AmountLoan").value, 10),
                        freeComment: newLoanFile.$("Comment").value,
                        retroScheduleOnPresentationBank: this.dateBank
                    }, { headers : this.stores.agent.headers()})
                    .then((value) => {
                        console.log('loan file create !', value)
                        if (this.contributors !== null) {
                            axios.post(`${API_HOST}/loan_file_contributor_managers`, {
                                loanFile: value.data["@id"],
                                salesforceId: null
                            }, { headers : this.stores.agent.headers()})
                            .then((value) => {
                                for (let i = 0; i < this.contributors.length; i++) {
                                    axios.post(`${API_HOST}/loan_file_contributors`, {
                                        contributorManager: value.data["@id"],
                                        contact: this.contributors[i]
                                    }, { headers : this.stores.agent.headers()})
                                    .then((value) => {
                                        console.log('contributor crée !! ', value)
                                    })
                                    .catch((error) => {
                                        console.log('Error API', error.response.data['hydra:description']);
                                        this.loadingCreateLoanFile = false
                                        this.stores.ui.addMessage('Erreur lors de la création des contributeurs', 'danger', 'all')
                                    })
                                }
                                this.stores.ui.addMessage('Dossier créé', 'succes', 'all');
                                this.loadingCreateLoanFile = false
                                history.goBack()
                            })
                            .catch((error) => {
                                console.log('Error API', error.response.data['hydra:description']);
                                this.loadingCreateLoanFile = false
                                this.stores.ui.addMessage('Erreur lors de la création du contributor managers', 'danger', 'all')
                            })
                            .finally(action(() => { this.loading = false }))
                        } else {
                            this.loading = false
                            this.stores.ui.addMessage('Dossier créé', 'succes', 'all');
                            this.loadingCreateLoanFile = false
                            history.goBack()
                        }
                    })
                    .catch((error) => {
                        console.log('Error API', error.response.data['hydra:description']);
                        this.loadingCreateLoanFile = false
                        this.stores.ui.addMessage('Erreur lors de la création du dossier', 'danger', 'all')
                    })
            })

            .finally(action(() => {
                this.loading = false
                this.loadingCreateLoanFile = false
            }))
        }

        @action listFilterTypeDossier = () => {

            let params = ''
            let sep  = '?'

            if (this.dateBefore !== '') {
              params += sep + 'statuses.loanFiles.salesforceCreatedDate[before]='+moment(this.dateBefore).format("DD-MM-YYYY HH:mm:ss")
              sep = '&'
            }
            if (this.dateAfter !== '') {
              params += sep + 'statuses.loanFiles.salesforceCreatedDate[after]='+moment(this.dateAfter).format("DD-MM-YYYY HH:mm:ss")
              sep = '&'
            }

            if (this.filter !== ''){
              params += sep + 'statuses.loanFiles.isIndieOrGroup='+this.filter
              sep = '&'
            }

            if (this.searchText !== '') {
              params += sep + 'MultiSearch_name='+this.searchText
              sep = '&'
            }

            this.stores.authStore.checkToken()
            axios.get(`${API_HOST}/loan_file_steps${params}`, { headers : this.stores.agent.headers()})
            .then(res => {
                this.setFilterTypeDossiers(res.data['hydra:member'])
            })
            .catch(err => { console.log('Error', err) })
        }

        @action search = (text = '') => {
            this.stores.authStore.checkToken()
            axios.get(`${API_HOST}/loan_files?MultiSearch_name=${text}`, { headers : this.stores.agent.headers()})
            .then(res => {
                this.searchResult.clear()
                this.setSearchResult(res.data['hydra:member'])
            })
            .catch(err => { /* throw err */ })
        }

        @action changeStatus = (id, loanfileStatusId) => {
            this.loadingStatus = true
            this.stores.authStore.checkToken()

            const loanFileStatusObject = {
              loanFileStatus: loanfileStatusId
            }

            axios.put(`${API_HOST}/loan_files/${id}`, JSON.stringify(loanFileStatusObject), { headers : this.stores.agent.headers()})
            .then(res => {
                axios.get(`${API_HOST}/loan_files/${res.data.id}`, { headers : this.stores.agent.headers()})
                .then(res => {
                    this.setLoanFileId(res.data)
                })
                .finally(action(() => { this.loadingStatus = false }))
            })
        }

        @action listStatuses = () => {
            this.stores.authStore.checkToken()
            axios.get(`${API_HOST}/loan_file_statuses`, { headers : this.stores.agent.headers()})
            .then(res => {
                this.setStatuses(res.data['hydra:member'])
            })
        }

    @action listAllStatuses = () => {
      this.stores.authStore.checkToken()
      axios.get(`${API_HOST}/loan_file_statuses?statuses=all`, { headers : this.stores.agent.headers()})
        .then(res => {
          this.setAllStatuses(res.data['hydra:member'])
        })
    }

    @action createLoanFileNote = (data, id) => {
        this.loadingUpdate = true
        this.stores.authStore.checkToken()
        axios.post(`${API_HOST}/loan_file_notes`,
          {
            title: "Notes sur le dossier",
            body: data.$("Comment").value,
            isPrivate: true,
            loanFile: '/v2/loan_files/' + id.id,
            needSync: true,
          }, { headers : this.stores.agent.headers()})
            .then(res => {
              this.refreshLoanFileNote(res.data.loanFile.replace("/v2/loan_files/", ""))
              this.stores.ui.toggleModifNote()
          })
          .finally(action(() => { this.loadingUpdate = false
        }))
    }

    @action updateLoanFileNotes = (data, id) => {
        this.loadingUpdate = true
        this.stores.authStore.checkToken()
        axios.put(`${API_HOST}/loan_file_notes/${id}`, {
            body: data.$("Comment").value
        }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.refreshLoanFileNote(res.data.loanFile.replace("/v2/loan_files/", ""))
                this.stores.ui.toggleModifNote()
            })
            .finally(action(() => { this.loadingUpdate = false }))
    }

    @action updateLoanFileBank = (data, id) => {
        this.loadingUpdate = true
        this.stores.authStore.checkToken()
        axios.put(`${API_HOST}/loan_file_banks/${id}`, {
            localContactLastName: data.$("firstName").value,
            localContactFirstName: data.$("lastName").value,
            localContactPhone: data.$("phone").value,
            localContactEmail: data.$("email").value,
            internalNotes: data.$("noteIntern").value,
            customersNotes: data.$("noteClient").value
        }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.stores.ui.toggleModifBank()
                this.refreshLoanFileBank(this.loanFileId.id)
            })
            .finally(action(() => { this.loadingUpdate = false }))
    }

    @action updateLoanFileBankManger = (data, id) => {
        this.loadingUpdate = true
        this.stores.authStore.checkToken()
        axios.put(`${API_HOST}/loan_file_bank_managers/${id}`, {
            infoToBankAfterMeetings: data.$("infoBank1").value,
            loanFileBanksEmail: data.$("infoBank2").value
        }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.refreshLoanFileBank(res.data.loanFile.replace("/v2/loan_files/", ""))
                this.stores.ui.modifInfoBank1 = false
                this.stores.ui.modifInfoBank2 = false
            })
            .finally(action(() => { this.loadingUpdate = false }))
    }

    @action refreshLoanFileNote = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files/${id}/notes`,{ headers : this.stores.agent.headers()})
            .then(res => {
                this.loanFileNotes = res.data['hydra:member']
            })
    }

    @action refreshLoanFileBank = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files/${id}/banks`,{ headers : this.stores.agent.headers()})
            .then(res => {
                this.loanFileBanks = res.data['hydra:member']
            })
    }

    @action listByType = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files?loanFileStatus.id=${id}`, { headers : this.stores.agent.headers()})
            .then(res => {
                this.setSizeListAll(res.data['hydra:totalItems'])
                this.setSuggestions(res.data['hydra:member'])
            })
    }

    @action uploadFile = (form) => {
        this.loadingUpload = true
        for (let i = 0; i < form.$("Upload").files.length; i++) {
            var data = new FormData()
            this.stores.authStore.checkToken()

            data.append('loanFile', form.$("LoanFileId").value)
            data.append('file', form.$("Upload").files[i])

            axios.post(`${API_HOST}/media_objects`, data, { headers : this.stores.agent.headers('files')})
              .then(res => {
                this.stores.ui.addMessage('Fichier envoyé', 'succes', 'all')
                this.stores.uploadForm.clear()
              })
              .catch(err => { console.log(err) })
              .finally(action(() => { this.loadingUpload = false }))
        }
    }

    @action updateLoanFileSchedule = (form) => {
        this.loadingUpdate = true
        this.stores.authStore.checkToken()
        if(form.$("loanFileId").value !== '' && form.$("dateLimitBankRetro").value !== '') {
            axios.put(`${API_HOST}/loan_files/${form.$("loanFileId").value}`,{
                retroScheduleOnOffersDeadline: form.$("dateLimitBankRetro").value
            }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.refreshLoanFile(res.data.id)
                this.stores.ui.modifRetroScheduleOnOffersDeadline = false
            })
            .finally(action(() => { this.loadingUpdate = false }))
        } else if(form.$("loanFileId").value !== '' && form.$("datePresentation").value !== '') {
            axios.put(`${API_HOST}/loan_files/${form.$("loanFileId").value}`,{
                retroScheduleOnPresentationBank: form.$("datePresentation").value
            }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.refreshLoanFile(res.data.id)
                this.stores.ui.modifRetroScheduleOnPresentationBank = false
            })
            .finally(action(() => { this.loadingUpdate = false }))
        } else if (form.$("bankManagerId").value !== '' && form.$("dateLimitBank").value !== '') {
                axios.put(`${API_HOST}/loan_file_bank_managers/${form.$("bankManagerId").value}`,{
                  bankProposalsDeadlineDate: form.$("dateLimitBank").value
                }, { headers : this.stores.agent.headers()})
                .then(res => {
                  this.refreshLoanFileBanks(this.loanFileId.id)
                  this.stores.ui.modifOffersDeadline = false
                })
                .finally(action(() => { this.loadingUpdate = false }))
        }
        else if (form.$("billingsId").value !== '' && form.$("dateDeblock").value !== '') {
                axios.put(`${API_HOST}/loan_file_billings/${form.$("billingsId").value}`,{
                    fundsReleasedDate: form.$("dateDeblock").value
                }, { headers : this.stores.agent.headers()})
                .then(res => {
                    this.refreshLoanFileBillings(this.loanFileId.id)
                    this.stores.ui.modifFundsReleasedDate = false
                })
                .finally(action(() => { this.loadingUpdate = false }))
        }
    }

    @action refreshLoanFile = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files/${id}`,{ headers : this.stores.agent.headers()})
            .then(res => {
                this.setLoanFileId(res.data)
            })
    }

    @action refreshLoanFileBillings = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files/${id}/billings`,{ headers : this.stores.agent.headers()})
            .then(res => {
                this.loanFileBillings = res.data['hydra:member']
            })
    }

    @action refreshLoanFileBanks = (id) => {
        this.stores.authStore.checkToken()
        axios.get(`${API_HOST}/loan_files/${id}/banks`,{ headers : this.stores.agent.headers()})
            .then(res => {
                this.loanFileBanks = res.data['hydra:member']
            })
    }

    @action confirmThanks = (id) => {
        this.stores.authStore.checkToken()
        axios.put(`${API_HOST}/loan_files/${id}`, {
            bankGreetingEmail : true
        }, { headers : this.stores.agent.headers()})
            .then(res => {
                this.refreshLoanFile(res.data.id)
            })
    }

}

export default loanFilesStore
