import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";
import Message from '../commons/Message'

@inject('user')
@observer
class PasswordLostPage extends Component {

  onChangeEmail = e => this.props.user.setEmail(e.target.value);

  onClick = (e) => {
    e.preventDefault();
    if (this.props.user.email !== '') {
      this.props.user.forgotPassword()
    }
  };

  render() {
    return (
      <React.Fragment>
        <div id="login-background">
          <div id="sidebar">
            <div className="head">
              <img src='./img/logo_unicolor.svg' alt='Logo Pr€t Pro' />
            </div>
            <div className="color-bar"></div>
            <form className="login-content">
              <Message zone='login' close={true} />
              <h1>RÉINITIALISATION DU MOT DE PASSE</h1>
              <label>E-mail</label>
              <input type="email" onChange={this.onChangeEmail} required />

              <button onClick={this.onClick} type="submit" className="button button-primary button-login">Envoyer ma demande</button>
              <Link to="/login" className="link">Annuler</Link>
            </form>
          </div>
        </div>
        <div className="color-bar"></div>
      </React.Fragment>
    );
  }
}

export default PasswordLostPage;
