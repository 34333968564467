import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'
import dvr from 'mobx-react-form/lib/validators/DVR'

const plugins = { dvr: dvr(validatorjs) }

const fields = {
  FrontOffice: {
    name: 'Contact',
    id: 'contact-frontOffice',
    label: 'Contact',
    rules: 'string',
    default: 'nulll'
  },
  choiceLoanFile: {
    name: 'choiceLoanFile',
    id: 'choiceLoanFile',
    label: 'Que souhaitez-vous sur ce dossier ?',
    rules: 'string'
  },
  PrecisionsApporteur: {
    name: 'PrecisionsApporteur',
    id: 'PrecisionsApporteur',
    label: 'Précisions sur ma demande',
    rules: 'string'
  },
  contactTitle: {
    name: 'contactTitle',
    id: 'contactTitle',
    label: 'Civilité',
    rules: 'required|string'
  },
  firstName: {
    name: 'firstName',
    id: 'firstName',
    label: 'Prénom',
    rules: 'required|string'
  },
  lastName: {
    name: 'lastName',
    id: 'lastName',
    label: 'Nom',
    rules: 'required|string'
  },
  mobilePhone: {
    name: 'mobilePhone',
    id: 'mobilePhone',
    label: 'Téléphone mobile',
    rules: 'required|string'
  },
  email: {
    name: 'email',
    id: 'email',
    label: 'Email',
    rules: 'required|email'
  },
  societe: {
    name: 'societe',
    id: 'societe',
    label: 'Société',
    rules: 'string'
  },
  department: {
    name: 'department',
    id: 'department',
    label: 'Département du contact',
    rules: 'required|string'
  },
  functionInteger: {
    name: 'functionInteger',
    id: 'functionInteger',
    label: 'Fonction du contact',
    rules: 'required|string'
  },

  city: {
    name: 'city',
    id: 'city',
    label: "Ville d'implantation",
    rules: 'required|string'
  },
  departmentLoanFile: {
    name: 'departmentLoanFile',
    id: 'departmentLoanFile',
    label: 'Département du projet',
    rules: 'required|string'
  },
  IndeOrFran: {
    name: 'IndeOrFran',
    id: 'IndeOrFran',
    label: 'Indépendant ou en franchise',
    rules: 'required|string'
  },
  nomReseau: {
    name: 'nomReseau',
    id: 'nomReseau',
    label: 'Si franchise, nom du réseau',
    rules: 'string'
  },
  TypeProject: {
    name: 'TypeProject',
    id: 'TypeProject',
    label: 'Type de projet',
    rules: 'required|string'
  },
  OtherTypeProject: {
    name: 'OtherTypeProject',
    id: 'OtherTypeProject',
    label: 'Autre type de projet',
    rules: 'string'
  },
  SecteurActivity: {
    name: 'SecteurActivity',
    id: 'SecteurActivity',
    label: "Secteur d'activité",
    rules: 'required|string'
  },
  DetailActivity: {
    name: 'DetailActivity',
    id: 'DetailActivity',
    label: "Précision sur l'activité",
    rules: 'string'
  },
  DateBank: {
    name: 'DateBank',
    id: 'DateBank',
    label: 'Date approximative de présentation en banque',
    rules: 'string',
    type: 'hidden'
  },
  StateLoanFile: {
    name: 'StateLoanFile',
    id: 'StateLoanFile',
    label: 'Etat du dossier',
    rules: 'string',
    type: 'hidden'
  },
  AmountInvestment: {
    name: 'AmountInvestment',
    id: 'AmountInvestment',
    label: "Montant global de l'investissement",
    rules: 'string'
  },
  AmountContribution: {
    name: 'AmountContribution',
    id: 'AmountContribution',
    label: "Montant de l'apport",
    rules: 'string'
  },
  AmountLoan: {
    name: 'AmountLoan',
    id: 'AmountLoan',
    label: "Montant de l'emprunt souhaité",
    rules: 'string'
  },
  SendPresentation: {
    name: 'SendPresentation',
    id: 'SendPresentation',
    label: 'Envoi de notre présentation ?',
    rules: 'required|string'
  },
  SendLoanFile: {
    name: 'SendLoanFile',
    id: 'SendLoanFile',
    label: 'Envoi de la liste des documents ?',
    rules: 'required|string'
  },
  Comment: {
    name: 'Comment',
    id: 'Comment',
    label: 'Commentaire libre',
    rules: 'string'
  }
}

const hooks = {
  onSuccess(form) {
    console.log('onSuccess Dossiers', form)
    // if (this.stores.dossiers.newContact === false) {
    //   console.log('this.stores.dossiers.newContact === FALSE', this.stores.dossiers.newContact);
    //   this.stores.dossiers.createLoanFile(form);
    // } else {
    this.stores.dossiers.loadingCreateLoanFile = true
    this.stores.contacts.createContact(form)
    // }
  },
  onError(form) {
    console.log('onError Dossiers', form)
  }
}
validatorjs.useLang('fr')

class LoanFilesFormStore extends MobxReactForm {
  stores

  constructor(stores) {
    super({ fields }, { plugins, hooks })
    this.stores = stores
  }
}

export default LoanFilesFormStore
