import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

@inject('contacts', 'authStore')
@observer
class ContactTable extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
  }

  onClick(contact) {
    this.props.contacts.setContactsId(contact)
    this.props.history.push({ pathname: '/contacts/' + contact.id })
  }

  render() {
    const { getContact, authStore } = this.props
    return (
      <React.Fragment>
        <section id="tableau">
          <table className="prp-table">
            <thead>
              <tr>
                {authStore.level > 6 && <th></th>}
                <th>Nom</th>
                {authStore.level > 6 && <th>Fonction</th>}
                <th>E-mail</th>
                <th>Mobile</th>
                <th>Téléphone</th>
              </tr>
            </thead>
            <tbody>
              {getContact &&
                getContact.map((contact, idx) => (
                  <React.Fragment key={idx}>
                    <tr className="active tablerow">
                      {authStore.level >= 6 && (
                        <td className="tablerow-actions">
                          {contact.contactOwner && (
                            <span
                              title="Ce contact a un mandataire propriétaire"
                              onClick={() => this.onClick(contact)}
                            >
                              <span className="fa fa-lock"></span>
                            </span>
                          )}
                          {(authStore.level === 10 ||
                            (contact &&
                              contact.contactOwner &&
                              contact.contactOwner.id === authStore.id)) && (
                            <Link
                              to={'/contacts/update/' + contact.id}
                              title="Modifier le contact"
                            >
                              <span className="fa fa-pencil"></span>
                            </Link>
                          )}
                        </td>
                      )}
                      <td
                        className="title"
                        onClick={() => this.onClick(contact)}
                      >
                        {' '}
                        {contact.firstName && contact.firstName}{' '}
                        {contact.lastName && ' ' + contact.lastName}{' '}
                      </td>
                      {authStore.level >= 6 && (
                        <td
                          className="infos"
                          onClick={() => this.onClick(contact)}
                        >
                          {' '}
                          {contact.function && contact.function.label}{' '}
                        </td>
                      )}
                      <td
                        className="infos email"
                        onClick={() => this.onClick(contact)}
                      >
                        {' '}
                        {contact.email}{' '}
                      </td>
                      <td
                        className="infos tel"
                        onClick={() => this.onClick(contact)}
                      >
                        {' '}
                        {contact.mobilePhone}{' '}
                      </td>
                      <td
                        className="infos tel"
                        onClick={() => this.onClick(contact)}
                      >
                        {' '}
                        {contact.phone}{' '}
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
            </tbody>
          </table>
        </section>
      </React.Fragment>
    )
  }
}

export default ContactTable
