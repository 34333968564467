import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import ContentText from '../../commons/ContentText'

@inject('ui', 'dossiers', 'authStore')
@observer
class BankSingle extends Component {

    constructor(props) {
        super(props)
        this.state = {
          retenuesBank: '' ,
          opened: false
        }
        this.retenuesSlugs = this.retenuesSlugs.bind(this)
    }

    onHeaderClick = () => {
      this.setState({
        opened: !this.state.opened
      })
    }

    retenuesSlugs(banks) {
      banks && banks[0] && banks[0].banks.map((bank, idx) =>
        bank.response === 'Banque retenue' && (this.state.retenuesBank.id === undefined || this.state.retenuesBank.id !== bank.id) &&
        this.setState({ retenuesBank: bank })
      )
    }

    successfulProposal = (data, bankId, append = '') => {
        if(this.state.retenuesBank && this.state.retenuesBank.id !== bankId) {
            if (append.length) append = ' ' + append;
            if (data){
              return (
                <td>{data + append}</td>
              )
            }else{
              return <td></td>
            }
        }
    }

    render() {
        const { label, banks, loading } = this.props
        const { opened } = this.state
        let contentClasses = 'section-content'
        if (opened) {
          contentClasses = contentClasses + ' open'
        }

        this.retenuesSlugs(banks)

        return (
            <React.Fragment>
                {banks && banks[0] && banks[0].banks.map((bank, idx) =>
                    <React.Fragment key={idx}>
                        {bank.bank && this.props.authStore.values.bankId === parseInt(bank.bank.replace("/v2/banks/", ""), 10) &&

                            <section className={contentClasses}>

                                <label className="accordion__heading" htmlFor="accordion-bank-single" onClick={this.onHeaderClick}>
                                    <i className="prp-ext-bank"></i>
                                    {label}
                                    {' (  ' + bank.response + ' )'}
                                    {banks[0] === undefined && loading && <div className="loader visible"></div>}
                                </label>

                                <div className="accordion__content">
                                    <div className="data">
                                        <table className="table-data">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Votre proposition</th>
                                                    <th>Proposition retenue</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Montant du financement</td>
                                                    <td>{bank.fundedAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bank.fundedAmount)}</td>
                                                    {this.state.retenuesBank.id && this.state.retenuesBank.id !== bank.id ?
                                                        <React.Fragment>
                                                            <td>{this.state.retenuesBank.fundedAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.retenuesBank.fundedAmount)}</td>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            {this.state.retenuesBank.id === bank.id ?
                                                                <td rowSpan="8" className="center">Votre proposition !</td>
                                                                :
                                                                <td rowSpan="8" className="center">Pas encore de proposition<br />bancaire retenue</td>
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td>Taux proposé</td>
                                                    <td>{bank.proposedRate && bank.proposedRate + '%'}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.proposedRate, bank.id, '%')}
                                                </tr>
                                                <tr>
                                                    <td>Délégation assurances ?</td>
                                                    <td>{bank.insuranceDelegation}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.insuranceDelegation, bank.id)}
                                                </tr>
                                                <tr>
                                                    <td>Taux assurances</td>
                                                    <td>{bank.insuranceRate  + '%'}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.insuranceRate, bank.id, '%')}
                                                </tr>
                                                <tr>
                                                    <td>Taux CB</td>
                                                    <td>{bank.proposedCreditCardRate  + '%'}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.proposedCreditCardRate, bank.id, '%')}
                                                </tr>
                                                <tr>
                                                    <td>Infos sur taux CB</td>
                                                    <td>{bank.creditCardRateInfos}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.creditCardRateInfos, bank.id)}
                                                </tr>
                                                <tr>
                                                    <td>Garanties proposées</td>
                                                    <td>{bank.proposedGuarantees}</td>
                                                    {this.successfulProposal(this.state.retenuesBank.proposedGuarantees, bank.id)}
                                                </tr>
                                                <tr>
                                                    <td>Frais de dossier</td>
                                                    <td>{bank.fees && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bank.fees)}</td>
                                                    {this.successfulProposal(new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(this.state.retenuesBank.fees), bank.id)}
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div className="row-contact">
                                            <div className="contact-element">
                                                <span className="contact-name">Nom du contact local</span>
                                                <ContentText text={bank.localContactLastName} />
                                            </div>
                                            <div className="contact-element">
                                                <span className="contact-name">Prénom du contact local</span>
                                                <ContentText text={bank.localContactFirstName} />
                                            </div>
                                        </div>
                                        <div className="row-contact">
                                            <div className="contact-element">
                                                <span className="contact-name">Email du contact local</span>
                                                <ContentText text={bank.localContactEmail} />
                                            </div>
                                            <div className="contact-element">
                                                <span className="contact-name">Téléphone du contact local</span>
                                                <ContentText text={bank.localContactPhone} />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </section>
                        }
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default BankSingle;
