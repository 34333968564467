import agent from './agent'
import AuthStore from './AuthStore'
import UIStore from './UIStore'
import UserStore from './UserStore'
import { HeaderStore } from './HeaderStore'
import { loanFilesStore } from './loanFilesStore'
import { ContactsStore } from './ContactsStore'
import { PicklistStore } from './PicklistStore'
import { SurveyStore } from './SurveyStore'
import { HomeStore } from './HomeStore'
import ContactsFormStore from './ContactForm/ContactsFormStore'
import LoanFilesFormStore from './LoanFileForm/LoanFilesFormStore'
import Upload from './Upload'

class RootStore {
  constructor() {
    this.ui = new UIStore(this)
    this.user = new UserStore(this)
    this.header = new HeaderStore(this)
    this.dossiers = new loanFilesStore(this)
    this.contacts = new ContactsStore(this)
    this.picklist = new PicklistStore(this)
    this.homeStore = new HomeStore(this)
    this.authStore = new AuthStore(this)
    this.contactsForm = new ContactsFormStore(this)
    this.dossiersForm = new LoanFilesFormStore(this)
    this.surveyStore = new SurveyStore(this)
    this.uploadForm = new Upload(this)
    this.agent = new agent.Agent(this)
  }
}

export default RootStore
