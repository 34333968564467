import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react"
import _ from 'lodash';
import moment from 'moment'
import Status from '../../LoanFiles/Status'
import helpers from "../../../helpers";

@inject('contacts', 'header', 'authStore', 'ui', 'dossiers')
@observer
class DetailContact extends Component {

  componentDidMount() {
    this.props.contacts.oneContact(this.props.match.params.contactId);
    this.props.dossiers.list()
  }

  componentDidUpdate() {
    const { getContactsId } = this.props.contacts
    if (getContactsId.firstName === undefined && getContactsId.lastName === undefined) {
      document.title = "Chargement..."
      this.props.header.setHeaderPage("Chargement...", 'desktop')
    } else if (this.props.header.text !== getContactsId.firstName + ' ' + getContactsId.lastName) {
      document.title = getContactsId.firstName + ' ' + getContactsId.lastName
      this.props.header.setHeaderPage(getContactsId.firstName + ' ' + getContactsId.lastName, 'desktop')
    }
    if (getContactsId.function && getContactsId.function.label) {
      if(this.props.ui.suggestions === '') {
        this.props.ui.setSuggestions(getContactsId.function)
      }
    } else if (this.props.ui.suggestions !== '') {
      this.props.ui.suggestions = ''
    }
  }

  componentWillUnmount() {
    this.props.ui.suggestions = null
  }

  render() {

    const { getContactsId, loading } = this.props.contacts
    const { authStore } = this.props
    const { getDossiers } = this.props.dossiers

    return (
      <React.Fragment>
        <div id="detail-contact" className="for-mobile">
          {getContactsId && !getContactsId.id && <div className="empty-info">Chargement en cours...</div>}
          {getContactsId && getContactsId.id &&
            <React.Fragment>
              <div className="detail-contact-container">
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Prénom</span>
                    <span className="contact-content">{getContactsId.firstName}</span>
                  </div>
                  <div className="contact-element">
                    <span className="contact-name">Nom</span>
                    <span className="contact-content">{getContactsId.lastName}</span>
                  </div>
                </div>
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Mobile</span>
                    <span className="contact-content">{getContactsId.mobilePhone ? getContactsId.mobilePhone : '-'}</span>
                  </div>
                  <div className="contact-element">
                    <span className="contact-name">Téléphone</span>
                    <span className="contact-content">{getContactsId.phone ? getContactsId.phone : '-'}</span>
                  </div>
                </div>
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Email</span>
                    <span className="contact-content">{getContactsId.email}</span>
                  </div>
                </div>
                <div className="row-contact">
                  {authStore.level >= 8 &&
                    <div className="contact-element">
                      <span className="contact-name">Fonction</span>
                      <span className="contact-content">{getContactsId.function ? getContactsId.function.label : '-'}</span>
                    </div>
                  }
                  <div className="contact-element">
                    <span className="contact-name">Compte</span>
                    <span className="contact-content">{getContactsId.account ? getContactsId.account.name : '-'}</span>
                  </div>
                </div>
                {authStore.level >= 8 &&
                  <div className="row-contact">
                    <div className="contact-element">
                      <span className="contact-name">Mandataire</span>
                      <span className="contact-content">{getContactsId.contactOwner ? getContactsId.contactOwner.firstName + ' ' + getContactsId.contactOwner.lastName : '-'}</span>
                    </div>
                  </div>
                }
                {(this.props.authStore.level === 2 || (this.props.authStore.level >= 4 && this.props.authStore.level <= 6)) && !this.props.dossiers.loading &&
                  <div className="dossiers">
                    <h3>Les dossiers de {getContactsId.firstName + ' ' + getContactsId.lastName} :</h3>
                    <div className="content">
                      {getDossiers && getDossiers.length !== 0 ?
                      <React.Fragment>
                        <section id="tableau">
                          <table className="prp-table">
                            <thead>
                              <tr>
                                {this.props.authStore.level >= 4 && this.props.authStore.level <= 6 && //Apporteur
                                  <React.Fragment>
                                    <th>Nom</th>
                                    <th>N&deg;&nbsp;dossier</th>
                                    <th>Etat&nbsp;du&nbsp;dossier</th>
                                    {/* <th>Dossier&nbsp;financé&nbsp;le</th> */}
                                    <th>Date&nbsp;réponse&nbsp;bancaire</th>
                                    <th>Status</th>
                                  </React.Fragment>
                                }
                                {this.props.authStore.level === 2 && //Client
                                  <React.Fragment>
                                    <th>Nom</th>
                                    <th>Etat&nbsp;du&nbsp;dossier</th>
                                    {/* <th>Dossier&nbsp;financé&nbsp;le</th> */}
                                    <th>Date&nbsp;réponse&nbsp;bancaire</th>
                                    <th>Status</th>
                                  </React.Fragment>
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {getDossiers.map((dossier, idx) =>
                                <React.Fragment key={idx}>
                                  {this.props.authStore.level >= 8 &&
                                    <tr className="active tablerow">
                                      <td className="tablerow-actions">
                                        {_.get(dossier, 'loanFileStatus.label') === "Financé" &&
                                          <span title="Dossier financé non payé">
                                            <span className="fa fa-euro"></span>
                                          </span>
                                        }
                                      </td>
                                      <td className="title" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {dossier.name} </td>
                                      <td className="infos no-dossier" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {dossier.referenceNumber} </td>
                                      <td className="infos" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {dossier.loanFileType} </td>
                                      <td className="status" title={helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label'))} onClick={() => this.props.history.push("/dossiers/" + dossier.id)}>
                                        <div className="status-small">
                                          <div className="progress-status">
                                            <Status step={_.get(dossier, 'step')} />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                  {this.props.authStore.level <= 8 && this.props.authStore.level >= 2 &&
                                    <tr className="active tablerow">
                                      <td className="title" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {dossier.name} </td>
                                      {this.props.authStore.level >= 4 && this.props.authStore.level <= 6 &&
                                        <td className="infos no-dossier" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {dossier.referenceNumber} </td>
                                      }
                                      <td className="infos" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label'))} </td>

                                      <React.Fragment>
                                        {dossier.step === 4 ?
                                          <td className="infos" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {_.get(dossier, 'fundedDate') && moment(dossier.fundedDate).format("MM-DD-YYYY")} </td>
                                          :
                                          <td className="infos" onClick={() => this.props.history.push("/dossiers/" + dossier.id)}> {_.get(dossier, 'retroScheduleOnOffersDeadline') && moment(dossier.retroScheduleOnOffersDeadline).format("MM-DD-YYYY")} </td>
                                        }
                                      </React.Fragment>

                                      <td className="status" title={helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label'))} onClick={() => this.props.history.push("/dossiers/" + dossier.id)}>
                                        <div className="status-small">
                                          <div className="progress-status">
                                            <Status step={_.get(dossier, 'step')} />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  }
                                </React.Fragment>
                              )}
                            </tbody>
                          </table>
                        </section>
                      </React.Fragment>
                      :
                      <div className="empty-info">Aucun dossier de financement</div>
                      }
                    </div>
                  </div>
                }
                {(this.props.authStore.level === 10 || (getContactsId && getContactsId.contactOwner && getContactsId.contactOwner.id === this.props.authStore.id)) && !loading && <Link to={"/contacts/update/" + getContactsId.id} className="button button-primary">Modifier</Link>}
                {(this.props.authStore.level === 10 || (getContactsId && getContactsId.contactOwner && getContactsId.contactOwner.id === this.props.authStore.id)) && loading && <Link to={"/contacts/update/" + getContactsId.id} className="button button-primary">Modifier<div className="loader visible"></div></Link>}
              </div>
            </React.Fragment>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default DetailContact;
