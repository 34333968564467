import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
  contactTitle: {
    name: "contactTitle",
    id: "contactTitle",
    label: "Civilité",
    rules: "required|string"
  },
  firstName: {
    name: "firstName",
    id: "firstName",
    label: "Nom",
    rules: "required|string"
  },
  lastName: {
    name: "lastName",
    id: "lastName",
    label: "Prénom",
    rules: "required|string"
  },
  mobilePhone: {
    name: "mobilePhone",
    id: "mobilePhone",
    label: "Téléphone mobile",
    rules: "required|string"
  },
  email: {
    name: "email",
    id: "email",
    label: "Email",
    rules: "required|email"
  },
  societe: {
    name: "societe",
    id: "societe",
    label: "Société",
    rules: "string"
  },
  department: {
    name: "department",
    id: "department",
    label: "Département du contact",
    rules: "required|string"
  },
  functionInteger: {
    name: "functionInteger",
    id: "functionInteger",
    label: "Fonction du contact",
    rules: "required|string"
  },
  loanfile: {
    name: "loanfile",
    id: "loanfile",
    label: "loanfile",
    value : false,
    type: "hidden"
  }
};

const hooks = {
  onSuccess(contactsFormStore) {
    this.stores.contacts.createContact(contactsFormStore);
  },
  onError(form) {
  }
};
validatorjs.useLang('fr');

class ContactsFormStore extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default ContactsFormStore;
