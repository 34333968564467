import React, { Component } from 'react'
import { inject } from 'mobx-react'

@inject('header')
class OutilMapPage extends Component {
  componentDidMount() {
    document.title = 'Vos outils'
    this.props.header.setHeaderPage('Outils')
  }

  render() {
    return (
      <React.Fragment>
        <div className="loader visible"></div>
      </React.Fragment>
    )
  }
}

export default OutilMapPage
