import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'

class Billings extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const { fundingAmount, mandateAmount, billings, label, loading } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        <section className={contentClasses}>

          <label className="accordion__heading" htmlFor="accordion-facturation" onClick={this.onHeaderClick}>
            <i className="prp-ext-invoice"></i>
            {label}
            {billings === undefined && loading && <div className="loader visible"></div>}
          </label>

          <div className="accordion__content">
            <React.Fragment>
              {billings &&
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Facturation client</span>
                    <ContentText text={billings && billings.paymentSteps} />
                  </div>
                </div>
              }
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Montant du financement</span>
                  <ContentText text={fundingAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(fundingAmount)} />
                </div>
                <div className="contact-element">
                  <span className="contact-name">Montant du mandat</span>
                  <ContentText  text={mandateAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(mandateAmount)} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Facture envoyée au client</span>
                  <ContentText text={'Non renseigné dans SF'} />
                </div>
                <div className="contact-element">
                  <span className="contact-name">Facture client payée</span>
                  <ContentText text={'Non renseigné dans SF'} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Facture du FO reçue ?</span>
                  <span className="contact-content">{billings && billings.isInvoiceReceivedFromFrontOffice ? 'Oui' : 'Non'}</span>
                </div>
                <div className="contact-element">
                  <span className="contact-name">FO payé ?</span>
                  <span className="contact-content">{billings && billings.isInvoiceReceivedFromFrontOffice ? 'Oui' : 'Non'}</span>
                </div>
              </div>
            </React.Fragment>
          </div>
        </section>
      </React.Fragment>
    );
  }
}


export default Billings;
