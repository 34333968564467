import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import SearchContactForm from '../commons/Filter/SearchContactForm'

@inject('user', 'ui', 'authStore')
@observer
class SwitchUser extends Component {

  componentDidMount() {
    this.props.authStore.resetSwitchUser()
  }

  close(e) {
    e.preventDefault();
    this.props.ui.setSwitchUser(false)
  }

  render() {
    return (
      <div className="overlay">
        <div className="modal-container" id="switch-user">
          <form className="login ajax-form" acceptCharset="utf-8">
            <div className="input">
              <SearchContactForm type="contact" button={false} state="admin" />
            </div>
            <span onClick={this.close.bind(this)}>Annuler</span>
          </form>
        </div>
      </div>
    );
  }
}


export default SwitchUser;
