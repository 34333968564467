import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  natureOne: {
    name: "natureOne",
    id: "natureOne",
    label: "Nature 1",
    rules: "string",
    type: "text"
  },
  monthlyQuarterlyOne: {
    name: "monthlyQuarterlyOne",
    id: "monthlyQuarterlyOne",
    label: "Charges mensuelles/trimestrielles 1",
    rules: "string",
    type: "text"
  },
  debtOne: {
    name: "debtOne",
    id: "debtOne",
    label: "Encours actuel et date de fin de la dette 1",
    rules: "string",
    type: "text"
  },
  natureTwo: {
    name: "natureTwo",
    id: "natureTwo",
    label: "Nature 2",
    rules: "string",
    type: "text"
  },
  monthlyQuarterlyTwo: {
    name: "monthlyQuarterlyTwo",
    id: "monthlyQuarterlyTwo",
    label: "Charges mensuelles/trimestrielles 2",
    rules: "string",
    type: "text"
  },
  debtTwo: {
    name: "debtTwo",
    id: "debtTwo",
    label: "Encours actuel et date de fin de la dette 2",
    rules: "string",
    type: "text"
  },
  natureThree: {
    name: "natureThree",
    id: "natureThree",
    label: "Nature 3",
    rules: "string",
    type: "text"
  },
  monthlyQuarterlyThree: {
    name: "monthlyQuarterlyThree",
    id: "monthlyQuarterlyThree",
    label: "Charges mensuelles/trimestrielles 3",
    rules: "string",
    type: "text"
  },
  debtThree: {
    name: "debtThree",
    id: "debtThree",
    label: "Encours actuel et date de fin de la dette 3",
    rules: "string",
    type: "text"
  },
  natureFour: {
    name: "natureFour",
    id: "natureFour",
    label: "Nature 4",
    rules: "string",
    type: "text"
  },
  monthlyQuarterlyFour: {
    name: "monthlyQuarterlyFour",
    id: "monthlyQuarterlyFour",
    label: "Charges mensuelles/trimestrielles 4",
    rules: "string",
    type: "text"
  },
  debtFour: {
    name: "debtFour",
    id: "debtFour",
    label: "Encours actuel et date de fin de la dette 4",
    rules: "string",
    type: "text"
  },
  natureFive: {
    name: "natureFive",
    id: "natureFive",
    label: "Nature 5",
    rules: "string",
    type: "text"
  },
  monthlyQuarterlyFive: {
    name: "monthlyQuarterlyFive",
    id: "monthlyQuarterlyFive",
    label: "Charges mensuelles/trimestrielles 5",
    rules: "string",
    type: "text"
  },
  debtFive: {
    name: "debtFive",
    id: "debtFive",
    label: "Encours actuel et date de fin de la dette 5",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class MovableAssets extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default MovableAssets;
