import React, { Component } from 'react';
import { Switch, withRouter, Redirect } from 'react-router-dom';
import { inject, observer } from "mobx-react";

import PrivateRoute from '../PrivateRoute';
import Message from '../commons/Message';

import SurveyPage from './SurveyPage'
import StartSurvey from './StartSurvey';
import SurveyCreationReprise from './1_CreationReprise/SurveyCreationReprise';
import SurveyWithPurchaseOrConstructionsWalls from './2_WithPurchaseOrConstructionsWalls/SurveyWithPurchaseOrConstructionsWalls';
import SurveyAcquisitionConstructionCommercialWalls from './3_AcquisitionConstructionCommercialWalls/SurveyAcquisitionConstructionCommercialWalls';
import SurveyRefinancingCurrentAccounts from './4_RefinancingCurrentAccounts/SurveyRefinancingCurrentAccounts';
import SurveyRestructuringOrDevelopment from './5_RestructuringOrDevelopment/SurveyRestructuringOrDevelopment';
import SurveyWorksOnExisting from './6_WorksOnExisting/SurveyWorksOnExisting';

@withRouter
@inject('surveyStore')
@observer
class LayoutSurvey extends Component {

  render() {
    return (
      <React.Fragment>
        <section className="wrapper-content for-mobile" id="survey">
          <Message zone='Survey' close={true} />
          <Switch>
            <PrivateRoute exact path="/survey" component={SurveyPage} {...this.props} />
            <PrivateRoute exact path="/survey/start/:id" component={StartSurvey} {...this.props} />
            <PrivateRoute exact path="/survey/1" component={SurveyCreationReprise} {...this.props} />
            <PrivateRoute exact path="/survey/2" component={SurveyWithPurchaseOrConstructionsWalls} {...this.props} />
            <PrivateRoute exact path="/survey/3" component={SurveyAcquisitionConstructionCommercialWalls} {...this.props} />
            <PrivateRoute exact path="/survey/4" component={SurveyRefinancingCurrentAccounts} {...this.props} />
            <PrivateRoute exact path="/survey/5" component={SurveyRestructuringOrDevelopment} {...this.props} />
            <PrivateRoute exact path="/survey/6" component={SurveyWorksOnExisting} {...this.props} />
            <Redirect to={"/survey"} {...this.props} />
          </Switch>
        </section>
      </React.Fragment>
    )
  }
}

export default LayoutSurvey;
