import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  nameGroup: {
    name: "nameGroup",
    id: "nameGroup",
    label: "Nom du Groupement ou Franchise (si vous êtes en réseau).",
    rules: "string",
    type: "text"
  },
  socialReason: {
    name: "socialReason",
    id: "socialReason",
    label: "Raison sociale (nom) de votre future entreprise",
    rules: "string",
    type: "text"
  },
  taxClosingDate: {
    name: "taxClosingDate",
    id: "taxClosingDate",
    label: "Date de clôture fiscale",
    rules: "string",
    type: "text"
  },
  copanyAddress: {
    name: "copanyAddress",
    id: "copanyAddress",
    label: "Adresse complète de votre entreprise",
    rules: "string",
    type: "text"
  },
  AmountCurrentAccounts: {
    name: "AmountCurrentAccounts",
    id: "AmountCurrentAccounts",
    label: "Montant approximatif du montant du ou des comptes courants à refinancer",
    rules: "string",
    type: "text"
  },
  remarks: {
    name: "remarks",
    id: "remarks",
    label: "Remarques à nous apporter",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class YourBusiness_v3 extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default YourBusiness_v3;
