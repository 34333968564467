import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

import FamilySituation from '../../../stores/SurveyForm/FamilySituation'
import YourIncome from '../../../stores/SurveyForm/YourIncome'
import SituationAfterInstallation from '../../../stores/SurveyForm/SituationAfterInstallation'
import BeneficiaryRecoveryCreation from '../../../stores/SurveyForm/BeneficiaryRecoveryCreation'
import RealEstate from '../../../stores/SurveyForm/RealEstate'
import MovableAssets from '../../../stores/SurveyForm/MovableAssets'
import Charges from '../../../stores/SurveyForm/Charges'
import OtherInformation from '../../../stores/SurveyForm/OtherInformation'
import InformationPerson from '../../../stores/SurveyForm/InformationPerson'
import YourBusiness from '../../../stores/SurveyForm/YourBusiness'
import YourLocation from '../../../stores/SurveyForm/YourLocation'
import PastFinancialSituation from '../../../stores/SurveyForm/PastFinancialSituation'
import InformationPersonProject from '../../../stores/SurveyForm/InformationPersonProject'
import InformationPersonProjectOther from '../../../stores/SurveyForm/InformationPersonProjectOther'
import LastPartForm from '../../../stores/SurveyForm/LastPartForm'

import SurveyInput from '../Components/SurveyInput';
import SurveyInputTable from '../Components/SurveyInputTable';
import SurveyBloc from '../Components/SurveyBloc';
import SurveyBlocCategory from '../Components/SurveyBlocCategory';
import SurveyMessage from '../Components/SurveyMessage';

@inject('surveyStore', 'header')
@observer
class SurveyCreationReprise extends Component {
    constructor(props) {
        super(props);
        document.title = "Questionnaire - Renseignements pour creation ou reprise"
        this.props.header.setHeaderPage('Renseignements pour creation ou reprise', 'desktop')

        this.familySituation = new FamilySituation(this.props.surveyStore, 'accountant')
        this.yourIncome = new YourIncome(this.props.surveyStore, 'yourIncome')
        this.situationAfterInstallation = new SituationAfterInstallation(this.props.surveyStore, 'situationAfterInstallation')
        this.beneficiaryRecoveryCreation = new BeneficiaryRecoveryCreation(this.props.surveyStore, 'beneficiaryRecoveryCreation')
        this.realEstate = new RealEstate(this.props.surveyStore, 'realEstate')
        this.movableAssets = new MovableAssets(this.props.surveyStore, 'movableAssets')
        this.charges = new Charges(this.props.surveyStore, 'charges')
        this.otherInformation = new OtherInformation(this.props.surveyStore, 'otherInformation')
        this.informationPersonOne = new InformationPerson(this.props.surveyStore, 'informationPersonOne')
        this.informationPersonTwo = new InformationPerson(this.props.surveyStore, 'informationPersonTwo')
        this.informationPersonThree = new InformationPerson(this.props.surveyStore, 'informationPersonThree')
        this.informationPersonFour = new InformationPerson(this.props.surveyStore, 'informationPersonFour')
        this.informationPersonFive = new InformationPerson(this.props.surveyStore, 'informationPersonFive')
        this.yourBusiness = new YourBusiness(this.props.surveyStore, 'yourBusiness')
        this.yourLocation = new YourLocation(this.props.surveyStore, 'yourLocation')
        this.pastFinancialSituation = new PastFinancialSituation(this.props.surveyStore, 'pastFinancialSituation')
        this.accountant = new InformationPersonProject(this.props.surveyStore, 'accountant')
        this.lawyer = new InformationPersonProject(this.props.surveyStore, 'lawyer')
        this.transactionFilm = new InformationPersonProject(this.props.surveyStore, 'transactionFilm')
        this.researchFilm = new InformationPersonProject(this.props.surveyStore, 'researchFilm')
        this.hardwareSupplierOne = new InformationPersonProject(this.props.surveyStore, 'hardwareSupplierOne')
        this.hardwareSupplierTwo = new InformationPersonProject(this.props.surveyStore, 'hardwareSupplierTwo')
        this.hardwareSupplierThree = new InformationPersonProject(this.props.surveyStore, 'hardwareSupplierThree')
        this.workManager = new InformationPersonProject(this.props.surveyStore, 'workManager')
        this.architect = new InformationPersonProject(this.props.surveyStore, 'architect')
        this.consultingFirm = new InformationPersonProject(this.props.surveyStore, 'consultingFirm')
        this.franchiseNetworkOne = new InformationPersonProject(this.props.surveyStore, 'franchiseNetworkOne')
        this.franchiseNetworkTwo = new InformationPersonProject(this.props.surveyStore, 'franchiseNetworkTwo')
        this.franchiseNetwork = new InformationPersonProject(this.props.surveyStore, 'franchiseNetwork')
        this.otherContactsOne = new InformationPersonProjectOther(this.props.surveyStore, 'otherContactsOne')
        this.otherContactsTwo = new InformationPersonProjectOther(this.props.surveyStore, 'otherContactsTwo')
        this.otherContactsThree = new InformationPersonProjectOther(this.props.surveyStore, 'otherContactsThree')
        this.lastPartForm = new LastPartForm(this.props.surveyStore, 'lastPartForm')
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    submitForm = async (e) => {
        this.props.surveyStore.loading = true
        e.preventDefault()
        await this.familySituation.onSubmit(e)
        await this.yourIncome.onSubmit(e)
        await this.situationAfterInstallation.onSubmit(e)
        await this.beneficiaryRecoveryCreation.onSubmit(e)
        await this.realEstate.onSubmit(e)
        await this.movableAssets.onSubmit(e)
        await this.charges.onSubmit(e)
        await this.otherInformation.onSubmit(e)
        await this.informationPersonOne.onSubmit(e)
        await this.informationPersonTwo.onSubmit(e)
        await this.informationPersonThree.onSubmit(e)
        await this.informationPersonFour.onSubmit(e)
        await this.informationPersonFive.onSubmit(e)
        await this.yourBusiness.onSubmit(e)
        await this.yourLocation.onSubmit(e)
        await this.pastFinancialSituation.onSubmit(e)
        await this.accountant.onSubmit(e)
        await this.lawyer.onSubmit(e)
        await this.transactionFilm.onSubmit(e)
        await this.researchFilm.onSubmit(e)
        await this.hardwareSupplierOne.onSubmit(e)
        await this.hardwareSupplierTwo.onSubmit(e)
        await this.hardwareSupplierThree.onSubmit(e)
        await this.workManager.onSubmit(e)
        await this.architect.onSubmit(e)
        await this.consultingFirm.onSubmit(e)
        await this.franchiseNetwork.onSubmit(e)
        await this.otherContactsOne.onSubmit(e)
        await this.otherContactsTwo.onSubmit(e)
        await this.otherContactsThree.onSubmit(e)
        await this.lastPartForm.onSubmit(e)

        setTimeout(() => {
            console.log('this.props.surveyStore.getUserInputs()', this.props.surveyStore.getUserInputs)
        }, 3000)

    }

    render() {
        const { loading } = this.props.surveyStore
        return (
            <React.Fragment>
                <form onSubmit={(e) => { this.submitForm(e)}}>
                    {/* {<div className="loader visible"></div>} */}
                    <SurveyBlocCategory title="IDENTITE DE L’EMPRUNTEUR ET/OU DE SON CONJOINT">
                        <SurveyBloc
                            title="Votre situation familiale"
                            name="familySituation"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.familySituation}
                            />
                        </SurveyBloc>
                    </SurveyBlocCategory>

                    <SurveyBlocCategory title="PROFESSION ET RESSOURCES">
                        <SurveyBloc
                            title="Vos revenus"
                            name="yourIncome"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.yourIncome}
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Votre situation avant et après votre installation"
                            name="situationAfterInstallation"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.situationAfterInstallation}
                                size={3}
                                titleTop={["Avant installation", "Après installation", "Commentaires"]}
                                titleLeft={["Revenus nets annuels du foyer", "Charges d’emprunts personnels annuels du foyer", "% d’endettement"]}
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Si vous êtes bénéficiaire dans le cadre de la reprise ou création d’un dispositif d’aide ou d’exonération"
                            name="beneficiaryRecoveryCreation"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.beneficiaryRecoveryCreation}
                                size={3}
                                titleTop={["Type d'aide", "Montant", "Commentaires"]}
                                titleLeft={["Aide numéro 1", "Aide numéro 2", "Aide numéro 3"]}
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Patrimoine immobilier : Vous indiquerez l’ensemble du patrimoine immobilier que vous détenez"
                            name="realEstate"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.realEstate}
                                size={7}
                                titleTop={["Nature", "Nature juridique de la propriété(*)", "Adresse", "Estimation actuelle", "Capital restant dû", "Hypothèque oui/non", "Montant hypothéqué"]}
                            />
                            <SurveyMessage
                                msg="(*) Bien propre, indivis ou démembré (nue-propriété / usufruit)"
                                type="note"
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Avoirs mobiliers, financiers (toutes banques) et autres produits (loyers, dividendes, parts, etc.)"
                            name="movableAssets"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.movableAssets}
                                size={4}
                                titleTop={["Nature", "Descriptif", "Estimation actuelle", "Etablissement teneur de compte (si nécessaire)"]}
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Charges mobilières et immobilières (toutes banques)"
                            name="charges"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.charges}
                                size={3}
                                titleTop={["Nature (PPI, crédit conso, pensions alimentaires, autres)", "Charges mensuelles/trimestrielles", "Encours actuel et date de fin de la dette"]}
                            />
                        </SurveyBloc>

                        <SurveyBloc
                            title="Autres informations (montant des cautions et sûretés consenties)"
                            name="otherInformation"
                            defaultChecked={false}
                        >
                            <SurveyInputTable
                                form={this.otherInformation}
                                size={4}
                                titleTop={["Nature de la sûreté ou Caution", "Montant garanti", "Etablissement bénéficiaire", "Date de fin de l’engagement"]}
                            />
                        </SurveyBloc>
                    </SurveyBlocCategory>

                    <SurveyBlocCategory title="Informations relatives à votre Assurance Emprunteur">
                        <SurveyMessage
                            title="ATTENTION"
                            msg="Merci d’être précis(e) dans vos réponses./n
                            Votre dossier de financement sera présenté avec une délégation d’assurances./n
                            Cette délégation permettra d’importantes économies sur votre dossier./n
                            Par conséquent, soyez très précis et répondez bien à toutes les questions."
                            type="warning"
                            multiligne
                        />
                        <SurveyBloc
                            title="Vos informations :"
                            name="informationPersonOne"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.informationPersonOne}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Informations sur votre premier associé (le cas échéant) :"
                            name="informationPersonTwo"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.informationPersonTwo}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Informations sur votre second associé (le cas échéant) :"
                            name="informationPersonThree"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.informationPersonThree}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Informations sur votre troisième associé (le cas échéant) :"
                            name="informationPersonFour"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.informationPersonFour}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Informations sur votre quatrième associé (le cas échéant) : "
                            name="informationPersonFive"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.informationPersonFive}
                            />
                        </SurveyBloc>
                    </SurveyBlocCategory>

                    <SurveyBlocCategory title="INFORMATIONS SUR LE PROJET">
                        <SurveyBloc
                            title="Votre entreprise"
                            name="yourBusiness"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.yourBusiness}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre emplacement"
                            name="yourLocation"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.yourLocation}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre situation financière passée"
                            name="pastFinancialSituation"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.pastFinancialSituation}
                            />
                        </SurveyBloc>
                    </SurveyBlocCategory>

                    <SurveyBlocCategory title="AVEC QUI ALLEZ-VOUS TRAVAILLER POUR VOTRE PROJET ?">
                        <SurveyMessage
                            title="Important"
                            msg="Les renseignements qui suivent sont très important pour la qualité de votre dossier./n
                            En effet, vous allez travailler avec plusieurs personnes ou entreprises pour votre projet. Les banques ont besoin de connaître ces professionnels pour ainsi mieux apprécier votre projet. Ces informations ne pourront que renforcer leur décision pour vous suivre dans votre financement."
                            type="warning"
                            multiligne
                        />
                        <SurveyBloc
                            title="Votre expert-comptable"
                            name="accountant"
                            defaultChecked={false}
                        >
                            <SurveyInput
                                form={this.accountant}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre avocat ou service juridique de votre expert comptable"
                            name="lawyer"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.lawyer}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre cabinet de transaction de fonds de commerce (si vous faites une reprise)"
                            name="transactionFilm"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.transactionFilm}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre cabinet de recherche d’emplacement"
                            name="researchFilm"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.researchFilm}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre/vos fournisseurs de matériel"
                            name="hardwareSupplier"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.hardwareSupplierOne}
                                type={"full"}
                            />
                            <div className="spacing"></div>
                            <SurveyInput
                                form={this.hardwareSupplierTwo}
                                type={"full"}
                            />
                            <div className="spacing"></div>
                            <SurveyInput
                                form={this.hardwareSupplierThree}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre gestionnaire de travaux"
                            name="workManager"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.workManager}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre architecte"
                            name="architect"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.architect}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Votre cabinet conseil"
                            name="consultingFirm"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.consultingFirm}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Si vous vous installez dans un réseau de franchise, vos contacts au sein du réseau"
                            name="franchiseNetwork"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide"
                                type="note"
                            />
                            <SurveyInput
                                form={this.franchiseNetworkOne}
                                type={"full"}
                            />
                            <div className="spacing"></div>
                            <SurveyInput
                                form={this.franchiseNetworkTwo}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyBloc
                            title="Autres contacts que vous voudriez nous faire connaitre"
                            name="otherContacts"
                            defaultChecked={false}
                        >
                            <SurveyMessage
                                msg="Si vous n’êtes pas concerné(e), laissez ce paragraphe vide 123"
                                type="note"
                            />
                            <SurveyInput
                                form={this.otherContactsOne}
                                type={"full"}
                            />
                            <div className="spacing"></div>
                            <SurveyInput
                                form={this.otherContactsTwo}
                                type={"full"}
                            />
                            <div className="spacing"></div>
                            <SurveyInput
                                form={this.otherContactsThree}
                                type={"full"}
                            />
                        </SurveyBloc>
                        <SurveyMessage
                            title="Important"
                            msg="Les renseignements qui suivent sont primordiaux pour la qualité de votre dossier./n
                            En vous accompagnant sur votre dossier, nous allons vous faire gagner beaucoup de temps, alors merci de passer le temps qu’il faut pour répondre avec précision aux questions./n
                            Plus vous serez précis et plus vous apporterez de détails, plus votre dossier sera de qualité./n
                            Vos réponses nous permettront de mettre en avant un grand nombre d’arguments dans la présentation de votre dossier et dans la négociation avec nos partenaires bancaires."
                            type="warning"
                            multiligne
                        />
                        <div className="accordion survey">
                            <div className="content">
                                <SurveyInput
                                    form={this.lastPartForm}
                                    type={"full big"}
                                />
                                <SurveyMessage
                                    msg="Conformément à la loi n°78-17 du 6 janvier 1978 relative à l’informatique aux fichiers et aux libertés, vous pouvez, à tout moment accéder aux informations vous concernant, les faire rectifier, les faire mettre à jour ou faire supprimer les données inexactes, incomplètes ou périmées et de vous opposer, sous réserve de justifier d’un motif légitime, à ce que des données à caractère personnel vous concernant fassent l’objet d’un traitement. Cette opposition peut toutefois entraîner l’impossibilité pour PR€T PRO de fournir le produit ou le service souscrit./n
                                    Vous pouvez, sans avoir à motiver votre demande, vous opposer à ce que ces données soient utilisées à des fins de prospection commerciale ou communiquées à des tiers à cette fin./n
                                    Ces droits peuvent être exercés, pour les clients de PR€T PRO, en écrivant par lettre simple à l’agence qui gère votre compte, pour les autres cas en écrivant par lettre simple à l’adresse suivante/n
                                    PR€T PRO – Service Gestion des Données/n
                                    12 Avenue Carnot – Cité des Congrès/n
                                    44000 Nantes/n
                                    Les frais de timbre vous seront remboursés sur simple demande écrite."
                                    type="info"
                                    multiligne
                                />
                            </div>
                        </div>
                    </SurveyBlocCategory>
                    <div className="submitButton">
                        {loading &&<div className="loader visible small"></div>}
                        <button className="button button-primary" type="button" onClick={(e) => {this.submitForm(e)}}>Envoyer</button>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

export default SurveyCreationReprise;
