import React, { Component } from 'react'
import ContentText from '../../commons/ContentText'

class Client extends Component {
  constructor(props) {
    super(props)
    this.state = {
      empty: 'wait',
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  onSubHeaderClick = (id) => {
    if (!this.state[id]) {// Si notre intervenant est masqué
      this.setState({
        [id]: true
      })
    } else { //Si il est affiché
      this.setState({
        [id]: false
      })
    }
  }
  subContentClasses = (id) => {
    var result = 'section-content'
    // console.log('this.state.subOpened', this.state[id])
    if (this.state[id]) {
      result += ' open'
    }
    return result
  }

  count = () => {
    const { contributors } = this.props
    var count = 0
    if (this.state.empty === 'wait') {
      contributors[0] &&
        contributors[0].contributors.map(contributor => {
          if (contributor.contact && contributor.contributorType !== 'Bank') {
            count++
          }
          return null
        })
      if (count !== 0) {
        this.setState({ empty: count })
      } else {
        this.setState({ empty: false })
      }
      return null
    }
    return null
  }

  render() {
    const { label, contributors, loading } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        {contributors && (
          <section className={contentClasses}>

            <label
              className="accordion__heading"
              htmlFor="accordion-intervenants"
              onClick={this.onHeaderClick}
            >
              <i className="prp-ext-contacts"></i>
              {label}
              {contributors[0] === undefined && loading && (
                <div className="loader visible"></div>
              )}
            </label>

            <div className="accordion__content intervenants">
              {!contributors[0] ? (
                <div className="empty-info no-border">Aucun intervenant</div>
              ) : (
                  <React.Fragment>
                    {contributors &&
                      Array.isArray(contributors) &&
                      contributors.map((contributors, idx) => (
                        <React.Fragment key={idx}>
                          {contributors.contributors.map((contributor, idx) => (
                            <React.Fragment key={idx}>
                              {contributor.contact &&
                                contributor.contributorType !== 'Bank' && (
                                  <section
                                    className={this.subContentClasses(contributor.contact.id)}
                                  >
                                    <label
                                      className="accordion__heading sub-menu"
                                      htmlFor={
                                        'accordion-intervenants-' +
                                        contributor.contact.id
                                      }
                                      onClick={() => this.onSubHeaderClick(contributor.contact.id)}
                                    >
                                      {contributor.contact.contactOwner && (
                                        <span
                                          className="fa fa-lock"
                                          title="Ce contact a un mandataire propriétaire"
                                        ></span>
                                      )}
                                      {contributor.contact.firstName &&
                                        contributor.contact.firstName + ' '}
                                      {contributor.contact.lastName &&
                                        contributor.contact.lastName}
                                      {contributor.contact.function && contributor.contact.function.label &&
                                        ' (' +
                                        contributor.contact.function.label +
                                        ')'}
                                    </label>

                                    <div className="accordion__content">
                                      <div className="row-contact">
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Nom
                                        </span>
                                          <ContentText
                                            text={
                                              contributor.contact.firstName +
                                              ' ' +
                                              contributor.contact.lastName
                                            }
                                          />
                                        </div>
                                      </div>
                                      <div className="row-contact">
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Fonction
                                        </span>
                                          <ContentText
                                            text={
                                              contributor.contact.function &&
                                              contributor.contact.function.label
                                            }
                                          />
                                        </div>
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Précision sur la fonction
                                        </span>
                                          <ContentText text={contributor.contact.groupFunction} />
                                        </div>
                                      </div>
                                      <div className="row-contact">
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Téléphone
                                        </span>
                                          <ContentText
                                            text={contributor.contact.phone}
                                          />
                                        </div>
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Téléphone mobile
                                        </span>
                                          <ContentText
                                            text={contributor.contact.mobilePhone}
                                          />
                                        </div>
                                      </div>
                                      <div className="row-contact">
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Email
                                        </span>
                                          <ContentText
                                            text={contributor.contact.email}
                                          />
                                        </div>
                                      </div>
                                      <div className="row-contact">
                                        <div className="contact-element">
                                          <span className="contact-name">
                                            Mandataire
                                        </span>
                                          <ContentText
                                            text={
                                              contributor.contact.contactOwner &&
                                              contributor.contact.contactOwner
                                                .firstName +
                                              ' ' +
                                              contributor.contact.contactOwner
                                                .lastName
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </section>
                                )}
                            </React.Fragment>
                          ))}
                          {!this.state.empty && (
                            <div className="empty-info no-border">
                              Aucun intervenant
                          </div>
                          )}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                )}
            </div>
          </section>
        )}
      </React.Fragment>
    )
  }
}

export default Client
