import React, { Component } from 'react';

class ContentText extends Component {

    render() {
        const { text } = this.props
        return (
            <React.Fragment>
                <span className="contact-content">
                    {!text || text === undefined || text === '' || text === null || text === 'Invalid date' ? '-' : text}
                </span>
            </React.Fragment>
        );
    }
}


export default ContentText;
