import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import NavItem from './NavItem'

@inject('authStore')
@observer
class PrivateNavItem extends Component {
  render() {
    const { authStore } = this.props
    let minLevelAccess
    let maxLevelAccess
    let forbiddenLevelAccess

    if (this.props.minLevelAccess === undefined) {
      minLevelAccess = 0
    } else {
      minLevelAccess = this.props.minLevelAccess
    }
    if (this.props.maxLevelAccess === undefined) {
      maxLevelAccess = 10
    } else {
      maxLevelAccess = this.props.maxLevelAccess
    }
    if (this.props.forbiddenLevelAccess === undefined) {
      forbiddenLevelAccess = []
    } else {
      var forbiddenValues  = this.props.forbiddenLevelAccess.split(',')
      forbiddenLevelAccess = forbiddenValues.map(function (val) {
        return parseInt(val, 10);
      });
    }

    if (authStore.getToken()) {
      let decodedToken = authStore.level
      if (decodedToken >= minLevelAccess && decodedToken <= maxLevelAccess && forbiddenLevelAccess.indexOf(decodedToken) === -1) {
        return (
          <React.Fragment>
            <NavItem {...this.props} />
          </React.Fragment>
        )
      }
    }
    return null
  }
}
export default PrivateNavItem
