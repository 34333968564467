import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  lastName: {
    name: "lastName",
    id: "lastName",
    label: "Nom",
    rules: "string",
    type: "text"
  },
  firstName: {
    name: "firstName",
    id: "firstName",
    label: "Prénom",
    rules: "string",
    type: "text"
  },
  personalAddress: {
    name: "personalAddress",
    id: "personalAddress",
    label: "Adresse complète – Code postal – Ville",
    rules: "string",
    type: "text"
  },
  phone: {
    name: "phone",
    id: "phone",
    label: "Téléphone",
    rules: "string",
    type: "text"
  },
  email: {
    name: "email",
    id: "email",
    label: "Email",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class InformationPersonProject extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default InformationPersonProject;
