import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import helpers from '../../../helpers'
import { FormHelperText } from '@material-ui/core'
import LoanFilesUpdateNoteForm from '../../../stores/LoanFileForm/LoanFilesUpdateNoteForm'

@inject('ui', 'dossiers', 'authStore')
@observer
class DocumentManagers extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  componentDidMount() {
    this.form = new LoanFilesUpdateNoteForm(this.props)
  }

  updateNote() {
    this.props.ui.toggleModifNote()
    if(this.props.notes[0]){
      this.form.$('id').value = this.props.notes[0].id
      this.form.$('Comment').value = this.props.notes[0].body
    }
  }

  render() {
    const { loadingUpdate } = this.props.dossiers
    const { label, notes, loading } = this.props
    const { form } = this
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        {notes && (
          <section className={contentClasses}>

            <label className="accordion__heading" htmlFor="accordion-notes" onClick={this.onHeaderClick}>
              <i className="prp-ext-notes"></i>
              {label}
              {notes[0] === undefined && loading && (
                <div className="loader visible"></div>
              )}
            </label>

            <div className="accordion__content">
              {
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name"  onClick={this.updateNote.bind(this)}>
                      Notes{' '}
                      <i
                        className="fa edit fa-edit"
                      ></i>
                    </span>
                    {!this.props.ui.modifNote ? (
                      <span className="contact-content">
                        {notes &&
                          Array.isArray(notes) &&
                          notes.map((note, idx) => (
                            <React.Fragment key={idx}>
                              <span
                                dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                                  note.body.replace(/\n/g, '<br />')
                                )}
                              />
                            </React.Fragment>
                          ))}
                          {!notes &&
                            <div className="empty-info no-border">Aucune note</div>
                          }
                      </span>
                    ) : (
                      <React.Fragment>
                        <div className="contact-element no-border no-margin">
                          {loadingUpdate && (
                            <div className="loader visible"></div>
                          )}
                          <form onSubmit={form.onSubmit}>
                            <textarea
                              cols="40"
                              rows="10"
                              {...form.$('Comment').bind()}
                              type="text"
                              className="input_text"
                            />
                            {form.$('Comment').error !== null && (
                              <FormHelperText
                                error={true}
                                className="field-error"
                              >
                                {form.$('Comment').error}
                              </FormHelperText>
                            )}
                            <button
                              className="button button-primary"
                              type="submit"
                            >
                              Valider
                            </button>
                          </form>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>}
            </div>
          </section>
        )}
      </React.Fragment>
    )
  }
}

export default DocumentManagers
