import React, { Component } from 'react';
import { inject } from "mobx-react"

@inject('header')
class OutilListsPage extends Component {

  componentDidMount() {
    document.title = "Vos outils"
    this.props.header.setHeaderPage('Outils')
  }

  render() {
    return (
      <React.Fragment>
          <div className="empty-info">Prochainement</div>
      </React.Fragment>
    );
  }
}

export default OutilListsPage;
