import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  natureOne: {
    name: "natureOne",
    id: "natureOne",
    label: "Nature 1",
    rules: "string",
    type: "text"
  },
  descriptionOne: {
    name: "descriptionOne",
    id: "descriptionOne",
    label: "Descriptif 1",
    rules: "string",
    type: "text"
  },
  currentEstimateOne: {
    name: "currentEstimateOne",
    id: "currentEstimateOne",
    label: "Estimation actuelle 1",
    rules: "string",
    type: "text"
  },
  accountHolderOne: {
    name: "accountHolderOne",
    id: "accountHolderOne",
    label: "Etablissement teneur de compte (si nécessaire) 1",
    rules: "string",
    type: "text"
  },
  natureTwo: {
    name: "natureTwo",
    id: "natureTwo",
    label: "Nature 2",
    rules: "string",
    type: "text"
  },
  descriptionTwo: {
    name: "descriptionTwo",
    id: "descriptionTwo",
    label: "Descriptif 2",
    rules: "string",
    type: "text"
  },
  currentEstimateTwo: {
    name: "currentEstimateTwo",
    id: "currentEstimateTwo",
    label: "Estimation actuelle 2",
    rules: "string",
    type: "text"
  },
  accountHolderTwo: {
    name: "accountHolderTwo",
    id: "accountHolderTwo",
    label: "Etablissement teneur de compte (si nécessaire) 2",
    rules: "string",
    type: "text"
  },
  natureThree: {
    name: "natureThree",
    id: "natureThree",
    label: "Nature 3",
    rules: "string",
    type: "text"
  },
  descriptionThree: {
    name: "descriptionThree",
    id: "descriptionThree",
    label: "Descriptif 3",
    rules: "string",
    type: "text"
  },
  currentEstimateThree: {
    name: "currentEstimateThree",
    id: "currentEstimateThree",
    label: "Estimation actuelle 3",
    rules: "string",
    type: "text"
  },
  accountHolderThree: {
    name: "accountHolderThree",
    id: "accountHolderThree",
    label: "Etablissement teneur de compte (si nécessaire) 3",
    rules: "string",
    type: "text"
  },
  natureFour: {
    name: "natureFour",
    id: "natureFour",
    label: "Nature 4",
    rules: "string",
    type: "text"
  },
  descriptionFour: {
    name: "descriptionFour",
    id: "descriptionFour",
    label: "Descriptif 4",
    rules: "string",
    type: "text"
  },
  currentEstimateFour: {
    name: "currentEstimateFour",
    id: "currentEstimateFour",
    label: "Estimation actuelle 4",
    rules: "string",
    type: "text"
  },
  accountHolderFour: {
    name: "accountHolderFour",
    id: "accountHolderFour",
    label: "Etablissement teneur de compte (si nécessaire) 4",
    rules: "string",
    type: "text"
  },
  natureFive: {
    name: "natureFive",
    id: "natureFive",
    label: "Nature 5",
    rules: "string",
    type: "text"
  },
  descriptionFive: {
    name: "descriptionFive",
    id: "descriptionFive",
    label: "Descriptif 5",
    rules: "string",
    type: "text"
  },
  currentEstimateFive: {
    name: "currentEstimateFive",
    id: "currentEstimateFive",
    label: "Estimation actuelle 5",
    rules: "string",
    type: "text"
  },
  accountHolderFive: {
    name: "accountHolderFive",
    id: "accountHolderFive",
    label: "Etablissement teneur de compte (si nécessaire) 5",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class MovableAssets extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default MovableAssets;
