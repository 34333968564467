import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

@inject("contacts", "picklist", "authStore")
@observer
class FilterByType extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.setState({ showResults: false, target: "" });
  }

  componentDidUpdate() {
    const { authStore } = this.props
    if (authStore.level !== '' && this.props.picklist.getFilterTypeContact.length === 0) {
      if (authStore.level === 2 || (authStore.level <= 6 && authStore.level >= 4)) {
        this.props.picklist.listFilterTypeContactDirection()
      } else {
        this.props.picklist.listFilterTypeContact()
      }
    }
  }

  onClick(target) {
    if (!this.props.contacts.loadingList) {
      this.props.contacts.setMainType(target)
      this.setState({ mainType: target });
      this.props.history.push({ pathname: "/contacts/function/" + target })
      if (target === this.state.target) {
        this.setState({ showResults: !this.state.showResults, target: target });
      } else {
        this.setState({ showResults: true, target: target });
      }
      this.forceUpdate();
    }
  }

  targetActive(target = '') {
    if (target === '') {
      this.setState({ mainType: '' });
    }
    this.props.contacts.setTypeId(target);
  }

  renderClass(contactMainFunctionsId) {
    var className = ''
    if (this.state.showResults && this.state.target === contactMainFunctionsId && this.state.mainType !== '') {
      className += 'show'
    }
    if (contactMainFunctionsId === this.props.contacts.mainType || this.state.mainType === contactMainFunctionsId) {
      className += ' active'
    }
    return className
  }

  render() {
    const { getSizeListAll } = this.props.contacts;
    const { getFilterTypeContact, FilterTypeContactLoading } = this.props.picklist;
    const { authStore } = this.props

    return (
      <React.Fragment>
        <ul className="dossier-type">
          {authStore.level === 2 || (authStore.level <= 6 && authStore.level >= 4) ?
            <React.Fragment>
              {getFilterTypeContact && getFilterTypeContact[0] && getFilterTypeContact[0].label === "Direction de PR€T PRO" &&
                <React.Fragment>
                  <Link to={"/contacts/type/" + getFilterTypeContact[0].id} >
                    <li onClick={() => { this.targetActive(getFilterTypeContact[0].id) }} className={getFilterTypeContact[0].id === this.props.contacts.typeId ? 'active' : null}>
                      <i className="icon prp-ext-contact" />
                      <div>
                        <div className="title">
                          Direction de Pr€t Pro
                        </div>
                        <span className="infos">{getFilterTypeContact[0].contactsCount} contacts</span>
                      </div>
                    </li>
                  </Link>
                  <Link to={"/contacts"}  >
                    <li className={this.props.contacts.typeId === '' ? 'active' : null} onClick={() => { this.targetActive() }}>
                      <i className="icon prp-ext-contact" />
                      <div>
                        <div className="title">
                          Vos contacts dossiers
                        </div>
                        <span className="infos">{getSizeListAll} contacts</span>
                      </div>
                    </li>
                  </Link>
                </React.Fragment>
              }
            </React.Fragment>
            :
            <React.Fragment>
              {this.state && !FilterTypeContactLoading &&
                <React.Fragment>
                  {getFilterTypeContact.map((contactMainFunctions, idx) => ( //MainFunction
                    <React.Fragment key={idx}>
                      {authStore.level === 8 && contactMainFunctions.label === 'Partenaires de PR€T PRO' ?
                        null
                        :
                        <React.Fragment>
                          {((contactMainFunctions && contactMainFunctions.functions && contactMainFunctions.functions.map((f) => f.contactsCount).reduce((a, b) => a + b, 0) !== 0) || authStore.level >= 10) && //S'il y a des contacts ou level > 8
                            <li className={this.renderClass(contactMainFunctions.id)} >
                              <div className="label-container" onClick={() => { this.onClick(contactMainFunctions.id) }} >
                                <i className="icon prp-ext-contact" />
                                <div className="label">
                                  <span className="title">
                                    {contactMainFunctions.label}
                                  </span>
                                  <br />
                                  <span className="infos">{contactMainFunctions && contactMainFunctions.functions && contactMainFunctions.functions.map((f) => f.contactsCount).reduce((a, b) => a + b, 0)} contacts</span>
                                </div>
                              </div>
                              <ul>
                                {contactMainFunctions && contactMainFunctions.functions && contactMainFunctions.functions.map( //Function
                                  (contactFunction, idx) => (
                                    (contactFunction.contactsCount !== 0 || authStore.level >= 10) &&
                                    <React.Fragment key={idx}>
                                      <Link to={"/contacts/type/" + contactFunction.id} >
                                        <li onClick={() => { this.targetActive(contactFunction.id) }} className={contactFunction.id === this.props.contacts.typeId ? 'active' : null}>
                                          <i className="icon prp-ext-contact" />
                                          <div>
                                            <div className="title">
                                              {contactFunction.label}
                                            </div>
                                            <span className="infos">{contactFunction.contactsCount} contacts</span>
                                          </div>
                                        </li>
                                      </Link>
                                    </React.Fragment>
                                  )
                                )}
                              </ul>
                            </li>
                          }
                        </React.Fragment>
                      }
                    </React.Fragment>
                  ))}
                  {authStore.level !== 3 && <Link to={"/contacts"} className="link">
                    <li className={this.props.contacts.typeId === '' && this.props.contacts.mainType === '' ? 'active' : null} onClick={() => { this.targetActive() }}>
                      <div className="label-container" >
                        <i className="icon prp-ext-contact" />
                        <div>
                          <span className="title">Tous</span>
                          <br />
                          <span className="infos">{getSizeListAll} contacts</span>
                        </div>
                      </div>
                    </li>
                  </Link>
                  }
                </React.Fragment>
              }
            </React.Fragment>
          }
        </ul>
      </React.Fragment>
    );
  }
}

export default FilterByType;
