import { observable, action } from 'mobx'
import axios from 'axios';
import { API_HOST } from '../config/_entrypoint';
import history from '../history';

class UserStore {

  constructor(stores) {
    this.stores = stores
  }

  @observable loading = false;
  @observable oldPassword = '';
  @observable newPassword = '';
  @observable newPasswordConfirm = '';
  @observable email = '';
  @observable resetToken = '';

  @action setOldPassword(pwd) {
    this.oldPassword = pwd
  }
  @action setNewPassword(pwd) {
    this.newPassword = pwd
  }
  @action setNewPasswordConfirm(pwd) {
    this.newPasswordConfirm = pwd
  }

  @action setEmail(email) {
    this.email = email;
  }

  @action changePassword() {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.stores.authStore.getToken()
    this.loading = true;

    axios.post(`${API_HOST}/change_password`, {
      oldPassword : this.oldPassword,
      newPassword : this.newPassword,
      newPasswordConfirm : this.newPasswordConfirm
    }, { headers : this.stores.agent.headers()})
      .then(res => {
        this.stores.ui.setChangePassword(false)
        this.stores.ui.addMessage('Mot de passe modifié !', 'succes', 'all')
      })
      .catch(err => {
        this.stores.ui.addMessage('Erreur de saisie !', 'danger', 'changePassword')
      })
      .finally(action(() => { this.loading = false }))
  }

  @action forgotPassword() {
    this.loading = true;

    axios.post(`${API_HOST}/forgot_password`, {
      email : this.email
    })
      .then(res => {
        this.stores.ui.addMessage(res.data.message, 'warning', 'login')
        history.push('login')
      })
      .catch(err => { throw err })
      .finally(action(() => { this.loading = false }))
  }

  @action resetPassword() {
    this.loading = true;

    axios.post(`${API_HOST}/reset_password`, {
      resetToken: this.resetToken,
      newPassword: this.newPassword,
      newPasswordConfirm: this.newPasswordConfirm
    })
      .then(res => {
        this.stores.ui.addMessage('Mot de passe modifié !', 'warning', 'login')
        history.push('/login')
      })
      .catch(err => { this.stores.ui.addMessage('Erreur', 'warning', 'login') })
      .finally(action(() => { this.loading = false }))
  }

}

export default UserStore;
