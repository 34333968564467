import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";

@inject('surveyStore', 'header')
@observer
class SurveyPage extends Component {

    componentDidMount() {
        document.title = "Liste des questionnaires"
        this.props.header.setHeaderPage('Liste des questionnaires', 'desktop')
    }

    render() {
        return (
            <React.Fragment>
                <div className="list-form">
                    {/* TODO: Liste des formulaires */}
                    <Link to={"/survey/start/1"}>2019.01 Janvier - Fiche 1 Renseignements pour creation ou reprise</Link>
                    <Link to={"/survey/start/2"}>2019.01 Janvier - Fiche 2 Renseignements pour creation ou reprise avec achat ou construction de murs</Link>
                    <Link to={"/survey/start/3"}>2019.01 Janvier - Fiche 3 Renseignements pour acquisition ou construction de murs commerciaux</Link>
                    <Link to={"/survey/start/4"}>2019.01 Janvier - Fiche 4 Renseignements pour refinancement des comptes courants</Link>
                    <Link to={"/survey/start/5"}>2019.01 Janvier - Fiche 5 Renseignements pour restructuration ou developpement</Link>
                    <Link to={"/survey/start/6"}>2019.01 Janvier - Fiche 6 Renseignements pour Travaux sur existant</Link>
                </div>
            </React.Fragment>
        );
    }
}

export default SurveyPage;
