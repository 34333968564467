import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
  id: {
    name: "id",
    id: "id",
    label: "id",
    type: "hidden",
    rules: "required"
  },
  firstName: {
    name: "firstName",
    id: "firstName",
    label: "Nom du contact local",
    rules: "string"
  },
  lastName: {
    name: "lastName",
    id: "lastName",
    label: "Prénom du contact local",
    rules: "string"
  },
  email: {
    name: "email",
    id: "email",
    label: "Email du contact local",
    rules: "string"
  },
  phone: {
    name: "phone",
    id: "phone",
    label: "Téléphone du contact local",
    rules: "string"
  },
  noteIntern: {
    name: "noteIntern",
    id: "noteIntern",
    label: "Notes internes",
    rules: "string"
  },
  noteClient: {
    name: "noteExtern",
    id: "noteExtern",
    label: "Notes client",
    rules: "string"
  }
};

const hooks = {
  onSuccess(form) {
      this.stores.dossiers.updateLoanFileBank(form, form.$("id").value);
  }
};
validatorjs.useLang('fr');

class LoanFilesUpdateBankForm extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default LoanFilesUpdateBankForm;
