import React, { Component } from 'react';
import { FormHelperText } from "@material-ui/core";
import { observer } from "mobx-react"

@observer
class FamilySituation extends Component {

    render() {
        const { form, type } = this.props
        if (form && form.fields) {
            this.familySituationFields = Array.from(form.fields._data.values())
        }
        return (
            <React.Fragment>
                {this.familySituationFields && this.familySituationFields.map((data, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            {/* <div className={type === "full" ? "fields survey full" : "fields survey"}> */}
                            <div className={type ? `fields survey ${type}` : `fields survey`}>
                                {form.$(data.value.name).label &&
                                    <label
                                        className="label"
                                        htmlFor={form.$(data.value.name).id}
                                    >
                                        {form.$(data.value.name).label}
                                    </label>
                                }
                                {type === "full big" ?
                                    <textarea
                                        {...form.$(data.value.name).bind()}
                                        className={data.value.value ? "input_text" : "input_text empty"}
                                        rows={7}
                                    />
                                    :
                                    <textarea
                                        {...form.$(data.value.name).bind()}
                                        className={data.value.value ? "input_text" : "input_text empty"}
                                    />
                                }
                                {
                                    form.$(data.value.name).error !== null && (
                                        <FormHelperText error={true} className="field-error">
                                            {form.$(data.value.name).error}
                                        </FormHelperText>
                                    )
                                }
                            </div>
                        </React.Fragment>
                    )
                })}
            </React.Fragment >
        );
    }
}

export default FamilySituation;
