import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import moment from 'moment'
import _ from 'lodash';
import Status from './Status'
import FilterByPayment from '../commons/Filter/FilterByPayment';
import helpers from "../../helpers";


@inject('header', 'dossiers', 'authStore')
@observer
class LoanFilesListPage extends Component {
      state = {
        page: []
      }

      static getDerivedStateFromProps(props, state) {
        return {
          page: []
        };
      }

    onClick(dossier) {
      this.props.dossiers.resetLoanFileId()
      this.props.dossiers.setLoanFileId(dossier)
      this.props.history.push({pathname:"/dossiers/"+dossier.id});
    }

    componentDidMount() {
        document.title = "Vos dossiers"
        this.props.header.setHeaderPage('Dossiers')
        this.setState({ page: [] })

        const { dossiers } = this.props
        if (this.props.match.params.typeId !== undefined && this.props.match.params.typeId !== this.props.dossiers.typeId) {
          this.props.dossiers.setTypeId(this.props.match.params.typeId)
        }
        if (this.props.match.params.stepId !== undefined && this.props.match.params.stepId !== this.props.dossiers.stepId) {
          this.props.dossiers.setStepId(this.props.match.params.stepId)
        }
        if(this.props.authStore.level !== 3) {
          dossiers.list()
        } else {
          if (this.props.authStore.values.bankId === '') {
            this.props.authStore.getBankId()
          } else {
            dossiers.list()
          }
        }
    }

    paging = () => {
        const { getSizeListAll, getPagination, sizeListOnePage } = this.props.dossiers;

        if (getSizeListAll !== 0 && this.state && this.state.page) {
          if (Math.ceil(getSizeListAll / sizeListOnePage) === 1 && this.state.page.length !== 0) {
            this.setState({ page: [] })
          } else if (this.state.page[1] === undefined) {
            if (Math.ceil(getSizeListAll / sizeListOnePage) > 5) {
              this.state.page.push(<li key={1} id={'page-1'} className={getPagination === 1 ? 'active' : undefined} onClick={() => { this.setPage(1) }}>{1}</li>)
              if (getPagination > 3) {
                this.state.page.push(<li key={'-1'} onClick={() => { this.setPage(getPagination - 1) }}>{'<<'}</li>)
              }
              for (let j = -2; j < 3; j++) {
                if (getPagination + j !== 1 && getPagination + j > 1 && getPagination + j <= Math.ceil(getSizeListAll / sizeListOnePage)) {
                  this.state.page.push(<li key={getPagination + j} className={getPagination === getPagination + j ? 'active' : undefined} onClick={() => { this.setPage(getPagination + j) }}>{getPagination + j}</li>)
                }
              }
              if (getPagination < Math.ceil(getSizeListAll / sizeListOnePage) - 3) {
                this.state.page.push(<li key={'+1'} onClick={() => { this.setPage(getPagination + 1) }}>{'>>'}</li>)
              }

              if (Math.ceil(getSizeListAll / sizeListOnePage) - getPagination >= 3) {
                this.state.page.push(<li key={Math.ceil(getSizeListAll / sizeListOnePage)} onClick={() => { this.setPage(Math.ceil(getSizeListAll / sizeListOnePage)) }}>{Math.ceil(getSizeListAll / sizeListOnePage)}</li>)
              }
            } else {
              for (let j = 0; j < (Math.ceil(getSizeListAll / sizeListOnePage)); j++) {
                if (this.state.page[j] === undefined) {
                  if (getPagination === j + 1) {
                    this.state.page.push(<li className='active' key={j} onClick={() => { this.setPage(j + 1) }}>{j + 1}</li>)
                  } else {
                    this.state.page.push(<li key={j} onClick={() => { this.setPage(j + 1) }}>{j + 1}</li>)
                  }
                }
              }
            }
          }
          return (this.state.page)
        }
      }

      setPage(nbPage) {
        this.props.dossiers.setPagination(nbPage);
        this.setState({ page: [] })
      }

    render() {

        const { getDossiers, loading, getSizeListAll, getTypeId } = this.props.dossiers
        return (
            <React.Fragment>
                <div id="contacct-list-view">
                    {loading && <div className="empty-info">Chargement en cours...</div>}
                    {!loading && getDossiers.length === 0 && <div className="empty-info">Aucun dossier</div>}
                    {!loading && getDossiers.length !== 0 &&
                        <React.Fragment>
                            {this.props.authStore.level >= 8 && getTypeId == 13 &&
                              <FilterByPayment />
                            }
                            <section id="tableau">
                              <table className="prp-table">
                                  <thead>
                                      <tr>
                                        {this.props.authStore.level >= 8 && //Admin et mandataire
                                          <React.Fragment>
                                            <th></th>
                                            <th>Nom</th>
                                            <th>N° dossier</th>
                                            <th>Type de dossier</th>
                                            <th>Status</th>
                                          </React.Fragment>
                                        }
                                        {this.props.authStore.level >= 4 && this.props.authStore.level <= 6 && //Apporteur
                                          <React.Fragment>
                                            <th>Nom</th>
                                            <th>N&deg;&nbsp;dossier</th>
                                            <th>Etat&nbsp;du&nbsp;dossier</th>
                                            {/* <th>Dossier&nbsp;financé&nbsp;le</th> */}
                                            <th>Date&nbsp;réponse&nbsp;bancaire</th>
                                            <th>Status</th>
                                          </React.Fragment>
                                        }
                                        {this.props.authStore.level === 3 && //Banque
                                          <React.Fragment>
                                            <th>Nom</th>
                                            <th>Etat&nbsp;du&nbsp;dossier</th>
                                            <th>Votre&nbsp;réponse</th>
                                            <th>Statut</th>
                                          </React.Fragment>
                                        }
                                        {this.props.authStore.level === 2 && //Client
                                          <React.Fragment>
                                            <th>Nom</th>
                                            <th>Etat&nbsp;du&nbsp;dossier</th>
                                            {/* <th>Dossier&nbsp;financé&nbsp;le</th> */}
                                            <th>Date&nbsp;réponse&nbsp;bancaire</th>
                                            <th>Status</th>
                                          </React.Fragment>
                                        }
                                      </tr>
                                  </thead>
                                  <tbody>
                                    { getDossiers.map((dossier, idx) =>
                                        <React.Fragment key={idx}>
                                          {this.props.authStore.level >= 8 &&
                                            <tr className="active tablerow">
                                                <td className="tablerow-actions">
                                                  {_.get(dossier, 'loanFileStatus.label') === "Financé" && dossier.billings && dossier.billings[0] && dossier.billings[0].paymentSteps && dossier.billings[0].paymentSteps !== 'Dossier payé' &&
                                                    <span title="Dossier financé non payé">
                                                      <span className="fa fa-euro"></span>
                                                    </span>
                                                  }
                                                </td>
                                                <td className="title" onClick={() => this.onClick(dossier)}> { dossier.name } </td>
                                                <td className="infos no-dossier" onClick={() => this.onClick(dossier)}> { dossier.referenceNumber } </td>
                                                <td className="infos" onClick={() => this.onClick(dossier)}> { (dossier.referenceNumber && dossier.loanFileType && dossier.loanFileType.substr(5)) || dossier.loanFileType && dossier.loanFileType } </td>
                                                <td className="status" title={helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label'))} onClick={() => this.onClick(dossier)}>
                                                  <div className="status-small">
                                                    <div className="progress-status">
                                                      <Status step={_.get(dossier, 'step')}/>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            }
                                          {this.props.authStore.level <= 7 && this.props.authStore.level >= 2 &&
                                            <tr className="active tablerow">
                                              <td className="title bank" onClick={() => this.onClick(dossier)}> { dossier.name } </td>
                                              {this.props.authStore.level >= 4 && this.props.authStore.level <= 6 &&
                                                <td className="infos no-dossier" onClick={() => this.onClick(dossier)}> { dossier.referenceNumber } </td>
                                              }
                                              <td className="infos" onClick={() => this.onClick(dossier)}> { helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label')) } </td>

                                              {this.props.authStore.level !== 3 ?
                                                <React.Fragment>
                                                  {dossier.step === 4 ?
                                                    <td className="infos" onClick={() => this.onClick(dossier)}> {_.get(dossier, 'fundedDate') && moment(dossier.fundedDate).format("DD/MM/YYYY") } </td>
                                                    :
                                                    <td className="infos" onClick={() => this.onClick(dossier)}> {_.get(dossier, 'retroScheduleOnOffersDeadline') && moment(dossier.retroScheduleOnOffersDeadline).format("DD/MM/YYYY") } </td>
                                                  }
                                                </React.Fragment>
                                                :
                                                <td className="infos" onClick={() => this.onClick(dossier)}>
                                                {dossier && dossier.banks && dossier.banks[0] && dossier.banks[0].banks.map((bank, idx) =>
                                                  <React.Fragment key={idx}>
                                                    {this.props.authStore.values.bankId === parseInt(bank.bank.replace("/v2/banks/", ""), 10) && bank.response}
                                                  </React.Fragment>
                                                )}
                                                </td>
                                              }
                                              <td className="status" title={helpers.getStatusLabel(_.get(dossier, 'loanFileStatus.label'))} onClick={() => this.onClick(dossier)}>
                                                <div className="status-small">
                                                  <div className="progress-status">
                                                    <Status step={_.get(dossier, 'step')}/>
                                                  </div>
                                                </div>
                                              </td>
                                            </tr>
                                            }
                                        </React.Fragment>
                                    )}
                                  </tbody>
                              </table>
                            </section>
                            <div className="info-footer">
                                <div className="clearfix">
                                    <ul className="pagination">
                                        {this.paging()}
                                    </ul>
                                </div>
                                <div className="total-count">Dossiers trouvés :  {getSizeListAll} </div>
                            </div>
                        </React.Fragment>}
                </div>
            </React.Fragment>
        );
    }
}

export default LoanFilesListPage;
