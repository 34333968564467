import React, { Component } from 'react'
import ContentText from '../../commons/ContentText'
import moment from 'moment'
import helpers from '../../../helpers'

class BankMeeting extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  onSubHeaderClick = (id) => {
        if (!this.state[id]) {// Si notre element est masqué
            this.setState({
                [id]: true
            })
        } else { //S'il est affiché
            this.setState({
                [id]: false
            })
        }
    }
    subContentClasses = (id) => {
        var result = 'section-content'

        if (this.state[id]) {
          result += ' open'
        }

        return result
    }

  render() {
    const { label, banks, loading } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <section className={contentClasses}>
        <label
          className="accordion__heading"
          htmlFor="accordion-rencontre-bank"
          onClick={this.onHeaderClick}
        >
          <i className="prp-ext-rdv"></i>
          {label}
          {banks && banks[0] === undefined && loading && (
            <div className="loader visible"></div>
          )}
        </label>

        <div className="accordion__content">
          {banks && !banks[0] ? (
            <div className="empty-info no-border">
              Aucun rendez-vous bancaire
            </div>
          ) : (
            <React.Fragment>
              <section className={this.subContentClasses("rencontre-bank")}>
                {banks &&
                  Array.isArray(banks) &&
                  banks.map((banks, idx) => (
                    <React.Fragment key={idx}>
                      <label
                        className="accordion__heading sub-menu"
                        onClick={() => this.onSubHeaderClick("rencontre-bank")}
                      >
                        Réunion bancaire
                        {!banks.firstMeetingDateTime &&
                          !banks.secondMeetingDateTime &&
                          ' (aucune programmée)'}
                      </label>
                      <div className="accordion__content">
                        {banks.firstMeetingDateTime ||
                        banks.secondMeetingDateTime ? (
                          <React.Fragment>
                            <div className="row-contact">
                              <div className="contact-element">
                                <span className="contact-name">
                                  Date et Heure de la 1ère réunion
                                </span>
                                <ContentText
                                  text={
                                    banks.firstMeetingDateTime &&
                                    moment(banks.firstMeetingDateTime).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  }
                                />
                              </div>
                              <div className="contact-element">
                                <span className="contact-name">
                                  Date et Heure de la 2ème réunion
                                </span>
                                <ContentText
                                  text={
                                    banks.secondMeetingDateTime &&
                                    moment(banks.secondMeetingDateTime).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="row-contact">
                              <div className="contact-element">
                                <span className="contact-name">
                                  Adresse de la réunion de présentation
                                </span>
                                {banks.bankPresentationMeetingAddress ?
                                <span className="contact-content"
                                dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                                  banks.bankPresentationMeetingAddress.replace(/\n/g, '<br />')
                                )}
                              />
                              :
                              <span className="contact-content">{ '-' }</span>
                                }
                              </div>
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="empty-info no-border">
                            Aucune réunion bancaire programmée
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
              </section>

              <section className={this.subContentClasses("individual-rencontre-bank")}>
                <label className="accordion__heading sub-menu" onClick={() => this.onSubHeaderClick("individual-rencontre-bank")}>
                  Rendez-vous bancaires individuels (
                  {banks &&
                    banks[0] &&
                    banks[0].meetings &&
                    banks[0].meetings.length}
                  )
                </label>
                <div className="accordion__content">
                  {banks &&
                  banks[0] &&
                  banks[0].meetings &&
                  banks[0].meetings.length === 0 ? (
                    <div className="empty-info no-border">
                      Aucun rendez-vous prévu
                    </div>
                  ) : (
                    <React.Fragment>
                      {banks &&
                        banks[0] &&
                        banks[0].meetings &&
                        banks[0].meetings.map((meeting, idx) => (
                          <React.Fragment key={idx}>
                            <div className="row-contact">
                              <div className="contact-element">
                                <span className="contact-name">
                                  Heure du RDV
                                </span>
                                {meeting.meetingDate ?
                                <ContentText
                                text={moment(meeting.meetingDate).format(
                                  'DD/MM/YYYY HH:mm'
                                )}
                              />
                              :
                              <ContentText
                                text={'-'}
                              />
                                }
                              </div>
                              <div className="contact-element">
                                <span className="contact-name">
                                  Nom de la banque
                                </span>
                                <ContentText text={meeting.bankName} />
                              </div>
                            </div>
                            <div className="row-contact meeting-bank-separator">
                              <div className="contact-element">
                                <span className="contact-name">
                                  Nom du contact et adresse du rdv
                                </span>
                                <ContentText text={meeting.meetingDetails} />
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  )}
                </div>
              </section>
            </React.Fragment>
          )}
        </div>
      </section>
    )
  }
}

export default BankMeeting
