import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'
import { inject, observer } from "mobx-react"
import helpers from '../../../helpers'
import { FormHelperText } from "@material-ui/core";
import LoanFilesUpdateBankForm from "../../../stores/LoanFileForm/LoanFilesUpdateBankForm"
import LoanFilesUpdateBankManagerForm from "../../../stores/LoanFileForm/LoanFilesUpdateBankManagerForm"

@inject('ui', 'dossiers', 'authStore')
@observer
class Bank extends Component {
    constructor(props) {
      super(props)
      this.state= {
        opened: false
      }
    }

    onHeaderClick = () => {
      this.setState({
        opened: !this.state.opened
      })
    }

    onSubHeaderClick = (id) => {
        if (!this.state[id]) {// Si notre element est masqué
            this.setState({
                [id]: true
            })
        } else { //S'il est affiché
            this.setState({
                [id]: false
            })
        }
    }
    subContentClasses = (id) => {
        var result = 'section-content'
        if (this.state[id]) {
            result += ' open'
        }
        return result
    }

    componentDidMount() {
        this.form = new LoanFilesUpdateBankForm(this.props)
        this.formManager = new LoanFilesUpdateBankManagerForm(this.props)
        this.updateForm = this.updateForm.bind(this)
        this.updateInfo1 = this.updateInfo1.bind(this)
        this.updateInfo1 = this.updateInfo1.bind(this)
        this.updateInfo2 = this.updateInfo2.bind(this)
    }

    updateForm(e, bank) {
        this.form.onReset(e)
        this.props.ui.toggleModifBank(true)
        this.props.ui.setModifBank(bank.id)
        this.form.$("id").value = bank.id
        if (bank.localContactLastName !== null) { this.form.$("firstName").value = bank.localContactLastName } else { this.form.$("firstName").value = '' }
        if (bank.localContactFirstName !== null) { this.form.$("lastName").value = bank.localContactFirstName } else { this.form.$("lastName").value = '' }
        if (bank.localContactEmail !== null) { this.form.$("email").value = bank.localContactEmail } else { this.form.$("email").value = '' }
        if (bank.localContactPhone !== null) { this.form.$("phone").value = bank.localContactPhone } else { this.form.$("phone").value = '' }
        if (bank.internalNotes !== null) { this.form.$("noteIntern").value = bank.internalNotes } else { this.form.$("noteIntern").value = '' }
        if (bank.customersNotes !== null) { this.form.$("noteClient").value = bank.customersNotes } else { this.form.$("noteClient").value = '' }
    }
    cancelForm(e) {
        this.props.ui.toggleModifBank(false)
        this.props.ui.setModifBank(0)
        this.form.onReset(e)
    }

    updateInfo1(bank) {
        this.props.ui.modifInfoBank1 = true
        this.formManager.$("id").value = bank.id
        if (bank.infoToBankAfterMeetings !== null) { this.formManager.$("infoBank1").value = bank.infoToBankAfterMeetings }
        if (bank.loanFileBanksEmail !== null) { this.formManager.$("infoBank2").value = bank.loanFileBanksEmail }
    }

    updateInfo2(bank) {
        this.props.ui.modifInfoBank2 = true
        this.formManager.$("id").value = bank.id
        if (bank.loanFileBanksEmail !== null) { this.formManager.$("infoBank2").value = bank.loanFileBanksEmail }
        if (bank.infoToBankAfterMeetings !== null) { this.formManager.$("infoBank1").value = bank.infoToBankAfterMeetings }
    }

    sentToBank = (loanFile) =>{
      console.log(loanFile)
      const loanFileStatusLabel = loanFile && loanFile.loanFileStatus && loanFile.loanFileStatus.label
      console.log('label', loanFileStatusLabel)
      const exludeLabels = [
        'Apporteur à contacter',
        'Prospect à contacter',
        'Prospect à contacter | Message laissé',
        'En attente de confirmation client',
        'Lettre de mission signée',
        'En cours de constitution (A attribuer à un BO)',
        'En cours de constitution | Réception des premiers documents',
        'En cours de constitution',
        'En cours de constitution | Rdv banques dans les 30 jours',
        'Découverte en cours'
      ]
      return exludeLabels.indexOf(loanFileStatusLabel) == -1;

    }

    render() {
        const { label, banks, bankGreetingEmail,  getLoanFileIdId, loading } = this.props
        const { loadingUpdate, getLoanFileId} = this.props.dossiers
        const role = this.props.authStore.values.role
        const impersonatedRole = this.props.authStore.values.impersonatedRole
        const { form, formManager } = this
        const { opened } = this.state
        let contentClasses = 'section-content'
        if (opened) {
          contentClasses = contentClasses + ' open'
        }

        return (
            <React.Fragment>
                <section className={contentClasses}>

                    <label className="accordion__heading" htmlFor="accordion-bank" onClick={this.onHeaderClick}>
                        <i className="prp-ext-bank"></i>
                        {label}
                        {banks && banks[0] === undefined && loading && <div className="loader visible"></div>}
                    </label>

                    <div className="accordion__content bank">
                        { (banks && !banks[0]) || !this.sentToBank(getLoanFileId)   ?
                            <div className="empty-info no-border">Aucune banque interrogée</div>
                            :
                            <React.Fragment>
                                {banks && Array.isArray(banks) && banks.map((banks, idx) =>
                                    <React.Fragment key={idx}>
                                        {banks.banks.map((bank, idx) =>
                                            <React.Fragment key={idx}>
                                                {bank.name && bank.name !== "Siagi" && bank.name !== "" &&
                                                    <section className={this.subContentClasses(bank.id)}>
                                                        {!bank.response &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank no-arrow" : "accordion__heading sub-menu bank"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("refus") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank refus no-arrow" : "accordion__heading sub-menu bank refus"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("banque retenue") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) ? "accordion__heading sub-menu bank banque-retenue no-arrow" : "accordion__heading sub-menu bank banque-retenue"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("accord de") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) ? "accordion__heading sub-menu bank accord no-arrow" : "accordion__heading sub-menu bank accord"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("accord de") === -1 &&
                                                            bank.response.toLowerCase().indexOf("banque retenue") === -1 &&
                                                            bank.response.toLowerCase().indexOf("refus") === -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank no-arrow" : "accordion__heading sub-menu bank"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {((this.props.authStore.level >= 4 && this.props.authStore.level <= 6)
                                                            ||
                                                            (this.props.authStore.level === 2 &&
                                                                (
                                                                    !bank.response
                                                                        ||
                                                                    (bank.response.toLowerCase().indexOf("accord de") === -1 && bank.response.toLowerCase().indexOf("banque retenue") === -1)
                                                                )
                                                            )
                                                        )
                                                            ?
                                                            null
                                                            :
                                                            <div className="accordion__content">
                                                                {bank.response && (bank.response.toLowerCase().indexOf("banque retenue") !== -1 || bank.response.toLowerCase().indexOf("accord de") !== -1) &&
                                                                    <React.Fragment>
                                                                        <table className="table-data">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>Montant du financement</td>
                                                                                    <td>{bank.fundedAmount && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bank.fundedAmount)}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Taux proposé</td>
                                                                                    <td>{bank.proposedRate && bank.proposedRate + ' %'}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Délégation assurances ?</td>
                                                                                    <td>{bank.insuranceDelegation}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Taux assurances</td>
                                                                                    <td>{bank.insuranceRate}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Taux CB</td>
                                                                                    <td>{bank.proposedCreditCardRate}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Infos sur taux CB</td>
                                                                                    <td>{bank.creditCardRateInfos}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Garanties proposées</td>
                                                                                    <td>{bank.proposedGuarantees}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Frais de dossier</td>
                                                                                    <td>{bank.fees && new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(bank.fees)}</td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </React.Fragment>
                                                                }
                                                                {!this.props.ui.modifBank || this.props.ui.modifIdBank !== bank.id ?
                                                                    <React.Fragment>
                                                                      {(this.props.authStore.level != 2 && this.props.authStore.impersonatedLevel != 2) &&
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Nom du contact local</span>
                                                                                <ContentText text={bank.localContactLastName} />
                                                                            </div>
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Prénom du contact local</span>
                                                                                <ContentText text={bank.localContactFirstName} />
                                                                            </div>
                                                                        </div>}
                                                                      {(this.props.authStore.level != 2 && this.props.authStore.impersonatedLevel != 2) &&
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Email du contact local</span>
                                                                                <ContentText text={bank.localContactEmail} />
                                                                            </div>
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Téléphone du contact local</span>
                                                                                <ContentText text={bank.localContactPhone} />
                                                                            </div>
                                                                        </div>}
                                                                      {(this.props.authStore.level != 2 && this.props.authStore.impersonatedLevel != 2) &&
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Notes internes</span>
                                                                                {bank.internalNotes ?
                                                                                    <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(bank.internalNotes.replace(/\n/g, "<br />"))} />
                                                                                    : '-'}
                                                                            </div>
                                                                        </div>}
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Notes client</span>
                                                                                {bank.customersNotes ?
                                                                                    <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(bank.customersNotes.replace(/\n/g, "<br />"))} />
                                                                                    : '-'}
                                                                            </div>
                                                                        </div>
                                                                        {this.props.authStore.level !== 2 && <button className="button button-primary" type="button" onClick={(e) => { this.updateForm(e, bank) }}>Modifier</button>}
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <form onSubmit={form.onSubmit}>
                                                                            {loadingUpdate && <div className="loader visible"></div>}
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("firstName").id}>
                                                                                        {form.$("firstName").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("firstName").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("firstName").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("firstName").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("lastName").id}>
                                                                                        {form.$("lastName").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("lastName").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("lastName").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("lastName").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("email").id}>
                                                                                        {form.$("email").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("email").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("email").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("email").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("phone").id}>
                                                                                        {form.$("phone").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("phone").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("phone").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("phone").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("noteIntern").id}>
                                                                                        {form.$("noteIntern").label}
                                                                                    </label>
                                                                                    <textarea
                                                                                        {...form.$("noteIntern").bind()}
                                                                                        className="input_text" />
                                                                                    {form.$("noteIntern").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("noteIntern").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("noteClient").id}>
                                                                                        {form.$("noteClient").label}
                                                                                    </label>
                                                                                    <textarea
                                                                                        {...form.$("noteClient").bind()}
                                                                                        className="input_text" />
                                                                                    {form.$("noteClient").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("noteClient").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <button className="button button-primary" type="button" onClick={(e) => { this.cancelForm(e) }}>Annuler</button>
                                                                            <button className="button button-primary" type="submit">Valider</button>
                                                                        </form>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        }
                                                    </section>
                                                }
                                            </React.Fragment>
                                        )}
                                        {this.props.authStore.level >= 8 &&
                                            <React.Fragment>
                                                <div className="row-contact">
                                                    {!this.props.ui.modifInfoBank1 ?
                                                        <div className="contact-element">
                                                            <span className="contact-name">Infos aux banques après RDV bancaire {this.props.authStore.level !== 2 && <i className="fa edit fa-edit" onClick={() => { this.updateInfo1(banks) }}></i>}</span>
                                                            {banks.infoToBankAfterMeetings ? <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(banks.infoToBankAfterMeetings.replace(/\n/g, "<br />"))} /> : <span>{'-'}</span>}
                                                        </div>
                                                        :
                                                        <div className="contact-element">
                                                            <form onSubmit={formManager.onSubmit}>
                                                                {loadingUpdate && <div className="loader visible"></div>}
                                                                <label className="contact-name" htmlFor={formManager.$("infoBank1").id}>
                                                                    {formManager.$("infoBank1").label}
                                                                </label>
                                                                <textarea
                                                                    {...formManager.$("infoBank1").bind()}
                                                                    className="input_text" />
                                                                {formManager.$("infoBank1").error !== null && (
                                                                    <FormHelperText error={true} className="field-error">
                                                                        {formManager.$("infoBank1").error}
                                                                    </FormHelperText>
                                                                )}
                                                                <button className="button button-primary" type="submit">Valider</button>
                                                            </form>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row-contact">
                                                    {!this.props.ui.modifInfoBank2 ?
                                                        <div className="contact-element">
                                                            <span className="contact-name">Emails des banques présentes au dossier {this.props.authStore.level !== 2 && <i className="fa edit fa-edit" onClick={() => { this.updateInfo2(banks) }}></i>}</span>
                                                            {banks.loanFileBanksEmail ? <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(banks.loanFileBanksEmail.replace(/\n/g, "<br />"))} /> : <span>{'-'}</span>}
                                                        </div>
                                                        :
                                                        <div className="contact-element">
                                                            <form onSubmit={formManager.onSubmit}>
                                                                {loadingUpdate && <div className="loader visible"></div>}
                                                                <label className="contact-name" htmlFor={formManager.$("infoBank2").id}>
                                                                    {formManager.$("infoBank2").label}
                                                                </label>
                                                                <textarea
                                                                    {...formManager.$("infoBank2").bind()}
                                                                    className="input_text" />
                                                                {formManager.$("infoBank2").error !== null && (
                                                                    <FormHelperText error={true} className="field-error">
                                                                        {formManager.$("infoBank2").error}
                                                                    </FormHelperText>
                                                                )}
                                                                <button className="button button-primary" type="submit">Valider</button>
                                                            </form>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row-contact">
                                                    <div className="contact-element">
                                                        <span className="contact-name">Remerciements envoyés aux banques (action de votre Back-Office) ?</span>
                                                        <ContentText text={bankGreetingEmail ? 'Oui' : 'Non'} />
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                        {!bankGreetingEmail && this.props.authStore.level >= 8 &&
                                            <div className="row-contact">
                                                <div className="contact-element">
                                                    <span className="contact-name">Confirmez-vous l'envoi des remerciements aux banques par votre Back Office ?</span>
                                                    <button className="button button-primary" type="button" onClick={() => { this.props.dossiers.confirmThanks(getLoanFileIdId) }}>Modifier</button>
                                                </div>
                                            </div>
                                        }
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                    </div>
                </section>
            </React.Fragment>
        );
    }
}


export default Bank;
