import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

@inject('contacts', 'authStore')
@observer
class FilterMandataire extends Component {

  onChangeFilter(event) {
    this.props.contacts.filterOwner = event.target.value
    this.props.contacts.list()
  }

  render() {
    return (
      <React.Fragment>
        <div className="order-filter">
          <label>Mandataire</label>
          <select name="filter_owner" value={this.props.contacts.filterOwner} onChange={this.onChangeFilter.bind(this)}>
            <option value="all">Tous les contacts</option>
            <option value="mine">Mes contacts</option>
            <option value="empty">Les contacts sans mandataire</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterMandataire;
