import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  nameGroup: {
    name: "nameGroup",
    id: "nameGroup",
    label: "Nom du Groupement ou Franchise (si vous êtes en réseau).",
    rules: "string",
    type: "text"
  },
  socialReason: {
    name: "socialReason",
    id: "socialReason",
    label: "Raison sociale (nom) de votre future entreprise",
    rules: "string",
    type: "text"
  },
  CreationDate: {
    name: "CreationDate",
    id: "CreationDate",
    label: "Date de création envisagée",
    rules: "string",
    type: "text"
  },
  StartingDate: {
    name: "StartingDate",
    id: "StartingDate",
    label: "Date envisagée de démarrage de votre activité",
    rules: "string",
    type: "text"
  },
  EndDate: {
    name: "EndDate",
    id: "EndDate",
    label: "Date de clôture fiscale envisagée",
    rules: "string",
    type: "text"
  },
  futureAddress: {
    name: "futureAddress",
    id: "futureAddress",
    label: "Adresse complète de votre future entreprise",
    rules: "string",
    type: "text"
  },
  socialPlan: {
    name: "socialPlan",
    id: "socialPlan",
    label: "Nombre d’emplois que vous avez prévu de créer",
    rules: "string",
    type: "text"
  },
  numberJobs: {
    name: "numberJobs",
    id: "numberJobs",
    label: "Nombre d’emplois que vous allez conserver",
    rules: "string",
    type: "text"
  },
  preciseActivity: {
    name: "preciseActivity",
    id: "preciseActivity",
    label: "Activité précise de votre future entreprise",
    rules: "string",
    type: "text"
  },
  bankCoordinates: {
    name: "bankCoordinates",
    id: "bankCoordinates",
    label: "Si vous avez déjà un ou plusieurs partenaires bancaires, coordonnées complètes de la ou les banques ainsi que les coordonnées du conseiller bancaire",
    rules: "string",
    type: "text"
  },
  amountOverallInvestment: {
    name: "amountOverallInvestment",
    id: "amountOverallInvestment",
    label: "Montant approximatif de l’investissement global",
    rules: "string",
    type: "text"
  },
  amountContributedInvestment: {
    name: "amountContributedInvestment",
    id: "amountContributedInvestment",
    label: "Montant approximatif de votre apport",
    rules: "string",
    type: "text"
  },
  remarks: {
    name: "remarks",
    id: "remarks",
    label: "Remarques diverses à nous faire sur votre projet",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class YourBusiness extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default YourBusiness;
