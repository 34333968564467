import React, { Component } from 'react';
import logo from './pictos_menu.png'
import logoSurvey from './survey.png'
import { Link } from "react-router-dom";
import { inject } from "mobx-react"

@inject('header')
class NavItem extends Component {

  reset() {
    this.props.header.setStateMenuMobile(false)
  }

  render() {
    const { title, href } = this.props;

    var backgroundImage = {
      background: 'transparent url(' + logo + ')' + this.props.position + ' no-repeat',
      paddingLeft: '3.5rem',
      backgroundSize: '3rem',
    }

    if(href === "/survey") {
      backgroundImage = {
        background: 'transparent url(' + logoSurvey + ')' + this.props.position + ' no-repeat',
        paddingLeft: '3.5rem',
        backgroundSize: '28px',
      }
    }

    return (
      <div className={href === '/' ? this.props.location.pathname === '/' ? "active nav-item" : "nav-item" : this.props.location.pathname.indexOf(href) >= 0 ? "active nav-item" : "nav-item"} onClick={this.reset.bind(this)}>
        <Link to={href} style={backgroundImage} className={this.props.size && 'size-small'}>
          {title}
        </Link>
      </div>
    );
  }
}

export default NavItem;
