import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

@inject('authStore', 'picklist', 'dossiers')
@observer
class FilterBankResponse extends Component {

  onChangeFilter(event) {
    this.props.picklist.changeBankResponse(event.target.value)
  }

  componentDidMount() {
    this.props.picklist.listFilterBankResponse()
  }

  render() {

    const { getFilterBankResponse } = this.props.picklist;

    return (
      <React.Fragment>
        <div className="bank-reponse-filter">
          <label htmlFor="bank_response">Votre réponse :</label>
          <div className="checkboxes">

            {getFilterBankResponse.map((reponse, idx) => (
              <React.Fragment key={idx}>
                <div className="checkbox-group">
                  <span className="checkbox">
                    <input type="checkbox" name="bank_response[]" value={reponse.label} checked={this.props.picklist.bankStateResponse[reponse.label]} id={reponse.label+'_id'} onChange={this.onChangeFilter.bind(this)} />
                  </span>
                  <label htmlFor={reponse.label+'_id'}>{reponse.label}</label>
                </div>
              </React.Fragment>
            ))}

            <div className="actions">
              <button onClick={() => {this.props.picklist.allChecked(true)}}>Tout cocher</button> / <button onClick={() => {this.props.picklist.allChecked(false)}}>Tout décocher</button>
            </div>
          </div>
        </div>
        <button className="button button-primary" onClick={() => this.props.dossiers.list() }>Valider</button>
      </React.Fragment>
    )
  }
}

export default FilterBankResponse;
