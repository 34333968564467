import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

@inject('dossiers', 'authStore', 'ui')
@observer
class FilterOrder extends Component {

  onChangeFilter(event) {
    this.props.dossiers.filterOrder = event.target.value
    this.props.ui.setLocation('null') //Responsive design
    this.props.dossiers.list()
  }

  render() {
    return (
      <React.Fragment>
        <div className="order-filter">
          <label>Trier par</label>
          <select name="dossier_order" value={this.props.dossiers.filterOrder} onChange={this.onChangeFilter.bind(this)}>
            <option value="0">Date de modification décroissant</option>
            <option value="1">Date de modification croissant</option>
            <option value="2">Date de création décroissant</option>
            <option value="3">Date de création croissant</option>
            {this.props.authStore.level >= 8 &&
              <React.Fragment>
                <option value="4">N° de dossier décroissant</option>
                <option value="5">N° de dossier croissant</option>
              </React.Fragment>
            }
          </select>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterOrder;
