import React, { Component } from 'react';
import { inject, observer } from "mobx-react"

@inject('authStore', 'header', 'ui')
@observer
class Header extends Component {

  componentDidMount() {
    this.props.authStore.setLevel(this.props.level)
    this.props.authStore.setTokenValues()
    if (this.props.authStore.xSwitchUser !== null && this.props.authStore.values.impersonatedUsername === '') {
      this.props.authStore.getProfile();
    }
    if (this.props.authStore.level === 3 && this.props.authStore.values.bankId === '') {
      this.props.authStore.getBankId()
    }
  }

  componentDidUpdate() {
    if (this.props.authStore.level === 3 && this.props.authStore.values.bankId === '') {
      this.props.authStore.getBankId()
    }
  }

  toggleHidden() {
    this.props.header.isHidden = !this.props.header.isHidden
  }

  onLogoutClick = (e) => {
    e.preventDefault();
    this.props.authStore.logout()
      .then(this.props.history.push("/login"))
  }

  onFinishImpersonation = (e) => {
    e.preventDefault();
    if (this.props.authStore) {
      this.props.authStore.resetSwitchUser()
      this.props.history.push("/")
    }
  }

  toggle() {
    this.props.header.toggleStateMenuMobile();
  }

  goBack() {
    this.props.ui.location = ''
    this.props.history.goBack()
  }

  render() {
    const { authStore } = this.props
    const isImpersonated = authStore && authStore.xSwitchUser
    const impersonatedUsername = (isImpersonated) ? authStore.values.impersonatedUsername : ''

    const Dropdown = () => (
      <ul className="dropdown-menu">
        {this.props.authStore.xSwitchUser === null && this.props.authStore.level === 10 && <li className="target-dp" onClick={() => { this.props.ui.setSwitchUser(true) }}><i className="fa fa-exchange"></i> Se connecter en tant que</li>}
        <li className="target-dp" onClick={() => { this.props.ui.setChangePassword(true) }}><i className="fa fa-key"></i> Changer mot de passe</li>
        {!isImpersonated && <li className="target-dp" onClick={this.onLogoutClick}><i className="fa fa-sign-out"></i> Déconnexion</li>}
        {isImpersonated && <li className="target-dp" onClick={this.onFinishImpersonation}><i className="fa fa-sign-out"></i> Déconnexion {impersonatedUsername}</li>}
      </ul>
    )
    return (
      <header className="home">
        {this.props.history && this.props.history.location && this.props.history.location.pathname === '/' ?
          <div className="menu-back mobile" onClick={this.toggle.bind(this)}>
            <i className="fa fa-bars"></i>
          </div>
          :
          <div className={this.props.header.getDevice === 'mobile' ? 'menu-back mobile' : 'menu-back desktop'} onClick={this.goBack.bind(this)}>
            <i className="fa fa-angle-left"></i>
          </div>
        }
        <h1>{this.props.header.getHeaderPage}</h1>
        {this.props.header.isHidden && <Dropdown />}

        <div className="user-menu">
          <span className="dropdown-toggle" onClick={() => { this.props.header.toggleHidden() }}>
            {this.props.authStore.xSwitchUser === null ?
              this.props.fullname
              :
              this.props.authStore.values.impersonatedUsername
            }
            <i className="fa fa-caret-down"></i></span>
        </div>
      </header>
    );
  }
}

export default Header;
