import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'

const plugins = { dvr: validatorjs }

const fields = {
  natureOne: {
    name: 'natureOne',
    id: 'natureOne',
    label: 'Nature 1',
    rules: 'string',
    type: 'text'
  },
  guaranteedAmountOne: {
    name: 'guaranteedAmountOne',
    id: 'guaranteedAmountOne',
    label: 'Montant garanti 1',
    rules: 'string',
    type: 'text'
  },
  beneficiaryEstablishmentOne: {
    name: 'beneficiaryEstablishmentOne',
    id: 'beneficiaryEstablishmentOne',
    label: 'Etablissement bénéficiaire 1',
    rules: 'string',
    type: 'text'
  },
  endDateCommitmentOne: {
    name: 'endDateCommitmentOne',
    id: 'endDateCommitmentOne',
    label: 'Date de fin de l’engagement 1',
    rules: 'string',
    type: 'text'
  },
  natureTwo: {
    name: 'natureTwo',
    id: 'natureTwo',
    label: 'Nature 2',
    rules: 'string',
    type: 'text'
  },
  guaranteedAmountTwo: {
    name: 'guaranteedAmountTwo',
    id: 'guaranteedAmountTwo',
    label: 'Montant garanti 2',
    rules: 'string',
    type: 'text'
  },
  beneficiaryEstablishmentTwo: {
    name: 'beneficiaryEstablishmentTwo',
    id: 'beneficiaryEstablishmentTwo',
    label: 'Etablissement bénéficiaire 2',
    rules: 'string',
    type: 'text'
  },
  endDateCommitmentTwo: {
    name: 'endDateCommitmentTwo',
    id: 'endDateCommitmentTwo',
    label: 'Date de fin de l’engagement 2',
    rules: 'string',
    type: 'text'
  },
  natureThree: {
    name: 'natureThree',
    id: 'natureThree',
    label: 'Nature 3',
    rules: 'string',
    type: 'text'
  },
  guaranteedAmountThree: {
    name: 'guaranteedAmountThree',
    id: 'guaranteedAmountThree',
    label: 'Montant garanti 3',
    rules: 'string',
    type: 'text'
  },
  beneficiaryEstablishmentThree: {
    name: 'beneficiaryEstablishmentThree',
    id: 'beneficiaryEstablishmentThree',
    label: 'Etablissement bénéficiaire 3',
    rules: 'string',
    type: 'text'
  },
  endDateCommitmentThree: {
    name: 'endDateCommitmentThree',
    id: 'endDateCommitmentThree',
    label: 'Date de fin de l’engagement 3',
    rules: 'string',
    type: 'text'
  },
  natureFour: {
    name: 'natureFour',
    id: 'natureFour',
    label: 'Nature 4',
    rules: 'string',
    type: 'text'
  },
  guaranteedAmountFour: {
    name: 'guaranteedAmountFour',
    id: 'guaranteedAmountFour',
    label: 'Montant garanti 4',
    rules: 'string',
    type: 'text'
  },
  beneficiaryEstablishmentFour: {
    name: 'beneficiaryEstablishmentFour',
    id: 'beneficiaryEstablishmentFour',
    label: 'Etablissement bénéficiaire 4',
    rules: 'string',
    type: 'text'
  },
  endDateCommitmentFour: {
    name: 'endDateCommitmentFour',
    id: 'endDateCommitmentFour',
    label: 'Date de fin de l’engagement 4',
    rules: 'string',
    type: 'text'
  },
  natureFive: {
    name: 'natureFive',
    id: 'natureFive',
    label: 'Nature 5',
    rules: 'string',
    type: 'text'
  },
  guaranteedAmountFive: {
    name: 'guaranteedAmountFive',
    id: 'guaranteedAmountFive',
    label: 'Montant garanti 5',
    rules: 'string',
    type: 'text'
  },
  beneficiaryEstablishmentFive: {
    name: 'beneficiaryEstablishmentFive',
    id: 'beneficiaryEstablishmentFive',
    label: 'Etablissement bénéficiaire 5',
    rules: 'string',
    type: 'text'
  },
  endDateCommitmentFive: {
    name: 'endDateCommitmentFive',
    id: 'endDateCommitmentFive',
    label: 'Date de fin de l’engagement 5',
    rules: 'string',
    type: 'text'
  }
}

const hooks = {
  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }
}
validatorjs.useLang('fr')

class OtherInformation extends MobxReactForm {
  stores

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks })
    this.stores = stores
    this.formName = formName
  }
}

export default OtherInformation
