import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

@inject("dossiers")
@observer
class FilterDate extends Component {

  onChangeDateBefore(event) {
    this.props.dossiers.dateBefore = event.target.value
  }
  onChangeDateAfter(event) {
    this.props.dossiers.dateAfter = event.target.value
  }
  onChangeFilter(event) {
    this.props.dossiers.filter = event.target.value
  }

  render() {
    return (
      <React.Fragment>
        <div className="dates-filter">
          <label>Date de création :</label>
          <input type="date" placeholder="Début" value={this.props.dossiers.dateAfter} onChange={this.onChangeDateAfter.bind(this)}/>
          <input type="date" placeholder="Fin" value={this.props.dossiers.dateBefore} onChange={this.onChangeDateBefore.bind(this)}/>
        </div>
        <div className="type-filter">
          <label>Filtrer par</label>
          <select name="groupe_by" value={this.props.dossiers.filter} onChange={this.onChangeFilter.bind(this)}>
            <option value="">Tous les dossiers</option>
            <option value="Groupement">Dossiers Groupement</option>
            <option value="Indépendant">Dossiers Indépendants</option>
          </select>
        </div>
      </React.Fragment>
    );
  }
}

export default FilterDate;
