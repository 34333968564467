import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { FormHelperText } from "@material-ui/core";
import ContactsUpdateFormStore from "../../stores/ContactForm/ContactsUpdateFormStore";

@inject("contacts", "header", "picklist")
@observer
class ContactUpdateContact extends Component {

  componentDidMount() {
    this.setState({ contactsForm: false });
    this.props.picklist.picklistFunction();
    this.props.picklist.picklistContactTitle();
    this.props.contacts.oneContact(this.props.match.params.contactId);
    document.title = "Modification d'un contact"
    this.props.header.setHeaderPage("Modification d'un contact", "desktop");
  }

  componentDidUpdate() {
    if (
      this.state.contactsForm === false &&
      this.props.contacts.getContactsId.id !== undefined
    ) {
      const { getContactsId } = this.props.contacts;

      this.form = new ContactsUpdateFormStore(this, {
        values: {
          id: getContactsId.id,
          contactTitle: getContactsId.contactTitle && getContactsId.contactTitle["@id"],
          firstName: getContactsId.firstName,
          lastName: getContactsId.lastName,
          mobilePhone: getContactsId.mobilePhone,
          phone: getContactsId.phone,
          email: getContactsId.email,
          functionInteger: getContactsId.function && getContactsId.function['@id']
        }
      });
      this.setState({ contactsForm: true });
    }
  }

  render() {
    const { getContactsId, loading } = this.props.contacts;

    if (getContactsId.id === undefined || this.form === undefined) {
      return <div className="empty-info">Chargement en cours...</div>;
    } else {
      const contactsForm = this.form;

      return (
        <React.Fragment>
          <div id="create-contact">
            <form
              className="create-contact-container"
              onSubmit={contactsForm.onSubmit}
            >
            {(loading || this.props.picklist.loading || this.props.picklist.FunctionLoading) && <div className="loader visible"></div>}
              <div className="row-contact">
                <div className="contact-element no-border">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("contactTitle").id}
                  >
                    {contactsForm.$("contactTitle").label}
                  </label>
                  <select
                    {...contactsForm.$("contactTitle").bind()}
                  >
                    {this.props.picklist.getAllContactTitle.map(
                      (contactTitle, idx) => (
                        <option key={idx} value={contactTitle["@id"]}>
                          {contactTitle.label}
                        </option>
                      )
                    )}
                    <option value="">--</option>
                  </select>
                </div>
                <div className="contact-element no-border" />
              </div>
              <div className="row-contact">
                <div className="contact-element no-border">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("firstName").id}
                  >
                    {contactsForm.$("firstName").label}
                  </label>
                  <input
                    {...contactsForm.$("firstName").bind()}
                    type="text"
                    className="input_text"
                  />
                  {contactsForm.$("firstName").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("firstName").error}
                    </FormHelperText>
                  )}
                </div>

                <div className="contact-element no-border">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("lastName").id}
                  >
                    {contactsForm.$("lastName").label}
                  </label>
                  <input
                    {...contactsForm.$("lastName").bind()}
                    type="text"
                    className="input_text"
                  />
                  {contactsForm.$("lastName").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("lastName").error}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("mobilePhone").id}
                  >
                    {contactsForm.$("mobilePhone").label}
                  </label>
                  <input
                    {...contactsForm.$("mobilePhone").bind()}
                    type="text"
                    className="input_text"
                  />
                  {contactsForm.$("mobilePhone").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("mobilePhone").error}
                    </FormHelperText>
                  )}
                </div>
                <div className="contact-element">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("phone").id}
                  >
                    {contactsForm.$("phone").label}
                  </label>
                  <input
                    {...contactsForm.$("phone").bind()}
                    type="text"
                    className="input_text"
                  />
                  {contactsForm.$("phone").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("phone").error}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("email").id}
                  >
                    {contactsForm.$("email").label}
                  </label>
                  <input
                    {...contactsForm.$("email").bind()}
                    type="text"
                    className="input_text"
                  />
                  {contactsForm.$("email").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("email").error}
                    </FormHelperText>
                  )}
                </div>
                <div className="contact-element no-border">
                  <label
                    className="contact-name"
                    htmlFor={contactsForm.$("functionInteger").id}
                  >
                    {contactsForm.$("functionInteger").label}
                  </label>
                  <select
                    {...contactsForm.$("functionInteger").bind()}
                  >
                    <option value="" disabled>
                      --
                    </option>
                    {this.props.picklist.getAllFunction.map((fun, idx) => (
                      <option key={idx} value={fun["@id"]}>
                        {fun.label}
                      </option>
                    ))}
                  </select>
                  {contactsForm.$("functionInteger").error !== null && (
                    <FormHelperText error={true} className="field-error">
                      {contactsForm.$("functionInteger").error}
                    </FormHelperText>
                  )}
                </div>
              </div>
              <div className="btn-actions">
                <button
                  className="button button-secondary"
                  type="button"
                  onClick={() => this.props.history.goBack()}
                >
                  Annuler
                </button>
                <button className="button button-primary" type="submit">
                  Mettre à jour
                </button>
              </div>
            </form>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default ContactUpdateContact;
