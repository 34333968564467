import { observable, computed, action, reaction } from 'mobx'
import jwt_decode from 'jwt-decode'
import agent from './agent'
import axios from 'axios'
import { API_HOST } from '../config/_entrypoint'
import history from '../history'

class AuthStore {
  constructor(stores) {
    this.stores = stores
    reaction(
      () => this.token,
      token => {
        if (token) {
          window.localStorage.setItem('jwt', token)
          window.localStorage.setItem('refresh_token', this.refresh_token)
        } else {
          window.localStorage.removeItem('jwt')
          window.localStorage.removeItem('refresh_token')
        }
      }
    )
    reaction(
      () => this.xSwitchUser,
      xSwitchUser => {
        if (xSwitchUser) {
          window.localStorage.setItem('SwitchUser', xSwitchUser)
        } else {
          window.localStorage.removeItem('SwitchUser')
        }
      }
    )
  }

  @observable inProgress = false
  @observable errors = undefined

  @observable token = window.localStorage.getItem('jwt')
  @observable tokenCookie = this.getCoockie('token')
  @observable refresh_token = window.localStorage.getItem('refresh_token')
  @observable refresh_tokenCookie = this.getCoockie('refresh_token')
  @observable xSwitchUser = window.localStorage.getItem('SwitchUser')
  remember = ''

  @observable values = {
    username: '',
    role: '',
    level: '',
    id: '',
    impersonatedUsername: '',
    impersonatedEmail: '',
    impersonatedRole: '',
    impersonatedLevel: '',
    impersonatedId: '',
    bankId: ''
  }

  @action setToken(token, refresh_token) {
    this.token = token
    this.refresh_token = refresh_token
  }

  @action getToken() {
    if (this.token) {
      return this.token
    } else {
      return this.tokenCookie
    }
  }

  @action getRefreshToken() {
    if (this.refresh_token) {
      return this.refresh_token
    } else {
      return this.refresh_tokenCookie
    }
  }

  @action getCoockie(name) {
    var nomEtEgal = name + '='
    var cTableau = document.cookie.split(';')
    for (var i = 0; i < cTableau.length; i++) {
      var c = cTableau[i]
      while (c.charAt(0) === ' ') c = c.substring(1, c.length)
      if (c.indexOf(nomEtEgal) === 0)
        return c.substring(nomEtEgal.length, c.length)
    }
    return null
  }

  @action setCoockie(token, refresh_token) {
    document.cookie = 'token=' + token + '; path=/'
    document.cookie = 'refresh_token=' + refresh_token + '; path=/'
  }

  @action setSwitchUser(xSwitchUser) {
    this.xSwitchUser = xSwitchUser
  }

  @computed get decodedToken() {
    if (this.token) {
      return jwt_decode(this.token)
    } else if(this.tokenCookie) {
      return jwt_decode(this.tokenCookie)
    }
    return null
  }

  @action setUsername(username) {
    this.values.username = username
  }

  @computed get username() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedUsername
    } else {
      return this.values.username
    }
  }
  @computed get email() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedEmail
    } else {
      return this.decodedToken.email
    }
  }
  @computed get role() {
    return this.values.role
  }
  @computed get level() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedLevel
    } else {
      return this.decodedToken.level
    }
  }
  @computed get id() {
    if (this.xSwitchUser !== null) {
      return this.values.impersonatedId
    } else if (typeof this.values.id !== 'undefined') {
      return this.values.id
    } else {
      return null
    }
  }

  @action setTokenValues() {
    this.values.email = this.decodedToken.email
    this.values.level = this.decodedToken.level
    this.values.username = this.decodedToken.fullname
    this.values.id = this.decodedToken.id
    this.values.role = this.decodedToken.roles[0]
  }

  @action setEmail(email) {
    this.values.email = email
  }

  @action setImpersonatedEmail(email) {
    this.values.impersonatedEmail = email
  }

  @action setPassword(password) {
    this.values.password = password
  }

  @action setRememberMe(val) {
    this.remember = val
  }

  @action setRole(role) {
    this.values.role = role
  }

  @action setLevel(level) {
    this.values.level = level
  }

  @action reset() {
    this.values.username = ''
    this.values.email = ''
    this.values.password = ''
  }

  @action resetSwitchUser() {
    window.localStorage.removeItem('SwitchUser')
    this.xSwitchUser = null
    this.stores.dossiers.resetCache()
    this.stores.contacts.resetCache()
    this.stores.picklist.resetCache()
  }

  @action login() {
    this.inProgress = true
    this.errors = undefined
    this.stores.ui.clearMessages()
    return agent.Auth.login(this.values.email, this.values.password)
      .then(user => {
        if (this.remember) {
          this.setToken(user.token, user.refresh_token)
        } else {
          this.setCoockie(user.token, user.refresh_token)
        }
      })
      .catch(
        action(err => {
          if (err.response && err.response.status === 401) {
            this.stores.ui.addMessage(
              "Erreur d'authentification",
              'danger',
              'login'
            )
          } else {
            this.stores.ui.addMessage(
              'Une erreur est survenue',
              'danger',
              'login'
            )
          }
          this.errors =
            err.response && err.response.body && err.response.body.errors
          throw err
        })
      )
      .finally(
        action(() => {
          this.inProgress = false
        })
      )
  }

  @action testUser(email) {
    this.inProgress = true
    this.errors = undefined
    this.stores.ui.clearMessages()
    axios.get(`${API_HOST}/?X-Switch-User=` + email, { headers: this.stores.agent.headers() })
      .then((response) => {
        if(response && response.status === 200){
          console.log(response.status);
          this.values.impersonatedEmail = email
          console.log(this.values.impersonatedEmail)
        }
      })
      .catch(
        action(err => {
           if (err.response && err.response.status === 404) {
            this.stores.ui.addMessage(
              "Utilisateur Introuvable",
              'danger',
              'all'
            )
          } else {
            this.stores.ui.addMessage(
              'Une erreur est survenue',
              'danger',
              'all'
            )
          }
          return false
        })
      ).finally(
      action(() => {
        this.inProgress = false
      })
    )
    return Promise.resolve()
  }

  @action refresh(refresh_token) {
    this.inProgress = true
    this.errors = undefined
    agent.Auth.refresh(refresh_token)
      .then(user => this.setToken(user.token, user.refresh_token))
      .catch(
        action(err => {
          this.errors =
            err.response && err.response.body && err.response.body.errors
          throw err
        })
      )
      .finally(
        action(() => {
          this.inProgress = false
        })
      )
    return Promise.resolve()
  }

  @action register() {
    this.inProgress = true
    this.errors = undefined
    return agent.Auth.register(
      this.values.username,
      this.values.email,
      this.values.password
    )
      .then(({ user }) => this.setToken(user.token))
      .catch(
        action(err => {
          this.errors =
            err.response && err.response.body && err.response.body.errors
          throw err
        })
      )
      .finally(
        action(() => {
          this.inProgress = false
        })
      )
  }

  @action logout() {
    document.cookie = 'token=; -1; path=/'
    document.cookie = 'refresh_token=; -1; path=/'
    this.setToken(undefined, undefined)
    this.setSwitchUser(undefined)
    history.push("/login")
    if (this.stores.ui) {
      this.stores.ui.addMessage(
        'Vous êtes maintenant déconnecté',
        'warning',
        'login'
      )
    }
    return Promise.resolve()
  }

  @action getProfile() {
    return new Promise(resolve => {
      axios
        .get(`${API_HOST}/profile`, { headers: this.stores.agent.headers() })
        .then(res => {
          this.fname = res.data.firstName ? res.data.firstName + ' ' : ''
          this.lname = res.data.lastName ? res.data.lastName : ''
          this.values.impersonatedUsername = this.fname + this.lname
          this.values.impersonatedEmail = res.data.email
          this.values.impersonatedRole = res.data.role
          this.values.impersonatedId = res.data.id
          if (res.data.role === 'ROLE_ADMINISTRATOR') {
            this.values.impersonatedLevel = 10
            resolve(true)
          } else if (res.data.role === 'ROLE_FRONT_OFFICE') {
            this.values.impersonatedLevel = 8
            resolve(true)
          } else if (res.data.role === 'ROLE_CONTRIBUTOR_L3') {
            this.values.impersonatedLevel = 6
            resolve(true)
          } else if (res.data.role === 'ROLE_CONTRIBUTOR_L2') {
            this.values.impersonatedLevel = 5
            resolve(true)
          } else if (res.data.role === 'ROLE_CONTRIBUTOR_L1') {
            this.values.impersonatedLevel = 4
            resolve(true)
          } else if (res.data.role === 'ROLE_BANK') {
            this.values.impersonatedLevel = 3
            this.getBankId().then(resolve(true))
          } else if (res.data.role === 'ROLE_CLIENT') {
            this.values.impersonatedLevel = 2
            resolve(true)
          } else if (res.data.role === 'ROLE_NO_ACCESS') {
            this.values.impersonatedLevel = 0
            resolve(true)
          }
        })
        .catch(err => {
          throw err
        })
    })
    // .finally(action(() => { this.loading = false }))
  }

  @action checkToken() {
    let date = new Date()
    if (this.decodedToken && this.decodedToken.exp < Date.now() / 1000) {
      //Token expired
      this.logout()
    } else if (
      this.decodedToken &&
      this.decodedToken.exp < date.setDate(date.getDate() + 15) / 1000
    ) {
      //Refresh a moins de 15 jours du delais
      this.refresh(this.refresh_token)
    }
  }

  @action getBankId() {
    return new Promise(resolve => {
      axios
        .get(`${API_HOST}/banks?emailExtranet=${this.email}`, {
          headers: this.stores.agent.headers()
        })
        .then(res => {
          this.values.bankId =
            res.data['hydra:member'][0] && res.data['hydra:member'][0].id
          resolve(true)
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.stores.dossiers.list()
          })
        )
    })
  }
}

export default AuthStore
