import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  lastName: {
    name: "lastName",
    id: "lastName",
    label: "Nom",
    rules: "string",
    type: "text"
  },
  firstName: {
    name: "firstName",
    id: "firstName",
    label: "Prénom",
    rules: "string",
    type: "text"
  },
  percentageCapitalSociety: {
    name: "percentageCapitalSociety",
    id: "percentageCapitalSociety",
    label: "Pourcentage du capital dans la société",
    rules: "string",
    type: "text"
  },
  statusFutureCompany: {
    name: "statusFutureCompany",
    id: "statusFutureCompany",
    label: "Votre statut dans la future entreprise (Salarié(e) ou TNS)",
    rules: "string",
    type: "text"
  },
  birthDate: {
    name: "birthDate",
    id: "birthDate",
    label: "Date de naissance",
    rules: "string",
    type: "text"
  },
  Smoker: {
    name: "Smoker",
    id: "Smoker",
    label: "Fumeur ou non-fumeur",
    rules: "string",
    type: "text"
  },
  yourHeight: {
    name: "yourHeight",
    id: "yourHeight",
    label: "Votre taille en cm",
    rules: "string",
    type: "text"
  },
  yourWeight: {
    name: "yourWeight",
    id: "yourWeight",
    label: "Votre poids en kg",
    rules: "string",
    type: "text"
  },
  manualWork: {
    name: "manualWork",
    id: "manualWork",
    label: "Allez-vous réaliser du travail manuel dans votre future activité ?",
    rules: "string",
    type: "text"
  },
  manualWorkDetail: {
    name: "manualWorkDetail",
    id: "manualWorkDetail",
    label: "Si travail manuel, merci de nous le décrire avec précision",
    rules: "string",
    type: "text"
  },
  numberKilometers: {
    name: "numberKilometers",
    id: "numberKilometers",
    label: "Nombre de kilomètres de déplacement dans l’année dans le cadre de sa profession",
    rules: "string",
    type: "text"
  },
  practiceSport: {
    name: "practiceSport",
    id: "practiceSport",
    label: "Pratiquez-vous un sport ?",
    rules: "string",
    type: "text"
  },
  practiceSportDetail: {
    name: "practiceSportDetail",
    id: "practiceSportDetail",
    label: "Si oui, quel sport pratiquez-vous ?",
    rules: "string",
    type: "text"
  },
  practiceSportLicensee: {
    name: "practiceSportLicensee",
    id: "practiceSportLicensee",
    label: "Etes-vous licencié dans ce sport ?",
    rules: "string",
    type: "text"
  },
  tripAbroad: {
    name: "tripAbroad",
    id: "tripAbroad",
    label: "Envisagez-vous dans les 2 ans, un voyage à l’étranger ?",
    rules: "string",
    type: "text"
  },
  whichCountry: {
    name: "whichCountry",
    id: "whichCountry",
    label: "Si oui, quel(s) pays ?",
    rules: "string",
    type: "text"
  },
  personalAddress: {
    name: "personalAddress",
    id: "personalAddress",
    label: "Votre adresse personnelle, code postal et ville",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class InformationPerson extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default InformationPerson;
