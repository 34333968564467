import React from 'react'
import ReactDom from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'mobx-react'
import registerServiceWorker from './registerServiceWorker'
import history from './history'
import RootStore from './stores/RootStore'

import App from './components/App'

const stores = new RootStore()

ReactDom.render(
  <Provider {...stores}>
    <Router history={history}>
      <App history={history} />
    </Router>
  </Provider>,
  document.getElementById('root')
)

registerServiceWorker()
