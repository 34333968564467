import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";

@inject('dossiers', 'authStore')
@observer
class FilterByPayment extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ showResults: false, target: "" });
  }

  filterPayment =  (e)  => {
    const{dossiers} = this.props
    dossiers.setPaymentType(e.target.value)
    dossiers.list()
  }

  targetActive(target = '') {
    if (target === '') {
      this.setState({ mainType: '' });
    }
    this.props.contacts.setTypeId(target);
  }

  renderClass(contactMainFunctionsId) {
    var className = ''
    if (this.state.showResults && this.state.target === contactMainFunctionsId && this.state.mainType !== '') {
      className += 'show'
    }
    if (contactMainFunctionsId === this.props.contacts.mainType || this.state.mainType === contactMainFunctionsId) {
      className += ' active'
    }
    return className
  }

  render() {
    const { authStore, dossiers } = this.props
    return (
      <React.Fragment>
        <form className="prp-table-filter">
          <label className="prp-table-filter__label">
            <input type="radio" name="filter_unpaid" value="" checked={dossiers.paymentType == ""} onChange={this.filterPayment}/>
            Tous
          </label>
          <label className="prp-table-filter__label">
            <input type="radio" name="filter_unpaid" value="Dossier payé" checked={dossiers.paymentType == "Dossier payé"} onChange={this.filterPayment}/>
            Dossier payé
          </label>
          <label className="prp-table-filter__label">
            <input type="radio" name="filter_unpaid" value="non_paye" checked={dossiers.paymentType == "non_paye"} onChange={this.filterPayment}/>
            Dossier financé non payé
          </label>
        </form>
      </React.Fragment>
    );
  }
}

export default FilterByPayment;
