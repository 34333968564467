import { observable, action, computed } from 'mobx'
import axios from 'axios'
import { API_HOST } from '../config/_entrypoint'
import history from '../history'

export class ContactsStore {
  @observable loading = false
  @observable loadingList = false
  @observable contactsRegistry = new Map()
  @observable contactsSuggestions = new Map()
  @observable contactsID = {}
  @observable sizeListAll = 0
  @observable sizeListByType = 0
  @observable searchResult = new Map()
  @observable filterOwner = 'all'
  @observable typeId = ''
  @observable mainType = ''
  @observable pagination = 1
  para = ''
  page = []
  searchText = ''
  lastParams = ''

  constructor(stores) {
    this.stores = stores
  }

  @computed get getContacts() {
    return Array.from(this.contactsRegistry.values())
  }

  @computed get getSuggestions() {
    return Array.from(this.contactsSuggestions.values())
  }

  @computed get getContactsId() {
    return this.contactsID
  }

  @computed get getSizeListAll() {
    return this.sizeListAll
  }

  @computed get getSizeListByType() {
    return this.sizeListByType
  }

  @computed get getPagination() {
    return this.pagination
  }

  @computed get getSearchResultContact() {
    return Array.from(this.searchResult.values())
  }

  @action clearSearchResult() {
    this.searchResult = new Map()
  }

  @action setContacts = contacts => {
    this.contactsRegistry = new Map()
    contacts &&
      contacts.forEach(data => {
        this.contactsRegistry.set(data['@id'], data)
      })
  }

  @action setSuggestions = contacts => {
    this.contactsSuggestions = new Map()
    contacts &&
      contacts.forEach(data => {
        this.contactsSuggestions.set(data['@id'], data)
      })
  }

  @action setContactsId = contacts => {
    this.contactsID = {}
    this.contactsID = contacts
  }

  @action setSizeListAll = nb => {
    this.sizeListAll = nb
  }

  @action setSizeListByType = nb => {
    this.sizeListByType = nb
  }

  @action setPagination = page => {
    this.pagination = page
    this.list()
  }

  @action resetPagination = () => {
    this.pagination = 1
  }

  @action setTypeId = type => {
    this.mainType = ''
    this.typeId = type
    this.resetPagination()
    this.list()
  }

  @action setMainType = mainType => {
    this.typeId = ''
    this.mainType = mainType
    this.resetPagination()
    this.list()
  }

  @action setSearchResult = result => {
    result &&
      result.forEach(data => {
        this.searchResult.set(data['@id'], data)
      })
  }

  @action resetCache() {
    this.lastParams = ''
  }

  @action list = () => {
    this.loadingList = true
    this.params = ''
    if (this.typeId !== '') {
      this.params = 'function=/v2/contact_functions/' + this.typeId + '&'
    }
    if (this.mainType !== '') {
      this.params = 'function.mainFunction.id=' + this.mainType + '&'
    }
    if (this.stores.picklist.departement !== '') {
      this.params +=
        'department.label=' + this.stores.picklist.departement + '&'
    }
    if (this.searchText !== '') {
      this.params += 'MultiSearch_fullname=' + this.searchText + '&'
    }
    if (this.filterOwner !== '') {
      switch (this.filterOwner) {
        case 'mine':
          this.params += 'contactOwner=' + this.stores.authStore.id + '&'
          break
        case 'empty':
          this.params += 'contactOwner[exists]=false&'
          break
        default:
          break
      }
    }
    this.params += 'page=' + this.pagination
    if (this.params !== this.lastParams) {
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/contacts?${this.params}`, {
          headers: this.stores.agent.headers()
        })
        .then(res => {
          if(this.getSizeListAll == 0) this.setSizeListAll(res.data['hydra:totalItems'])
          this.setSizeListByType(res.data['hydra:totalItems'])
          this.setContacts(res.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.loadingList = false
          })
        )
    } else {
      this.loadingList = false
    }

    this.lastParams = this.params
  }

  @action oneContact = id => {
    this.loading = true

    this.stores.authStore.checkToken()
    axios
      .get(`${API_HOST}/contacts/${id}`, {
        headers: this.stores.agent.headers()
      })
      .then(res => {
        this.setContactsId(res.data)
      })
      .catch(err => {
        throw err
      })
      .finally(
        action(() => {
          this.loading = false
        })
      )
  }

  @action createContact = newContact => {
    this.loading = true

    this.stores.authStore.checkToken()
    axios
      .post(
        `${API_HOST}/contacts`,
        {
          firstName: newContact.$('firstName').value,
          lastName: newContact.$('lastName').value,
          mobilePhone: newContact.$('mobilePhone').value,
          function:
            '/v2/contact_functions/' + newContact.$('functionInteger').value,
          contactTitle:
            '/v2/contact_titles/' + newContact.$('contactTitle').value,
          email: newContact.$('email').value,
          department: '/v2/departments/' + newContact.$('department').value,
          contactOwner: '/v2/contacts/' + this.stores.authStore.id,
          salesforceId: null
        },
        { headers: this.stores.agent.headers() }
      )
      .then(result => {
        if (this.stores.dossiers.newContact) {
          this.stores.dossiers.createLoanFile(
            newContact,
            result,
            result.data.id
          )
        } else {
          this.stores.ui.addMessage('Contact créé', 'succes', 'all')
          history.goBack()
        }
      })
      .catch(error => {
        console.log('error', error)
        this.stores.dossiers.loadingCreateLoanFile = false
        this.stores.ui.clearMessages()
        this.stores.ui.addMessage(
          'Erreur lors de la création',
          'danger',
          'Contacts'
        )
      })
      .finally(
        action(() => {
          this.loading = false
        })
      )
  }

  @action updateContact = updateContact => {
    this.loading = true
    this.stores.authStore.checkToken()
    axios
      .put(
        `${API_HOST}/contacts/${updateContact.$('id').value}`,
        {
          firstName: updateContact.$('firstName').value,
          lastName: updateContact.$('lastName').value,
          phone: updateContact.$('phone').value,
          mobilePhone: updateContact.$('mobilePhone').value,
          function:
            updateContact.$('functionInteger').value === ''
              ? null
              : updateContact.$('functionInteger').value,
          contactTitle:
            updateContact.$('contactTitle').value === ''
              ? null
              : updateContact.$('contactTitle').value,
          email: updateContact.$('email').value
        },
        { headers: this.stores.agent.headers() }
      )
      .then(value => {
        this.stores.ui.addMessage('Contact modifié', 'succes', 'Contacts')
        history.goBack()
      })
      .catch(err => {
        this.stores.ui.clearMessages()
        this.stores.ui.addMessage(
          'Erreur lors de la modification',
          'danger',
          'Contacts'
        )
      })
      .finally(
        action(() => {
          this.loading = false
        })
      )
  }

  @action search = (text = '') => {
    //this.searchText = text
    this.stores.authStore.checkToken()
    axios
      .get(`${API_HOST}/contacts?MultiSearch_fullname=${text}`, {
        headers: this.stores.agent.headers()
      })
      .then(res => {
        this.searchResult.clear()
        this.setSearchResult(res.data['hydra:member'])
      })
      .catch(err => {
        throw err
      })
  }

  @action searchAccess = text => {
    this.stores.authStore.checkToken()
    axios
      .get(
        `${API_HOST}/contacts?role[]=ROLE_ADMINISTRATOR&role[]=ROLE_FRONT_OFFICE&role[]=ROLE_CONTRIBUTOR_L3&role[]=ROLE_CONTRIBUTOR_L2&role[]=ROLE_CONTRIBUTOR_L1&role[]=ROLE_BANK&role[]=ROLE_CLIENT&MultiSearch_fullname=${text}`,
        { headers: this.stores.agent.headers() }
      )
      .then(res => {
        this.searchResult.clear()
        this.setSearchResult(res.data['hydra:member'])
      })
      .catch(err => {
        throw err
      })
  }

  @action listByType = id => {
    this.stores.authStore.checkToken()
    axios
      .get(`${API_HOST}/contacts?function=/v2/contact_functions/${id}`, {
        headers: this.stores.agent.headers()
      })
      .then(res => {
        this.setSizeListByType(res.data['hydra:totalItems'])
        this.setSuggestions(res.data['hydra:member'])
      })
  }
}

export default ContactsStore
