import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  areYouManager: {
    name: "areYouManager",
    id: "areYouManager",
    label: "Avez-vous été déjà gérant d’une société ?",
    rules: "string",
    type: "text"
  },
  financialDifficulty: {
    name: "financialDifficulty",
    id: "financialDifficulty",
    label: "Si oui, merci de nous préciser si vous avez déjà été en difficulté financière.",
    rules: "string",
    type: "text"
  },
  paymentIncidents: {
    name: "paymentIncidents",
    id: "paymentIncidents",
    label: "Avez-vous déjà eu des incidents de paiements sur vos comptes personnels ?",
    rules: "string",
    type: "text"
  },
  toBeStuck: {
    name: "toBeStuck",
    id: "toBeStuck",
    label: "Etes-vous ou avez-vous été fiché par la Banque de France",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class PastFinancialSituation extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default PastFinancialSituation;
