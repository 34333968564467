import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

@inject('authStore')
@observer
export default class AccessRoute extends React.Component {

  render() {
    const { authStore, ...restProps } = this.props;
    let minLevelAccess;
    let maxLevelAccess;

    if (this.props.minLevelAccess === undefined) {
      minLevelAccess = 0
    } else {
      minLevelAccess = this.props.minLevelAccess;
    }
    if (this.props.maxLevelAccess === undefined) {
      maxLevelAccess = 10
    } else {
      maxLevelAccess = this.props.maxLevelAccess;
    }

    if (authStore.getToken()) {
      if (authStore.level >= minLevelAccess && authStore.level <= maxLevelAccess) {
        return <Route {...restProps} />
      } else {
        return <Redirect to="/" />
      }
    } else {
      return <Redirect to="/login" />
    }
  }

}