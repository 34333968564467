import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import _ from 'lodash';

import Status from './Status'
import StatusEvolution from './Detail/StatusEvolution'
import Billings from './Detail/Billings'
import FrontOffice from './Detail/FrontOffice'
import BackOffice from './Detail/BackOffice'
import Client from './Detail/Client'
import Contributor from './Detail/Contributor'
import Links from './Detail/Links'
import DocumentManagers from './Detail/DocumentManagers'
import Specifics from './Detail/Specifics'
import Schedule from './Detail/Schedule'
import CounterGuarantee from './Detail/CounterGuarantee'
import Banks from './Detail/Banks'
import BankSingle from './Detail/BankSingle'
import BankMeeting from './Detail/BankMeeting'
import Notes from './Detail/Notes'
import ContentText from "../commons/ContentText";
import helpers from "../../helpers";

@inject('header', 'dossiers', 'authStore', 'ui')
@observer
class LoanFilesDetailPage extends Component {

  constructor(props) {
    super(props);
    if(this.props.authStore.level === '') {
      this.props.dossiers.setLoaderOnLoanFile(true)
      this.props.authStore.getProfile().then(res => {
        this.props.dossiers.onLoanFile(this.props.match.params.loanFileId);
      })
    } else {
       this.props.dossiers.onLoanFile(this.props.match.params.loanFileId);
    }
  }

  componentDidUpdate (prevProps) {
    const { getLoanFileId } = this.props.dossiers
    if (prevProps.match.params.loanFileId !== this.props.match.params.loanFileId) {
      this.props.dossiers.resetLoanFileId()
      this.props.dossiers.onLoanFile(this.props.match.params.loanFileId)
    }
    if (getLoanFileId.name === undefined) {
      if(document.title !== "Chargement...") {
        document.title = "Chargement..."
        this.props.header.setHeaderPage("Chargement...", 'desktop')
      }
    } else if(document.title !== getLoanFileId.name) {
      document.title = getLoanFileId.name
      this.props.header.setHeaderPage(getLoanFileId.name, 'desktop')
    }
    if (getLoanFileId.loanFileStatus && getLoanFileId.loanFileStatus.label) {
      if(this.props.ui.suggestions === null || this.props.ui.suggestions.label !== helpers.getStatusLabel(getLoanFileId.loanFileStatus.label)) {
        this.props.ui.setSuggestions(getLoanFileId.loanFileStatus)
      }
    } else if(this.props.ui.suggestions !== '') {
      this.props.ui.suggestions = ''
    }
    if (this.props.authStore.level === 3 && this.props.authStore.values.bankId === '') {
      this.props.authStore.getBankId()
    }
  }

  componentWillUnmount() {
    this.props.ui.suggestions = null
    this.props.dossiers.resetLoanFileId()
  }



  render() {
    const { authStore } = this.props
    const { loadingID, getLoanFileId, loadingBillings, loadingFrontOffice, loadingBackOffice, loadingClient, loadingContributors, loadingLinks, loadingDocumentManagers, loadingBanks, loadingNotes, loanFileBillings, loanFileFrontOffice, loanFileBackOffice, loanFileClient, loanFileContributor, loanFileLinks, loanFileDocumentMangers, loanFileBanks, loanFileBankMeeting, loanFileNotes } = this.props.dossiers
    return (
      <React.Fragment>
        <div id="contacct-list-view">
          {/* {(loading || !getLoanFileId.id) && <div className="empty-info">Chargement en cours...</div>} */}
          { getLoanFileId.id ?
            <React.Fragment>
              <div className="status-large">
                <div className="progress-status">
                  <Status step={getLoanFileId.step} labelStatus={helpers.getStatusLabel(_.get(getLoanFileId, 'loanFileStatus.label'))} />
                </div>
                <div className="text-wrapper">
                  <span className="status-text">{helpers.getStatusLabel(_.get(getLoanFileId, 'loanFileStatus.label'))}</span>
                </div>
              </div>

              <div className="accordion">
                {/* admin & FO */}
                {authStore.level >= 8 &&
                  <React.Fragment>
                    {getLoanFileId.step === 1 &&
                      <StatusEvolution
                        loanFileStatusLabel={getLoanFileId.loanFileStatus.label}
                        {...this.props}
                      />
                    }
                    <Billings
                      label="Facturation"
                      loading={loadingBillings}
                      fundingAmount={_.get(getLoanFileId, 'fundingAmount')}
                      mandateAmount={_.get(getLoanFileId, 'mandateAmount')}
                      billings={loanFileBillings[0]}
                    />
                    {this.props.authStore.email !== loanFileFrontOffice.email &&
                      <FrontOffice
                        label="Contact Pr€t Pro Frontoffice"
                        loading={loadingFrontOffice}
                        firstName={_.get(loanFileFrontOffice, 'firstName')}
                        lastName={_.get(loanFileFrontOffice, 'lastName')}
                        email={_.get(loanFileFrontOffice, 'email')}
                        mobilePhone={_.get(loanFileFrontOffice, 'mobilePhone')}
                      />
                    }
                    <BackOffice
                      label="Contact Pr€t Pro Backoffice"
                      loading={loadingBackOffice}
                      firstName={_.get(loanFileBackOffice, 'firstName')}
                      lastName={_.get(loanFileBackOffice, 'lastName')}
                      email={_.get(loanFileBackOffice, 'email')}
                      mobilePhone={_.get(loanFileBackOffice, 'mobilePhone')}
                    />
                    <Client
                      label="Client"
                      loading={loadingClient}
                      client={loanFileClient}
                      companyName={_.get(getLoanFileId, 'companyName')}
                      streetNumber={_.get(getLoanFileId, 'streetNumber')}
                      streetName={_.get(getLoanFileId, 'streetName')}
                      additionalAddress={_.get(getLoanFileId, 'additionalAddress')}
                      postalCode={_.get(getLoanFileId, 'postalCode')}
                      city={_.get(getLoanFileId, 'city')}
                    />

                    <Contributor
                      label="Intervenants"
                      loading={loadingContributors}
                      contributors={loanFileContributor}
                    />
                    <Links
                      label="Liens vers le dossier"
                      loading={loadingLinks}
                      links={loanFileLinks}
                    />
                    <DocumentManagers
                      label="Pièces du dossier"
                      loading={loadingDocumentManagers}
                      documentManagers={loanFileDocumentMangers}
                      getLoanFileIdId={getLoanFileId.id}
                    />
                    {(_.get(getLoanFileId, 'partner') || _.get(getLoanFileId, 'account.parentAccount.name')) &&
                      <Specifics
                        label="Spécificités de l'enseigne"
                        loading={loadingID}
                        brandLink = {_.get(getLoanFileId, 'brandInfosDownloadLink')}
                        parentAccount={getLoanFileId.account.parentAccount}
                      />
                    }
                    <Schedule
                      label="Calendrier"
                      loading={loadingID}
                      loadingBanks={loadingBanks}
                      getLoanFileId={getLoanFileId}
                      loanFileBillings={loanFileBillings}
                      banks={loanFileBanks}
                    />
                    <CounterGuarantee
                      label="Contre-garantie"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                      {...this.props}
                    />

                    <Banks
                      label="Gestion des banques"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                      bankGreetingEmail={getLoanFileId.bankGreetingEmail}
                      getLoanFileIdId={getLoanFileId.id}
                      {...this.props}
                    />

                    <BankMeeting
                      label="Rencontre bancaire"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                      bankMeetings={loanFileBankMeeting}
                    />
                    <Notes
                      label="Notes"
                      loading={loadingNotes}
                      notes={loanFileNotes}
                      {...this.props}
                    />
                  </React.Fragment>
                }

                {/* Apporteurs */}
                {authStore.level >= 4 && authStore.level <= 6 &&
                  <React.Fragment>
                    <Client
                      label="Client"
                      loading={loadingClient}
                      client={loanFileClient}
                      companyName={_.get(getLoanFileId, 'companyName')}
                      streetNumber={_.get(getLoanFileId, 'streetNumber')}
                      streetName={_.get(getLoanFileId, 'streetName')}
                      additionalAddress={_.get(getLoanFileId, 'additionalAddress')}
                      postalCode={_.get(getLoanFileId, 'postalCode')}
                      city={_.get(getLoanFileId, 'city')}
                    />
                    <FrontOffice
                      label="Contact Pr€t Pro Frontoffice"
                      loading={loadingFrontOffice}
                      firstName={_.get(loanFileFrontOffice, 'firstName')}
                      lastName={_.get(loanFileFrontOffice, 'lastName')}
                      email={_.get(loanFileFrontOffice, 'email')}
                      mobilePhone={_.get(loanFileFrontOffice, 'mobilePhone')}
                    />
                    <Schedule
                      label="Calendrier"
                      loading={loadingID}
                      getLoanFileId={getLoanFileId}
                      loanFileBillings={loanFileBillings}
                      banks={loanFileBanks}
                    />
                    <DocumentManagers
                      label="Pièces du dossier"
                      loading={loadingDocumentManagers}
                      documentManagers={loanFileDocumentMangers}
                      getLoanFileIdId={getLoanFileId.id}
                    />
                    <CounterGuarantee
                      label="Contre-garantie"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                    />
                    <Banks
                      label="Gestion des banques"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                    />
                    <BankMeeting
                      label="Rencontre bancaire"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                      bankMeetings={loanFileBankMeeting}
                    />
                  </React.Fragment>
                }

                {/* Banques */}
                {authStore.level === 3 &&
                  <React.Fragment>
                    <Client
                      label="Client"
                      loading={loadingClient}
                      client={loanFileClient}
                      companyName={_.get(getLoanFileId, 'companyName')}
                      streetNumber={_.get(getLoanFileId, 'streetNumber')}
                      streetName={_.get(getLoanFileId, 'streetName')}
                      additionalAddress={_.get(getLoanFileId, 'additionalAddress')}
                      postalCode={_.get(getLoanFileId, 'postalCode')}
                      city={_.get(getLoanFileId, 'city')}
                    />
                    <FrontOffice
                      label="Contact Pr€t Pro Frontoffice"
                      loading={loadingFrontOffice}
                      firstName={_.get(loanFileFrontOffice, 'firstName')}
                      lastName={_.get(loanFileFrontOffice, 'lastName')}
                      email={_.get(loanFileFrontOffice, 'email')}
                      mobilePhone={_.get(loanFileFrontOffice, 'mobilePhone')}
                    />
                    <Links
                      label="Liens vers le dossier"
                      loading={loadingLinks}
                      links={loanFileLinks}
                    />
                    <BankSingle
                      label="Infos bancaires"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                    />
                    <Schedule
                      label="Calendrier"
                      loading={loadingID}
                      getLoanFileId={getLoanFileId}
                      loanFileBillings={loanFileBillings}
                      banks={loanFileBanks}
                    />
                  </React.Fragment>
                }

                {/* Client */}
                {authStore.level === 2 &&
                  <React.Fragment>
                    <FrontOffice
                      label="Contact Pr€t Pro Frontoffice"
                      loading={loadingFrontOffice}
                      firstName={_.get(loanFileFrontOffice, 'firstName')}
                      lastName={_.get(loanFileFrontOffice, 'lastName')}
                      email={_.get(loanFileFrontOffice, 'email')}
                      mobilePhone={_.get(loanFileFrontOffice, 'mobilePhone')}
                    />
                    <Schedule
                      label="Calendrier"
                      loading={loadingID}
                      getLoanFileId={getLoanFileId}
                      loanFileBillings={loanFileBillings}
                      banks={loanFileBanks}
                    />
                    <DocumentManagers
                      label="Pièces du dossier"
                      loading={loadingDocumentManagers}
                      documentManagers={loanFileDocumentMangers}
                    />
                    <CounterGuarantee
                      label="Contre-garantie"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                    />
                    <Banks
                      label="Gestion des banques"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                    />
                    <BankMeeting
                      label="Rencontre bancaire"
                      loading={loadingBanks}
                      banks={loanFileBanks}
                      bankMeetings={loanFileBankMeeting}
                    />
                  </React.Fragment>
                }
              </div>
            </React.Fragment> :
            getLoanFileId.error &&
            <div className="empty-info"> Pas de dossier trouvé. </div>
          }
        </div>
      </React.Fragment>
    );
  }
}
export default LoanFilesDetailPage;
