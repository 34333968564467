import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
  loanFileId: {
    name: "loanFileId",
    id: "loanFileId",
    label: "loanFileId",
    type: "hidden"
  },
  bankManagerId: {
    name: "bankManagerId",
    id: "bankManagerId",
    label: "bankManagerId",
    type: "hidden"
  },
  billingsId: {
    name: "billingsId",
    id: "billingsId",
    label: "billingsId",
    type: "hidden"
  },
  datePresentation: {
    name: "datePresentation",
    id: "datePresentation",
    label: "Date de présentation en banque",
    type: "date"
  },
  dateLimitBankRetro: {
    name: "dateLimitBankRetro",
    id: "dateLimitBankRetro",
    label: "Date limite des offres",
    type: "date"
  },
  dateLimitBank: {
    name: "dateLimitBank",
    id: "dateLimitBank",
    label: "Date limite des offres",
    type: "date"
  },
  dateDeblock: {
    name: "dateDeblock",
    id: "dateDeblock",
    label: "Date de déblocage des fonds",
    type: "date"
  }
};

const hooks = {
  onSuccess(form) {
    this.stores.dossiers.updateLoanFileSchedule(form)
  },
  onError(form) {
    console.log('onError Dossiers', form);
  }
};
validatorjs.useLang('fr');

class LoanFilesUpdateScheduleForm extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default LoanFilesUpdateScheduleForm;
