import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  name: {
    name: "name",
    id: "name",
    label: "Nom du Groupement ou Franchise (si vous êtes en réseau).",
    rules: "string",
    type: "text"
  },
  socialReason: {
    name: "socialReason",
    id: "socialReason",
    label: "Raison sociale (nom) de votre future entreprise",
    rules: "string",
    type: "text"
  },
  taxClosingDate: {
    name: "taxClosingDate",
    id: "taxClosingDate",
    label: "Date de clôture fiscale",
    rules: "string",
    type: "text"
  },
  addressCompany: {
    name: "addressCompany",
    id: "addressCompany",
    label: "Adresse complète de votre entreprise",
    rules: "string",
    type: "text"
  },
  jobsCreate: {
    name: "jobsCreate",
    id: "jobsCreate",
    label: "Nombre d’emplois que vous avez prévu de créer",
    rules: "string",
    type: "text"
  },
  jobsKeep: {
    name: "jobsKeep",
    id: "jobsKeep",
    label: "Nombre d’emplois que vous allez conserver",
    rules: "string",
    type: "text"
  },
  activityCompany: {
    name: "activityCompany",
    id: "activityCompany",
    label: "Activité précise de votre entreprise",
    rules: "string",
    type: "text"
  },
  AmountInvestmentWorks: {
    name: "AmountInvestmentWorks",
    id: "AmountInvestmentWorks",
    label: "Coordonnées complètes de la ou les banques qui suivent l’entreprise (ainsi que les coordonnées du conseiller bancaire)",
    rules: "string",
    type: "text"
  },
  AmountContributed: {
    name: "AmountContributed",
    id: "AmountContributed",
    label: "Montant approximatif de votre apport",
    rules: "string",
    type: "text"
  },
  remarks: {
    name: "remarks",
    id: "remarks",
    label: "Remarques à nous apporter",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class YourBusiness_v5 extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default YourBusiness_v5;
