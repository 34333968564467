import { observable, computed, action } from 'mobx'
import { v4 } from 'uuid'

export class UIStore {
  @observable messagesRegistry = new Map()
  @observable messagesType = ''
  @observable messagesZone = ''
  @observable changePassword = false
  @observable switchUser = false
  @observable seePassword = false
  @observable location = ''

  @observable modifNote = false
  @observable modifBank = false
  @observable modifIdBank = 0
  @observable modifInfoBank1 = false
  @observable modifInfoBank2 = false
  @observable modifRetroScheduleOnOffersDeadline = false
  @observable modifRetroScheduleOnPresentationBank = false
  @observable modifOffersDeadline = false
  @observable modifFundsReleasedDate = false
  @observable suggestions = null
  @observable MainSuggestions = null

  @computed get message() {
    return Array.from(this.messagesRegistry.values())
  }

  @computed get messageType() {
    return this.messagesType
  }

  @computed get messageZone() {
    return this.messagesZone
  }

  @computed get getChangePassword() {
    return this.changePassword
  }

  @computed get getLayoutClass() {
    this.className = 'container'
    if (this.switchUser || this.changePassword) {
      this.className += ' no-scroll'
    }
    if (this.location === '/dossiers' || this.location === '/contacts') {
      this.className += ' filter-first'
    }
    return this.className
  }

  constructor(stores) {
    this.stores = stores
  }

  @action addMessage(text, type = 'info', zone) {
    const id = v4()
    this.messagesRegistry.set(id, { id, text, type, zone })

    setTimeout(() => this.delMessage(id), 6000)
  }

  @action delMessage(id) {
    this.messagesRegistry.delete(id)
  }

  @action clearMessages() {
    this.messagesRegistry.clear()
  }

  @action toggleChangePassword() {
    this.changePassword = !this.changePassword
  }

  @action setChangePassword(val) {
    this.changePassword = val
  }

  @action setSeePassword(val) {
    this.seePassword = val
  }

  @action setSwitchUser(val) {
    this.switchUser = val
  }

  @action toggleSeePassword() {
    this.seePassword = !this.seePassword
  }

  @action toggleModifNote() {
    this.modifNote = !this.modifNote
  }

  @action toggleModifBank(val = null) {
    if (val === null) {
      this.modifBank = !this.modifBank
    } else {
      this.modifBank = val
    }
  }

  @action setModifBank(id) {
    this.modifIdBank = id
  }

  @action resetModifInfoBank() {
    this.modifInfoBank1 = false
    this.modifInfoBank2 = false
  }

  @action setLocation(val) {
    if (this.location !== 'null') {
      this.location = val
    }
  }

  @action setSuggestions(data) {
    if (typeof data.id !== 'undefined') {
      this.suggestions = {
        id: data.id,
        label: data.label
      }
    }
  }
}

export default UIStore
