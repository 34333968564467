import React, { Component } from 'react';
import { observer } from "mobx-react"

@observer
class SurveyBloc extends Component {
    render() {
        const { title, name, defaultChecked } = this.props
        return (
            <div className="accordion survey">
                <section className="section-content">
                    <input type="checkbox" className="accordion__checkbox" id={"accordion-"+name} defaultChecked={defaultChecked} />
                    <label className="accordion__heading" htmlFor={"accordion-"+name}>
                        {/* <i className="prp-ext-invoice"></i> */}
                        {title}
                    </label>
                    <div className="accordion__content">
                        {this.props.children}
                    </div>
                </section>
            </div>
        )
    }
}

export default SurveyBloc;
