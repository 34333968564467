import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'
import { observer } from 'mobx-react'
import dvr from 'mobx-react-form/lib/validators/DVR';

@observer
class ContactsUpdateFormStore extends MobxReactForm {
  constructor(stores, contact) {
    super(contact)
    this.stores = stores
    validatorjs.useLang('fr')
  }

  plugins() {
    return { dvr: dvr(validatorjs) }
  }

  setup() {
    return {
      fields: {
        id: {
          name: 'id',
          id: 'id',
          label: 'id',
          type: 'hidden'
        },
        contactTitle: {
          name: 'contactTitle',
          id: 'contactTitle',
          label: 'Civilité',
          rules: 'string'
        },
        firstName: {
          name: 'firstName',
          id: 'firstName',
          label: 'Prénom',
          rules: 'required|string'
        },
        lastName: {
          name: 'lastName',
          id: 'lastName',
          label: 'Nom',
          rules: 'required|string'
        },
        mobilePhone: {
          name: 'mobilePhone',
          id: 'mobilePhone',
          label: 'Téléphone mobile',
          rules: 'string'
        },
        phone: {
          name: 'phone',
          id: 'phone',
          label: 'Téléphone',
          rules: 'string'
        },
        email: {
          name: 'email',
          id: 'email',
          label: 'Email',
          rules: 'required|email'
        },
        functionInteger: {
          name: 'functionInteger',
          id: 'functionInteger',
          label: 'Fonction du contact',
          rules: 'required|string'
        }
      }
    }
  }

  hooks() {
    return {
      onSuccess(form) {
        this.stores.props.contacts.updateContact(form)
      }
    }
  }

  render() {
    return null
  }
}

export default ContactsUpdateFormStore
