import React, { Component } from 'react';
import { observer } from "mobx-react"

@observer
class SurveyBlocCategory extends Component {
    render() {
        const { title } = this.props
        return (
            <div className="survey title">
                <h2 className="">
                    {title}
                </h2>
                <div>
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default SurveyBlocCategory;
