import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";

@inject('dossiers', 'contacts', 'ui')
@observer
class Suggestions extends Component {

    componentDidUpdate() {
        const { suggestions } = this.props.ui
        if (suggestions !== '' && suggestions !== undefined && suggestions !== null) {
            if (this.props.type === 'dossiers' && suggestions.label && this.props.dossiers.dossiersSuggestions.size === 0 ) {
                this.props.dossiers.listByType(suggestions.id)
            } else if (this.props.type === 'contacts' && suggestions.label && this.props.contacts.contactsSuggestions.size === 0 ) {
                this.props.contacts.listByType(suggestions.id)
            }
        }
    }

    render() {
        const { getLoanFileId } = this.props.dossiers
        const { contactsID } = this.props.contacts
        const { suggestions } = this.props.ui
        return (
            <React.Fragment>
                {suggestions &&
                    <React.Fragment>
                        <div className="table-suggestions">
                            <div className="list-title">{suggestions.label}</div>
                            {this.props.type === 'dossiers' ?
                                <React.Fragment>
                                    {this.props.dossiers.getSuggestions && this.props.dossiers.getSuggestions.map((contact, idx) => (
                                        <React.Fragment key={idx}>
                                            <Link to={'/dossiers/' + contact.id} className={getLoanFileId.referenceNumber === contact.referenceNumber ? "row-suggestions link active" : "row-suggestions link"}>
                                                <span className="title">{contact.name}</span>
                                                <span>{contact.referenceNumber}</span>
                                              {contact.loanFileType && <span>{contact.loanFileType.substring(5, contact.loanFileType.length)}</span>}
                                            </Link>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {this.props.contacts.getSuggestions && this.props.contacts.getSuggestions.map((contact, idx) => (
                                        <React.Fragment key={idx}>
                                            <Link to={'/dossiers/' + contact.id} className={contactsID.email === contact.email ? "row-suggestions link active" : "row-suggestions link"}>
                                                <span className="title">{contact.lastName + ' ' + contact.firstName}</span>
                                                <span>{contact.function && contact.function.label}</span>
                                                <span>{contact.email}</span>
                                                <span>{contact.mobilePhone}</span>
                                            </Link>
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            }
                        </div>
                        {this.props.type === "dossiers" && this.props.dossiers.sizeListAll !== 0 && <div className="total-count">Dossiers trouvés : {this.props.dossiers.sizeListAll}</div>}
                        {this.props.type === "contacts" && this.props.contacts.sizeListAll !== 0 && <div className="total-count">Contacts trouvés : {this.props.contacts.sizeListAll}</div>}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

export default Suggestions;
