import React, { Component } from 'react';

class Status extends Component {

  getClasses(step) {
    let statusClasses = []

    for (let i = 1; i <= 3; i++) {
      if (i <= step) {
        statusClasses.push("step-ok")
      } else {
        statusClasses.push("step-todo")
      }
    }

    // TODO : Checker le step.label plutot que status
    if (step === 4) {
      statusClasses.push("finance")
    } else if (step === 5) {
      statusClasses.push("refuse")
    } else if (step === 6) {
      statusClasses.push("other")
    } else {
      statusClasses.push("step-todo")
    }

    return statusClasses
  }

  render() {
    const { step } = this.props
    let statusClasses = this.getClasses(step)

    return (
      <React.Fragment>
        {statusClasses.map((cls, idx) => <div key={idx} className={"step "+ cls}></div> )}
      </React.Fragment>
    );
  }
}


export default Status;
