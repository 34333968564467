import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  who: {
    name: "who",
    id: "who",
    label: "Qui :\n Expliquez-nous le plus précisément possible qui vous êtes, en mettant en avant vos qualités.",
    rules: "string",
    type: "text"
  },
  what: {
    name: "what",
    id: "what",
    label: "Quoi :\n Expliquez-nous le plus précisément possible votre projet en mettant en avant les qualités du projet.",
    rules: "string",
    type: "text"
  },
  where: {
    name: "where",
    id: "where",
    label: "Où :\n Expliquez-nous l’intérêt de l’emplacement que vous avez retenu pour votre projet. Si vous êtes en Franchise, n’hésitez pas à demander les informations à votre Franchiseur.",
    rules: "string",
    type: "text"
  },
  how: {
    name: "how",
    id: "how",
    label: "Comment :\n Expliquez-nous comment vous avez envisagé, avec votre comptable, le financement de votre projet.",
    rules: "string",
    type: "text"
  },
  why: {
    name: "why",
    id: "why",
    label: "Pourquoi :\n D’une manière générale, expliquez-nous pourquoi vous avez fait le choix de ce projet.",
    rules: "string",
    type: "text"
  },
  strongPoints: {
    name: "strongPoints",
    id: "strongPoints",
    label: "Les points forts de votre projet : \n D’après vous, quels sont les points forts de votre projet ? Merci d’être très objectif dans vos réponses.",
    rules: "string",
    type: "text"
  },
  weakPoints: {
    name: "weakPoints",
    id: "weakPoints",
    label: "Les points faibles de votre projet : D’après vous, quels sont les points faibles de votre projet ? Merci d’être très objectif dans vos réponses.",
    rules: "string",
    type: "text"
  },
  comment: {
    name: "comment",
    id: "comment",
    label: "Commentaires : Merci de nous indiquer vos commentaires éventuels sur votre dossier.",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class LastPartForm extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default LastPartForm;
