import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Message from '../commons/Message'
import helpers from '../../helpers'
import moment from 'moment'

@inject('header', 'authStore', 'homeStore')
@observer
class HomePage extends Component {
  componentDidMount() {
    document.title = 'Bienvenue'
    this.props.header.setHeaderPage(' ')
    this.props.homeStore.news()
    this.props.homeStore.events()
  }

  render() {
    const { getAllPost, getAllEvent, loadingNews, loadingEvents } = this.props.homeStore
    return (
      <React.Fragment>
        <section id="home" className="wrapper-content">
          <Message zone="Contacts" close={true} />
          <div id="home-container">
            <div id="actu" className="block">
              <div className="head-home">
                <i className="title-icon prp-actu-circled"></i>
                <h2>Actualité</h2>
              </div>
              {getAllPost.length !== 0 ? (
                <React.Fragment>
                  <div className="posts-wp">
                    {getAllPost.map((post, idx) => (
                      <div key={idx} className="post">
                        <h3
                          dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                            post.title.rendered
                          )}
                        />
                        <div className="date">
                          {moment(post.date).format('DD/MM/YYYY')}
                        </div>
                        <div
                          className="post-content"
                          dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                            post.content.rendered
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                loadingNews ?
                    <div className="loader visible"></div>
                    :
                    <div className="posts-wp">
                      <h3>Aucun événement</h3>
                    </div>
              )}
            </div>
            <div id="event" className="block">
              <div className="head-home">
                <i className="title-icon prp-events"></i>
                <h2>Événements</h2>
              </div>
              {getAllEvent.length !== 0 ? (
                <React.Fragment>
                  <div className="posts-wp">
                    {getAllEvent.map((event, idx) => (
                      <div key={idx} className="post">
                        <div
                          className="date"
                          dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                            event.date_event
                          )}
                        />
                        <h3
                          dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                            event.title
                          )}
                        />
                        <div
                          className="post-content"
                          dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                            event.content
                          )}
                        />
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                  loadingEvents ?
                    <div className="loader visible"></div>
                    :
                    <div className="posts-wp">
                      <h3>Aucun événement</h3>
                    </div>
                )}
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default HomePage
