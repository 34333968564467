import React, { Component } from 'react';
import { inject, observer } from "mobx-react";

@inject("picklist", "ui")
@observer
class FilterGeographique extends Component {

  componentDidMount() {
    this.setState({
      departement: ''
    })
  }

  onChange(event) {
    this.setState({
      departement: event.target.value
    })
  }

  onClick() {
    this.props.ui.setLocation('null') //Responsive Design
    this.props.picklist.setDepartement(this.state.departement)
    this.props.history.push('/contacts')
  }

  render() {
    return (
      <React.Fragment>
        <div>
          <label id="label-geo">Situation géographique</label>
          <input type="number" id="input-geo" name="search_dossier" autoComplete="off" className="input-search" min="0" placeholder={this.props.picklist.departement !== '' ? this.props.picklist.departement : "Départements (ex: 44, 85, 75)"} onChange={this.onChange.bind(this)} />
          <button type="submit" className="button button-primary geo-button" onClick={this.onClick.bind(this)}>Filtrer</button>
        </div>
      </React.Fragment>
    )
  }
}

export default FilterGeographique;
