import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";
import Message from '../commons/Message'

@inject('authStore')
@observer
class LoginPage extends Component {

  onChangeEmail = e => this.props.authStore.setEmail(e.target.value);

  onChangePassword = e => this.props.authStore.setPassword(e.target.value);

  rememberMe = e => this.props.authStore.setRememberMe(e.target.checked);

  onClick = (e) => {
    e.preventDefault();
    if (this.props.authStore.values.email !== '' && this.props.authStore.values.password !== '') {
      this.props.authStore.login()
        .then(() => window.location.replace('/'));
    }
  };

  render() {
    const { inProgress } = this.props.authStore
    return (
      <React.Fragment>
        <div id="login-background">
          <div id="sidebar">
            <div className="head">
              <img src='./img/logo_unicolor.svg' alt='Logo Pr€t Pro' />
              <p>Vous êtes client, apporteur ou partenaire de PR€T PRO, demandez votre accès sur <a href="mailto:extranet@pretpro.fr">extranet@pretpro.fr</a></p>
              <p>Vous pourrez ainsi suivre en temps réel l'évolution de notre travail, de la constitution du dossier jusqu'au financement, en passant par la liste des documents, des rendez-vous bancaires et des réponses de chaque banque. </p>
              <p>Notre maître mot : Transparence !</p>
            </div>
            <div className="color-bar"></div>
            <form className="login-content">
              {inProgress && <div className="loader visible"></div>}
              <Message zone='login' close={true} />
              <h1>CONNECTEZ-VOUS</h1>
              <label>E-mail</label>
              <input type="email" onChange={this.onChangeEmail} required />

              <label>Mot de passe</label>
              <input type="password" onChange={this.onChangePassword} required />

              <div className="login__lost-password">
                <Link to="password-lost">Mot de passe oublié ?</Link>
              </div>

              <div className="login__rememberme">
                <label htmlFor="rememberme">Se souvenir de moi</label>
                <input type="checkbox" name="rememberme" value="1" onChange={this.rememberMe} />
              </div>

              <button onClick={this.onClick} type="submit" className="button button-primary button-login">Se connecter</button>
            </form>
          </div>
        </div>
        <div className="color-bar"></div>
      </React.Fragment>
    );
  }
}

export default LoginPage;
