import { observable, action, computed } from 'mobx'
import axios from 'axios'
import { API_HOST } from '../config/_entrypoint'

export class PicklistStore {
  @observable loading = false
  @observable FilterTypeContactLoading = false
  @observable FunctionLoading = false
  @observable FrontOfficeLoading = false
  @observable FilterTypeContact = new Map()
  @observable departement = ''
  @observable allDepartement = new Map()
  @observable allFunction = new Map()
  @observable allContactTitle = new Map()
  @observable frontOfficeContact = new Map()
  @observable bankResponse = new Map()
  @observable bankStateResponse = {
    'Accord de Financement': true,
    'Accord pour contre-garantie': true,
    'Pas de réponse après rdv': true,
    'Accord pour rendez-vous': true,
    'Refus pour rendez-vous': true,
    'Refus de Financement après étude': true,
    'Banque non présente sur la ville': true,
    'Banque retenue': true,
    'Banque non sollicitée': true,
    'En attente du contact bancaire local': true,
    "Banque non concernée par ce secteur d'activité": true,
    'Banque non sollicitée (à la demande du client)': true,
    'Refus pour contre-garantie': true,
    "En cours d'étude avant rendez-vous bancaire": true,
    'Refus de Financement après étude (sans courrier bancaire)': true,
    'Siagi non concerné': true,
    "En cours d'étude": true,
    'Aucun retour après envoi du dossier': true
  }

  @observable nameDocument = {
    '01_01': 'Doc 01-04.Plaquette commerciale enseigne',
    '01_02': 'Doc 01-02.DIP',
    '01_03': 'Doc 01-07.Bilans points de vente',
    '01_04': "Doc 01-08.Chiffres d'affaires du réseau",
    '02_01': 'Doc 02-01.Etude de marché',
    '02_02': 'Doc 02-02.Bilan prévisionnel',
    '02_03': 'Doc 02-03.Photos ou docs du local',
    '02_04': 'Doc 02-04.Situation géographique plan',
    '02_05': 'Doc 02-05.Devis des travaux',
    '02_06': 'Doc 02-06.Bail ou projet de Bail',
    '03_01': 'Doc 03-01.Statut ou projet statut',
    '03_02': 'Doc 03-02.Attestation Aides Financières',
    '03_03': 'Doc 03-03.Extrait kbiss',
    '03_04': 'Doc 03-04.Les 3 derniers relevés banque',
    '03_05': 'Doc 03-05.Copie des prêts en cours',
    '03_06': "Doc 03-06.Copie contrats d'assurances",
    '03_07': 'Doc 03-07.TA des prêts en cours',
    '03_08': 'Doc 03-08.Justificatif apport pro.',
    '04_01': "Doc 04-01.Pièce d'identité recto-verso",
    '04_02': 'Doc 04-02.Contrat de Mariage (ou pacs)',
    '04_03': 'Doc 04-03.Livret de Famille',
    '04_04': 'Doc 04-04.Fiche de renseignements',
    '04_05': 'Doc 04-05.TA des prêts en cours',
    '04_06': "Doc 04-06.Ensemble de l'Epargne",
    '04_07': 'Doc 04-07.Bulletins de salaire',
    '04_08': 'Doc 04-08.Les 2 derniers avis imposition',
    '04_09': 'Doc 04-09.Les 3 derniers relevés banque',
    '04_10': 'Doc 04-10 : CV',
    '05_01': 'Doc 05-01.Contrat de construction',
    '05_02': 'Doc 05-02.Copie du dépot du permis',
    '05_03': 'Doc 05-03.Devis liés à la construction',
    '05_04': "Doc 05-04.Bilan de l'entreprise qui cons",
    '05_05': 'Doc 05-05.Compromis achat terrain',
    '06_01': "Doc 06-01.Compromis sur l'achat des murs",
    '06_02': 'Doc 06-02.Devis des travaux',
    '06_03': "Doc 06-03.Bilan de l'entreprise",
    '07_01': 'Doc 07-01.Compromis sur Achat Entreprise',
    '07_02': 'Doc 07-02.Devis travaux Achat Entreprise',
    '07_03': 'Doc 07-03.Copie Ct Assurances Entreprise',
    '07_04': 'Doc 07-04.Les 2 derniers bilans cédant',
    '07_05': 'Doc 07-05.FEC (Fichier Expert Comptable)',
    '08_01': 'Doc 08-01.Organigramme Holding',
    '08_02': 'Doc 08-02.Deux derniers bilans Holding',
    '08_03': 'Doc 08-03.Kbis de la Holding',
    '08_04': 'Doc 08-04.Statut de la Holding',
    '08_05': 'Doc 08-05.Relevés Compte Bancaire',
    '08_06': 'Doc 08-06.CV du des dirigeants Holding',
    '08_07': 'Doc 08-07.CNI du ou des dirigeants Hold',
    '08_08': 'Doc 08-08.CV du directeur de la "Fille"',
    '09_01': 'Doc 09-01.Lettre budget Casino',
    '09_02': 'Doc 09-02.Note de proposition',
    '09_03': 'Doc 09-03.COMAG',
    '09_04': 'Doc 09-04.EDM',
    '09_05': 'Doc 09-05.Les 2 derniers bilans cédant',
    '09_06': 'Doc 09-06.Reprise copie ct assurances',
    '10_01': 'Doc 10.01.Les 3 derniers bilans Commune',
    '11_01': 'Doc 11.01.Compte rendu de M Wilhelm',
    '12_01': 'Doc 12-01.Attestation Comptes Courants',
    '13_01': 'Doc 13-01.Fiche technique du matériel',
    '13_02': 'Doc 13-02.Devis ou facture proforma',
    '13_03': "Doc 13-03.Bilans de l'entreprise"
  }

  @observable Demande_de_l_apporteur_sur_son_dossier__c = {
    edges: [
      {
        id: 1,
        label: 'Etre contacté pour vous présenter les services de PRET PRO'
      },
      {
        id: 2,
        label: 'Etre contacté avant le client pour échanges sur le dossier'
      },
      {
        id: 3,
        label: 'Contacter votre client pour se présenter'
      },
      {
        id: 4,
        label:
          "Client déjà d'accord pour nous faire intervenir, lui envoyer notre lettre de mission"
      },
      {
        id: 5,
        label: 'Autre (merci de préciser votre demande)'
      }
    ]
  }

  @observable Ind_pendant_ou_Commerce_Associ__c = {
    edges: [
      {
        id: 1,
        label: 'Indépendant'
      },
      {
        id: 2,
        label: 'Groupement'
      },
      {
        id: 3,
        label: 'Non connu actuellement'
      }
    ]
  }

  @observable Type_de_projet__c = {
    edges: [
      {
        id: 1,
        label: 'Création'
      },
      {
        id: 2,
        label: 'Reprise'
      },
      {
        id: 3,
        label: 'Transmission'
      },
      {
        id: 4,
        label: 'Murs commerciaux'
      },
      {
        id: 5,
        label: 'Murs industriels'
      },
      {
        id: 6,
        label: 'Autre'
      }
    ]
  }
  @observable Pr_cision_sur_le_domaine_d_activit__c = {
    edges: [
      {
        id: 1,
        label: 'Activité à préciser'
      },
      {
        id: 2,
        label: 'Automobile - Cycles - Moto'
      },
      {
        id: 3,
        label: 'Bâtiment'
      },
      {
        id: 4,
        label: 'Beauté - Santé - Remise en forme'
      },
      {
        id: 5,
        label: 'Commerce alimentaire'
      },
      {
        id: 6,
        label: 'Décoration et équipement de la maison'
      },
      {
        id: 7,
        label: 'Franchiseur ou Groupement'
      },
      {
        id: 8,
        label: 'Hôtellerie'
      },
      {
        id: 9,
        label: 'Immobilier'
      },
      {
        id: 10,
        label: 'Mini, Super et Hypermarché'
      },
      {
        id: 11,
        label: 'Mode et équipement de la personne'
      },
      {
        id: 12,
        label: 'Restauration'
      },
      {
        id: 13,
        label: 'Services particuliers et entreprises'
      },
      {
        id: 14,
        label: 'Services rapides et services de proximité'
      }
    ]
  }

  constructor(stores) {
    this.stores = stores
  }

  @computed get getFilterTypeContact() {
    return Array.from(this.FilterTypeContact.values())
  }

  @computed get getAllDepartement() {
    return Array.from(this.allDepartement.values())
  }

  @computed get getAllFunction() {
    return Array.from(this.allFunction.values())
  }

  @computed get getAllContactTitle() {
    return Array.from(this.allContactTitle.values())
  }

  @computed get getFrontOfficeContact() {
    return Array.from(this.frontOfficeContact.values())
  }
  @computed get valueBankStateResponse() {
    this.result = true
    this.bankResponse &&
      this.bankResponse.forEach(data => {
        if (!this.bankStateResponse[data.label]) {
          this.result = false
        }
      })
    return this.result
  }

  @computed get getFilterBankResponse() {
    return Array.from(this.bankResponse.values())
  }

  @action setDepartement = dep => {
    this.departement = dep
    this.stores.contacts.list()
  }

  @action setAllDepartement = dep => {
    dep &&
      dep.forEach(data => {
        if (typeof data != 'undefined' && typeof data.id != 'undefined') {
          this.allDepartement.set(data.id, data)
        }
      })
  }

  @action setAllFunction = fun => {
    fun &&
      fun.forEach(data => {
        if (typeof data != 'undefined' && typeof data.id != 'undefined') {
          this.allFunction.set(data.id, data)
        }
      })
  }

  @action setAllContactTitle = contactTitles => {
    contactTitles &&
      contactTitles.forEach(data => {
        if (typeof data != 'undefined' && typeof data.id != 'undefined') {
          this.allContactTitle.set(data.id, data)
        }
      })
  }

  @action setFilterTypeContact(data) {
    data &&
      data.forEach(data => {
        this.FilterTypeContact.set(data['@id'], data)
      })
  }

  @action  setFilterBankResponse(data) {

    this.bankResponse.clear()

    data &&
    data.forEach( (item, idx) => {
      this.bankResponse.set(idx, {label: item})
    })
  }

  @action setFrontOfficeContact(data) {
    if (typeof data != 'undefined' && typeof data.id != 'undefined') {
      this.frontOfficeContact.set(data.id, data)
    } else {
      data &&
        data.forEach(data => {
          if (typeof data != 'undefined' && typeof data.id != 'undefined') {
            this.frontOfficeContact.set(data.id, data)
          }
        })
    }
  }

  @action changeBankResponse(name) {
    this.bankStateResponse[name] = !this.bankStateResponse[name]
  }

  @action allChecked(val) {
    this.bankResponse &&
      this.bankResponse.forEach(data => {
        this.bankStateResponse[data.label] = val
      })
  }

  @action resetCache() {
    this.FilterTypeContact = new Map()
    this.allFunction = new Map()
    this.frontOfficeContact = new Map()
  }

  @action listFilterTypeContact = () => {
    if (this.getFilterTypeContact.length === 0) {
      this.FilterTypeContactLoading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/contact_main_functions`, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setFilterTypeContact(result.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.FilterTypeContactLoading = false
          })
        )
    }
  }

  @action listFilterTypeContactDirection = () => {
    if (this.getFilterTypeContact.length === 0) {
      this.FilterTypeContactLoading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/contact_functions?label=Direction de PR€T PRO`, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setFilterTypeContact(result.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.FilterTypeContactLoading = false
          })
        )
    }
  }

  @action picklistDepartement = () => {
    if (this.getAllDepartement.length === 0) {
      this.loading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/departments`, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setAllDepartement(result.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.loading = false
          })
        )
    }
  }

  @action picklistContactTitle = () => {
    if (this.getAllContactTitle.length === 0) {
      this.loading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/contact_titles`, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setAllContactTitle(result.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.loading = false
          })
        )
    }
  }

  @action picklistFunction = () => {
    if (this.getAllFunction.length === 0) {
      this.FunctionLoading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/contact_functions`, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setAllFunction(result.data['hydra:member'])
        })
        .catch(err => {
          throw err
        })
        .finally(
          action(() => {
            this.FunctionLoading = false
          })
        )
    }
  }

  @action picklistFrontOffice = () => {
    if (
      typeof this.stores.authStore !== 'undefined' &&
      typeof this.stores.authStore.id !== 'undefined' &&
      typeof this.stores.authStore.level !== 'undefined'
    ) {
      if (
        this.stores.authStore.level >= 4 &&
        this.stores.authStore.level <= 6
      ) {
        this.param = '/' + this.stores.authStore.id
      } else {
        this.param = '?role=ROLE_FRONT_OFFICE'
      }
      if (this.getFrontOfficeContact.length === 0) {
        this.FrontOfficeLoading = true
        this.stores.authStore.checkToken()
        axios
          .get(`${API_HOST}/contacts${this.param}`, {
            headers: this.stores.agent.headers()
          })
          .then(result => {
            this.setFrontOfficeContact(result.data.contactOwner)
          })
          .catch(err => {
            throw err
          })
          .finally(
            action(() => {
              this.FrontOfficeLoading = false
            })
          )
      }
    }
  }

  @action listFilterBankResponse = () => {
    //let stepOrStatus = this.
    let step = this.stores.dossiers.getStepId
    let status = this.stores.dossiers.getTypeId

    let stepOrStatus = '';

    if (step || status){
      let sep = '?'

      if(step){
        stepOrStatus += sep +'step=' + step
        sep = '&'
      }

      if(status){
        stepOrStatus += sep +'status=' + status
        sep = '&'
      }
    }

    console.log('stepOrStatus', stepOrStatus)

    if (this.getFilterTypeContact.length === 0) {
      //this.FilterTypeContactLoading = true
      this.stores.authStore.checkToken()
      axios
        .get(`${API_HOST}/banks/responses` + stepOrStatus, {
          headers: this.stores.agent.headers()
        })
        .then(result => {
          this.setFilterBankResponse(result.data)
        })
        .catch(err => {
          throw err
        })
       /* .finally(
          action(() => {
            this.FilterTypeContactLoading = false
          })
        )*/
    }
  }
}

export default PicklistStore
