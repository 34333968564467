import { observable, action, computed } from 'mobx'

export class HeaderStore {
  @observable text = ''
  @observable device = ''
  @observable stateMenuMobile = false
  @observable isHidden = false

  constructor(stores) {
    this.stores = stores
  }

  @computed get getHeaderPage() {
    return this.text
  }

  @computed get getDevice() {
    return this.device
  }

  @computed get getStateMenuMobile() {
    return this.stateMenuMobile
  }

  @action setHeaderPage = (text, device = 'mobile') => {
    this.text = text
    this.device = device
  }

  @action toggleStateMenuMobile = () => {
    this.stateMenuMobile = !this.stateMenuMobile
  }

  @action toggleHidden = () => {
    this.isHidden = !this.isHidden
  }

  @action setStateMenuMobile = (state) => {
    this.stateMenuMobile = state
  }

}

export default HeaderStore
