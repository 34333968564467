import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import Message from '../commons/Message'

@inject('user', 'ui')
@observer
class ChangePassword extends Component {
  changePassword(form) {
    form.preventDefault()
    this.props.user.changePassword()
  }
  passwordOnChangeOld(event) {
    this.props.user.setOldPassword(event.target.value)
  }
  passwordOnChange(event) {
    this.props.user.setNewPassword(event.target.value)
  }
  passwordOnChangeConfirm(event) {
    this.props.user.setNewPasswordConfirm(event.target.value)
  }

  render() {
    const { loading } = this.props.user
    return (
      <div className="overlay">
        <div className="modal-container" id="change-password">
          <Message zone="changePassword" close={true} />
          <form
            className="login ajax-form"
            onSubmit={this.changePassword.bind(this)}
            acceptCharset="utf-8"
          >
            {loading && <div className="loader visible"></div>}
            <div className="input">
              <label htmlFor="new_password">Mot de passe actuel :</label>
              <input
                type={this.props.ui.seePassword ? 'text' : 'password'}
                onChange={this.passwordOnChangeOld.bind(this)}
                placeholder="Votre mot de passe actuel"
              />
              <label htmlFor="new_password">Nouveau mot de passe :</label>
              <input
                type={this.props.ui.seePassword ? 'text' : 'password'}
                pattern=".{6,}"
                required
                title="6 characters minimum"
                onChange={this.passwordOnChange.bind(this)}
                placeholder="Votre nouveau mot de passe"
              />
              <label htmlFor="new_password">
                Nouveau mot de passe (confirm):
              </label>
              <input
                type={this.props.ui.seePassword ? 'text' : 'password'}
                pattern=".{6,}"
                required
                title="6 characters minimum"
                onChange={this.passwordOnChangeConfirm.bind(this)}
                placeholder="Confirmer votre nouveau mot de passe"
              />
              <span
                className="see-password"
                onClick={() => {
                  this.props.ui.toggleSeePassword()
                }}
              >
                <span>Voir les mots de passe</span>
                <i className="fa fa-eye-slash"></i>
              </span>
            </div>
            <button className="button button-primary" type="submit">
              Modifier
            </button>
            <span
              className="abort"
              onClick={() => {
                this.props.ui.setChangePassword(false)
              }}
            >
              Annuler
            </span>
          </form>
        </div>
      </div>
    )
  }
}

export default ChangePassword
