import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  currentProfession: {
    name: "currentProfession",
    id: "currentProfession",
    label: "Votre profession actuelle",
    rules: "string",
    type: "text"
  },
  employer: {
    name: "employer",
    id: "employer",
    label: "Votre employeur",
    rules: "string",
    type: "text"
  },
  sinceWhen: {
    name: "sinceWhen",
    id: "sinceWhen",
    label: "Depuis quand ?",
    rules: "string",
    type: "text"
  },
  annualAmountCurrentIncome: {
    name: "annualAmountCurrentIncome",
    id: "annualAmountCurrentIncome",
    label: "Montant annuel de vos revenus actuels",
    rules: "string",
    type: "text"
  },
  annualAmountFutureOneIncome: {
    name: "annualAmountFutureOneIncome",
    id: "annualAmountFutureOneIncome",
    label: "Montant annuel de vos revenus futurs en Année 1 dans votre projet",
    rules: "string",
    type: "text"
  },
  annualAmountFutureTwoIncome: {
    name: "annualAmountFutureTwoIncome",
    id: "annualAmountFutureTwoIncome",
    label: "Montant annuel de vos revenus futurs en Année 2 dans votre projet",
    rules: "string",
    type: "text"
  },
  spouseJob: {
    name: "spouseJob",
    id: "spouseJob",
    label: "Profession actuelle de votre conjoint(e)",
    rules: "string",
    type: "text"
  },
  spouseEmployer: {
    name: "spouseEmployer",
    id: "spouseEmployer",
    label: "Son employeur",
    rules: "string",
    type: "text"
  },
  spouseSinceWhen: {
    name: "spouseSinceWhen",
    id: "spouseSinceWhen",
    label: "Depuis quand ?",
    rules: "string",
    type: "text"
  },
  spouseAmountIncome: {
    name: "spouseAmountIncome",
    id: "spouseAmountIncome",
    label: "Montant annuel de ses revenus",
    rules: "string",
    type: "text"
  },
  annualAmountRentalIncome: {
    name: "annualAmountRentalIncome",
    id: "annualAmountRentalIncome",
    label: "Montant annuel de vos revenus locatifs (si vous en avez)",
    rules: "string",
    type: "text"
  },
  annualAmountHouseholdIncome: {
    name: "annualAmountHouseholdIncome",
    id: "annualAmountHouseholdIncome",
    label: "Montant annuel de vos revenus mobiliers (si vous en avez)",
    rules: "string",
    type: "text"
  },
  otherAnnualIncome: {
    name: "otherAnnualIncome",
    id: "otherAnnualIncome",
    label: "Autres revenus annuels (préciser la nature en plus du montant)",
    rules: "string",
    type: "text"
  },
  comment: {
    name: "comment",
    id: "comment",
    label: "Commentaires divers à nous faire concernant vos revenus",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class YourIncome extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default YourIncome;
