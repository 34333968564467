import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  incomeBeforeInstallation: {
    name: "incomeBeforeInstallation",
    id: "incomeBeforeInstallation",
    label: "Type d'aide 1",
    rules: "string",
    type: "text"
  },
  incomeAfterInstallation: {
    name: "incomeAfterInstallation",
    id: "incomeAfterInstallation",
    label: "Montant 1",
    rules: "string",
    type: "text"
  },
  incomeComment: {
    name: "incomeComment",
    id: "incomeComment",
    label: "Commentaire 1",
    rules: "string",
    type: "text"
  },
  loanExpensesBeforeInstallation: {
    name: "loanExpensesBeforeInstallation",
    id: "loanExpensesBeforeInstallation",
    label: "Type d'aide 2",
    rules: "string",
    type: "text"
  },
  loanExpensesAfterInstallation: {
    name: "loanExpensesAfterInstallation",
    id: "loanExpensesAfterInstallation",
    label: "Montant 2",
    rules: "string",
    type: "text"
  },
  loanExpensesComment: {
    name: "loanExpensesComment",
    id: "loanExpensesComment",
    label: "Commentaire 2",
    rules: "string",
    type: "text"
  },
  spouseJob: {
    name: "spouseJob",
    id: "spouseJob",
    label: "Type d'aide 3",
    rules: "string",
    type: "text"
  },
  spouseEmployer: {
    name: "spouseEmployer",
    id: "spouseEmployer",
    label: "Montant 3",
    rules: "string",
    type: "text"
  },
  spouseSinceWhen: {
    name: "spouseSinceWhen",
    id: "spouseSinceWhen",
    label: "Commentaire 3",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class BeneficiaryRecoveryCreation extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default BeneficiaryRecoveryCreation;
