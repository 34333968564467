import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import _ from 'lodash';
import history from '../../../history';

@inject("contacts", "dossiers", "authStore")
@observer
class SearchContactForm extends Component {

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

        this.state = {
            popupVisible: false
        };
    }

    componentDidMount() {
        if (this.props.state === "admin") {
            this.handleSearchDebounced = _.debounce(function () {
              this.props.contacts.searchAccess(this.state.query);
            }, 500);
        } else {
            this.handleSearchDebounced = _.debounce(function () {
              this.props.contacts.search(this.state.query);
            }, 500);
        }
    }

    handleClick(event) {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            if (this.props.state === "admin") {
                this.props.contacts.searchAccess(event.target.value);
            } else {
                this.props.contacts.search(event.target.value);
            }
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }

        this.setState(prevState => ({
            popupVisible: !prevState.popupVisible,
        }));
    }

    handleOutsideClick(e) {
        this.setState(prevState => ({
            popupVisible: true,
        }));
        this.handleClick();
    }

    onChange(event) {
        this.setState({query: event.target.value});
        this.handleSearchDebounced()
    }

    onClick(contact) {

        if (contact.account !== null) {
            this.setState({ result: contact.firstName + ' ' + contact.lastName + '(' + contact.account.name + ')' });
        } else {
            this.setState({ result: contact.firstName + ' ' + contact.lastName });
        }

        if (this.props.state === "admin") {
            this.props.authStore.setImpersonatedEmail(contact.email)
            window.localStorage.setItem('SwitchUser', contact.email)
            history.push('/')
            window.location.reload()
        }
    }

    componentWillUnmount() {
        this.props.contacts.clearSearchResult()
    }

    render() {
        const { getSearchResultContact } = this.props.contacts

        return (
            <React.Fragment>
                <div id="search" ref={node => { this.node = node; }}>
                    {this.props.state === "admin" && <p>Se connecter en tant que...</p>}
                    <input
                        type="text"
                        name="search_dossier"
                        autoComplete="off"
                        id="searchbox"
                        className="input-search fullsize"
                        placeholder="Rechercher"
                        onChange={this.onChange.bind(this)}
                        onClick={this.handleClick}
                        value={this.state.result}
                    />
                    {this.state.popupVisible && (
                        <ul className="suggestions open">
                            {getSearchResultContact.map((search, idx) =>
                                <li key={idx} onClick={() => this.onClick(search)}>{
                                  (search.account && search.account.name)?
                                    ((search.firstName)?(search.firstName + ' '):'') + ((search.lastName)?search.lastName:'') + ' (' + search.account.name + ')'
                                    : ((search.firstName)?(search.firstName + ' '):'') + ' ' + ((search.lastName)?search.lastName:'')}</li>
                            )}
                        </ul>
                    )}
                </div>
                {this.props.children}
                {this.props.button !== false &&
                    <button type="submit" className="button button-primary search-button">
                        Valider
                    </button>
                }
            </React.Fragment>
        );
    }
}
export default SearchContactForm;
