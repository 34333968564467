import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'
import moment from 'moment'
import { inject, observer } from "mobx-react"
import LoanFilesUpdateScheduleForm from '../../../stores/LoanFileForm/LoanFilesUpdateScheduleForm'
import { FormHelperText } from "@material-ui/core"

@inject('ui', 'dossiers', 'authStore')
@observer
class Schedule extends Component {

    constructor(props) {
        super(props)
        this.updateRetroScheduleOnOffersDeadline = this.updateRetroScheduleOnOffersDeadline.bind(this)
        this.close = this.close.bind(this)
        this.form = new LoanFilesUpdateScheduleForm(this.props)
        this.state = {
            opened: false
        }
    }

    onHeaderClick = () => {
        this.setState({
            opened: !this.state.opened
        })
    }

    onSubHeaderClick = (id) => {
        if (!this.state[id]) {// Si notre element est masqué
            this.setState({
                [id]: true
            })
        } else { //S'il est affiché
            this.setState({
                [id]: false
            })
        }
    }
    subContentClasses = (id) => {
        var result = 'section-content'
        if (this.state[id]) {
            result += ' open'
        }
        return result
    }

    updateRetroScheduleOnOffersDeadline(date, id) {
        this.close()
        this.props.ui.modifRetroScheduleOnOffersDeadline = true
        this.form.$("loanFileId").value = id
        this.form.$("dateLimitBankRetro").value = moment(date).format("YYYY-MM-DD")
    }

    updateRetroScheduleOnPresentationBank(date, id) {
        this.close()
        this.props.ui.modifRetroScheduleOnPresentationBank = true
        this.form.$("loanFileId").value = id
        this.form.$("datePresentation").value = moment(date).format("YYYY-MM-DD")
    }

    updateFundsReleasedDate(date, id) {
        this.close()
        this.props.ui.modifFundsReleasedDate = true
        if (date !== null) { this.form.$("dateDeblock").value = moment(date).format("YYYY-MM-DD") }
        this.form.$("billingsId").value = id
    }

    updateOffersDeadline(date, id) {
      this.close()
      this.props.ui.modifOffersDeadline = true
      this.form.$("bankManagerId").value = id
      this.form.$("dateLimitBank").value = moment(date).format("YYYY-MM-DD")
    }


  close() {
        // this.form.onReset
        this.form.$("datePresentation").value = ''
        this.form.$("dateLimitBankRetro").value = ''
        this.form.$("dateDeblock").value = ''
        this.form.$("dateLimitBank").value = ''
        this.props.ui.modifRetroScheduleOnOffersDeadline = false
        this.props.ui.modifRetroScheduleOnPresentationBank = false
        this.props.ui.modifFundsReleasedDate = false
        this.props.ui.modifOffersDeadline = false
    }

    render() {
        const { label, getLoanFileId, loanFileBillings, loading, banks, loadingBanks } = this.props
        const { loadingUpdate } = this.props.dossiers
        const { form } = this
        const { opened } = this.state
        let contentClasses = 'section-content'
        if (opened) {
            contentClasses = contentClasses + ' open'
        }

        return (
            <React.Fragment>
                {getLoanFileId && <section className={contentClasses}>

                    <label className="accordion__heading" htmlFor="accordion-calendar" onClick={this.onHeaderClick}>
                        <i className="prp-ext-event"></i>
                        {label}
                        {getLoanFileId.id === undefined && loading && <div className="loader visible"></div>}
                    </label>

                    <div className="accordion__content">
                        {this.props.authStore.level !== 3 &&
                        <section
                            className={this.subContentClasses("retro")}
                        >
                            <label className="accordion__heading sub-menu" htmlFor="accordion-calendar-retro" onClick={() => this.onSubHeaderClick("retro")}>
                                Rétro-planning {!getLoanFileId.retroScheduleOnCompleteFile && !getLoanFileId.retroScheduleOnSendBank && !getLoanFileId.retroScheduleOnPresentationBank && !getLoanFileId.retroScheduleOnOffersDeadline && loanFileBillings[0] && !loanFileBillings[0].fundsReleasedDate && '(aucune date prévue)'}
                            </label>

                            <div className="accordion__content">
                                <div className="row-contact">
                                    <div className="contact-element">
                                        <span className="contact-name">Date de dossier complet</span>
                                        <ContentText text={getLoanFileId.retroScheduleOnCompleteFile && moment(getLoanFileId.retroScheduleOnCompleteFile).format("DD/MM/YYYY")} />
                                    </div>
                                </div>
                                <div className="row-contact">
                                    <div className="contact-element">
                                        <span className="contact-name">Date d'envoi en banque</span>
                                        <ContentText text={getLoanFileId.retroScheduleOnSendBank && moment(getLoanFileId.retroScheduleOnSendBank).format("DD/MM/YYYY")} />
                                    </div>
                                </div>
                                {!this.props.ui.modifRetroScheduleOnPresentationBank ?
                                    <div className="row-contact">
                                        <div className="contact-element">
                                            <span className="contact-name">Date de présentation en banque {this.props.authStore.level >= 8 && <i className="fa edit fa-edit" onClick={() => { this.updateRetroScheduleOnPresentationBank(getLoanFileId.retroScheduleOnPresentationBank, getLoanFileId.id) }}></i>}</span>
                                            <ContentText text={getLoanFileId.retroScheduleOnPresentationBank && moment(getLoanFileId.retroScheduleOnPresentationBank).format("DD/MM/YYYY")} />
                                        </div>
                                    </div>
                                    :
                                    <div className="row-contact">
                                        {loadingUpdate && <div className="loader visible"></div>}
                                        <form onSubmit={form.onSubmit} className="column">
                                            <label className="contact-name" htmlFor={form.$("datePresentation").id}>
                                                {form.$("datePresentation").label} <i className="fa edit fa-ban" onClick={() => { this.close() }}></i>
                                            </label>
                                            <input
                                                {...form.$("datePresentation").bind()}
                                                className="input_text" />
                                            {form.$("datePresentation").error !== null && (
                                                <FormHelperText error={true} className="field-error">
                                                    {form.$("datePresentation").error}
                                                </FormHelperText>
                                            )}
                                            <button className="button button-primary" type="submit">Valider</button>
                                        </form>
                                    </div>
                                }
                                {!this.props.ui.modifRetroScheduleOnOffersDeadline ?
                                    <div className="row-contact">
                                        <div className="contact-element">
                                            <span className="contact-name">Date limite des offres {this.props.authStore.level >= 8 && <i className="fa edit fa-edit" onClick={() => { this.updateRetroScheduleOnOffersDeadline(getLoanFileId.retroScheduleOnOffersDeadline, getLoanFileId.id) }}></i>}</span>
                                            <ContentText text={getLoanFileId.retroScheduleOnOffersDeadline && moment(getLoanFileId.retroScheduleOnOffersDeadline).format("DD/MM/YYYY")} />
                                        </div>
                                    </div>
                                    :
                                    <div className="row-contact">
                                        {loadingUpdate && <div className="loader visible"></div>}
                                        <form onSubmit={form.onSubmit} className="column">
                                            <label className="contact-name" htmlFor={form.$("dateLimitBankRetro").id}>
                                                {form.$("dateLimitBankRetro").label} <i className="fa edit fa-ban" onClick={() => { this.close() }}></i>
                                            </label>
                                            <input
                                                {...form.$("dateLimitBankRetro").bind()}
                                                className="input_text" />
                                            {form.$("dateLimitBankRetro").error !== null && (
                                                <FormHelperText error={true} className="field-error">
                                                    {form.$("dateLimitBankRetro").error}
                                                </FormHelperText>
                                            )}
                                            <button className="button button-primary" type="submit">Valider</button>
                                        </form>
                                    </div>
                                }
                            </div>
                        </section>
                        }
                        <section className={this.subContentClasses("planning")}>

                            <label className="accordion__heading sub-menu" htmlFor="accordion-calendar-planning" onClick={() => this.onSubHeaderClick("planning")}>
                                Planning {!getLoanFileId.scheduleOnCompleteFile && !getLoanFileId.scheduleOnSendBank && '(aucune date prévue)'}
                            </label>

                            <div className="accordion__content">
                                {this.props.authStore.level !== 3 &&
                                    <div className="row-contact">
                                        <div className="contact-element">
                                            <span className="contact-name">Date de dossier complet</span>
                                            <ContentText text={getLoanFileId.scheduleOnCompleteFile && moment(getLoanFileId.scheduleOnCompleteFile).format("DD/MM/YYYY")} />
                                        </div>
                                    </div>
                                }
                                <div className="row-contact">
                                    <div className="contact-element">
                                        <span className="contact-name">Date d'envoi en banque</span>
                                        <ContentText text={getLoanFileId.scheduleOnSendBank && moment(getLoanFileId.scheduleOnSendBank).format("DD/MM/YYYY")} />
                                    </div>
                                </div>
                                <div className="row-contact">
                                    <div className="contact-element">
                                        {loadingBanks && <div className="loader visible small"></div>}
                                        <span className="contact-name">Date de présentation en banque</span>
                                        <ContentText text={banks && banks[0] && banks[0].bankPresentationMeetingDate && moment(banks[0].bankPresentationMeetingDate).format("DD/MM/YYYY")} />
                                    </div>
                                </div>
                                {!this.props.ui.modifOffersDeadline ?
                                    <div className="row-contact">
                                        <div className="contact-element">
                                            <span className="contact-name">Date limite des offres {this.props.authStore.level >= 8 && <i className="fa edit fa-edit" onClick={() => { this.updateOffersDeadline(banks[0] && banks[0].bankProposalsDeadlineDate, banks[0] && banks[0].id) }}></i>}</span>
                                            <ContentText
                                              text={banks && banks[0] && banks[0].bankProposalsDeadlineDate && moment(banks[0].bankProposalsDeadlineDate).format("DD/MM/YYYY")}/>
                                        </div>
                                    </div>
                                    :
                                    <div className="row-contact">
                                        {loadingUpdate && <div className="loader visible"></div>}
                                        <form onSubmit={form.onSubmit} className="column">
                                            <label className="contact-name" htmlFor={form.$("dateLimitBank").id}>
                                                {form.$("dateLimitBank").label} <i className="fa edit fa-ban" onClick={() => { this.close() }}></i>
                                            </label>
                                            <input
                                                {...form.$("dateLimitBank").bind()}
                                                className="input_text" />
                                            {form.$("dateLimitBank").error !== null && (
                                                <FormHelperText error={true} className="field-error">
                                                  {form.$("dateLimitBank").error}
                                                </FormHelperText>
                                            )}
                                            <button className="button button-primary" type="submit">Valider</button>
                                        </form>
                                    </div>

                                }

                                {this.props.authStore.level !== 3 &&
                                    <React.Fragment>
                                        {!this.props.ui.modifFundsReleasedDate ?
                                            <div className="row-contact">
                                                <div className="contact-element">
                                                    <span className="contact-name">Date de déblocage des fonds {this.props.authStore.level >= 8 && <i className="fa edit fa-edit" onClick={() => { this.updateFundsReleasedDate(loanFileBillings[0].fundsReleasedDate, loanFileBillings[0].id) }}></i>}</span>
                                                    {loanFileBillings[0] && <ContentText text={loanFileBillings[0].fundsReleasedDate && moment(loanFileBillings[0].fundsReleasedDate).format("DD/MM/YYYY")} />}
                                                </div>
                                            </div>
                                            :
                                            <div className="row-contact">
                                                {loadingUpdate && <div className="loader visible"></div>}
                                                <form onSubmit={form.onSubmit} className="column">
                                                    <label className="contact-name" htmlFor={form.$("dateDeblock").id}>
                                                        {form.$("dateDeblock").label} <i className="fa edit fa-ban" onClick={() => { this.close() }}></i>
                                                    </label>
                                                    <input
                                                        {...form.$("dateDeblock").bind()}
                                                        className="input_text" />
                                                    {form.$("dateDeblock").error !== null && (
                                                        <FormHelperText error={true} className="field-error">
                                                            {form.$("dateDeblock").error}
                                                        </FormHelperText>
                                                    )}
                                                    <button className="button button-primary" type="submit">Valider</button>
                                                </form>
                                            </div>
                                        }
                                    </React.Fragment>
                                }
                                {this.props.authStore.level === 3 && getLoanFileId.step === 4 &&
                                    <div className="row-contact">
                                        <div className="contact-element">
                                            <span className="contact-name">Dossier financé le</span>
                                            <ContentText text={getLoanFileId.fundedDate && moment(getLoanFileId.fundedDate).format("DD/MM/YYYY")} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>
                    </div>
                </section>}
            </React.Fragment>
        );
    }
}


export default Schedule;
