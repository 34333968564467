import React, { Component } from 'react';
import { Switch, Link, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react"

import Filter from '../commons/Filter/Filter';
import FilterByType from '../commons/Filter/FilterByType'
import FilterMandataire from '../commons/Filter/FilterMandataire'
import Search from '../commons/Filter/Search'
import PrivateRoute from '../PrivateRoute';
import FilterGeographique from '../commons/Filter/FilterGeographique'
import Message from '../commons/Message'
import Suggestions from '../commons/Suggestions'

import ContactListView from './ContactListView/ContactListView'
import DetailContact from './DetailContact/DetailContact'
import ContactUpdatePage from './ContactUpdatePage'

@withRouter
@inject('authStore', 'contacts', 'ui')
@observer
class LayoutContact extends Component {

  clickFilter () {
    this.props.ui.setLocation('null') //Responsive design
    this.props.contacts.list()
    this.props.history.push('/contacts')
  }

  render() {
    return (
      <React.Fragment>
        <Filter>
          <div id="filter">
            <Search type="contact" />
            <button type="submit" className="button button-primary search-button" onClick={this.clickFilter.bind(this)}>
              Valider
            </button>
            {this.props.authStore.level >= 8 && <FilterGeographique {...this.props} />}
            <div className="dates-filter">
              {this.props.authStore.level === 8 && <FilterMandataire {...this.props}/>}
              {this.props.ui.suggestions === null ?
                <FilterByType {...this.props}/>
              :
                <Suggestions type="contacts" {...this.props}/>
            }
            </div>
          </div>
        </Filter>
        <section className="wrapper-content for-mobile">
          <Message zone='Contacts' close={true} />
          <Switch>
            <PrivateRoute exact path="/contacts" component={ContactListView} {...this.props} />
            <PrivateRoute exact path="/contacts/type/:typeId" component={ContactListView} {...this.props} />
            <PrivateRoute exact path="/contacts/function/:functionId" component={ContactListView} {...this.props} />
            <PrivateRoute exact path="/contacts/update/:contactId" component={ContactUpdatePage} {...this.props} />
            <PrivateRoute exact path="/contacts/:contactId" component={DetailContact} {...this.props} />
          </Switch>
        </section>
      </React.Fragment>
    )
  }
}

export default LayoutContact;
