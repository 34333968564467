import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'

const plugins = { dvr: validatorjs }

const fields = {
  marketResearch: {
    name: 'marketResearch',
    id: 'marketResearch',
    label: 'Allez-vous réaliser une étude de marché ?',
    rules: 'string',
    type: 'text'
  },
  companyCoordinates: {
    name: 'companyCoordinates',
    id: 'companyCoordinates',
    label:
      'Si oui, coordonnées complètes de la société avec laquelle vous allez travailler : nom, prénom, cabinet, adresse, téléphone, fax, mail',
    rules: 'string',
    type: 'text'
  },
  CreationDate: {
    name: 'CreationDate',
    id: 'CreationDate',
    label:
      'Allez-vous mettre en place un nouveau bail avec le propriétaire de votre emplacement ?',
    rules: 'string',
    type: 'text'
  },
  remainingTime: {
    name: 'remainingTime',
    id: 'remainingTime',
    label:
      'Si non, quelle est la durée restante du bail que vous allez reprendre ?',
    rules: 'string',
    type: 'text'
  },
  rightlease: {
    name: 'rightlease',
    id: 'rightlease',
    label:
      'Devez-vous vous acquitter d’un droit au bail ou droit d’entrée pour le local ?',
    rules: 'string',
    type: 'text'
  },
  amountDepositLocal: {
    name: 'amountDepositLocal',
    id: 'amountDepositLocal',
    label: 'Si oui, quel est son montant ?',
    rules: 'string',
    type: 'text'
  },
  rentDeposit: {
    name: 'rentDeposit',
    id: 'rentDeposit',
    label:
      'Vous est-il demandé une caution de loyer ou une garantie bancaire dans votre bail ?',
    rules: 'string',
    type: 'text'
  },
  amountDepositBail: {
    name: 'amountDepositBail',
    id: 'amountDepositBail',
    label: 'Si oui, quel est son montant ?',
    rules: 'string',
    type: 'text'
  },
  annualRent: {
    name: 'annualRent',
    id: 'annualRent',
    label: 'Quel sera votre loyer annuel ?',
    rules: 'string',
    type: 'text'
  },
  remarks: {
    name: 'remarks',
    id: 'remarks',
    label: 'Remarques diverses à nous faire sur votre emplacement',
    rules: 'string',
    type: 'text'
  }
}

const hooks = {
  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }
}

class YourLocation extends MobxReactForm {
  stores

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks })
    this.stores = stores
    this.formName = formName
  }
}

export default YourLocation
