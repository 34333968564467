import React, { Component } from 'react'
import ContentText from '../../commons/ContentText'
import { inject, observer } from 'mobx-react'

@inject('authStore')
@observer
class Links extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  getLabel(link) {
    switch (link) {
      case 'loan-application':
        return 'Demande de prêt'
      case 'our-documents':
        return 'Nos documents'
      case 'bank-file-1':
        return 'Banque'
      case 'bank-file-2':
        return 'Banque 2'
      case 'bank-proposal':
        return 'Propositions bancaires faites'
      case 'bank-accepted-proposal':
        return 'Proposition bancaire retenue'
      case 'insurance-quote':
        return 'Devis Assurance Emprunteur'
      default:
        break
    }
  }

  render() {
    const { label, links, authStore, loading } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        {links && (
          <section className={contentClasses}>

            <label
              className="accordion__heading"
              htmlFor="accordion-link-dossier"
              onClick={this.onHeaderClick}
            >
              <i className="prp-ext-resume"></i>
              {label}
              {links[0] === undefined && loading && (
                <div className="loader visible"></div>
              )}
            </label>

            <div className="accordion__content">
              {!links[0] ? (
                <div className="empty-info no-border">Aucun lien</div>
              ) : (
                <React.Fragment>
                  {links &&
                    Array.isArray(links) &&
                    links.map((link, idx) => (
                      <React.Fragment key={idx}>
                        {authStore.level !== 3 ? (
                          <div className="row-contact">
                            <div className="contact-element">
                              <span className="contact-name">
                                {this.getLabel(link.linkType)}
                              </span>
                              <a
                                className="link main-color"
                                href={link.uri}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ContentText text={link.uri} />
                              </a>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            {(link.linkType !== 'loan-application' ||
                              link.linkType !== 'bank-proposal' ||
                              link.linkType !== 'bank-accepted-proposal') && (
                              <div className="row-contact">
                                <div className="contact-element">
                                  <span className="contact-name">
                                    {this.getLabel(link.linkType)}
                                  </span>
                                  <a
                                    className="link main-color"
                                    href={link.uri}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <ContentText text={link.uri} />
                                  </a>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              )}
            </div>
          </section>
        )}
      </React.Fragment>
    )
  }
}

export default Links
