import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  legalForm: {
    name: "legalForm",
    id: "legalForm",
    label: "Forme juridique de l’entreprise qui porte le projet",
    rules: "string",
    type: "text"
  },
  socialReason: {
    name: "socialReason",
    id: "socialReason",
    label: "Raison sociale (nom) de votre future entreprise",
    rules: "string",
    type: "text"
  },
  CreationDate: {
    name: "CreationDate",
    id: "CreationDate",
    label: "Date de création",
    rules: "string",
    type: "text"
  },
  taxClosingDate: {
    name: "taxClosingDate",
    id: "taxClosingDate",
    label: "Date de clôture fiscale",
    rules: "string",
    type: "text"
  },
  activityCompany: {
    name: "activityCompany",
    id: "activityCompany",
    label: "Activité précise de votre entreprise",
    rules: "string",
    type: "text"
  },
  AmountInvestmentWorks: {
    name: "AmountInvestmentWorks",
    id: "AmountInvestmentWorks",
    label: "Montant approximatif de l’investissement global de vos travaux",
    rules: "string",
    type: "text"
  },
  AmountContributed: {
    name: "AmountContributed",
    id: "AmountContributed",
    label: "Montant approximatif de votre apport",
    rules: "string",
    type: "text"
  },
  remarks: {
    name: "remarks",
    id: "remarks",
    label: "Remarques à nous faire sur votre projet",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class YourBusiness_v4 extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default YourBusiness_v4;
