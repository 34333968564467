import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'

@inject('ui')
@observer
class Message extends Component {

  onClick(id) {
    this.props.ui.delMessage(id)
  }

  render() {
    const { ui } = this.props

    return (
      <React.Fragment>
        {ui.message.map((message, idx) =>
          message.text !== undefined && message.text !== '' && (message.zone === this.props.zone || message.zone === "all") &&
          <React.Fragment key={idx}>
            <div className={'message message-' + message.type}>
              {message.text}
              {this.props.close &&
                <span onClick={() => { this.onClick(message.id) }} >x</span>}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default Message
