import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";
import Message from '../commons/Message'

@inject('user', 'ui')
@observer
class NewPasswordPage extends Component {

  componentDidMount () {
    this.props.user.resetToken = this.props.match.params.token
  }

  passwordOnChange = e => this.props.user.setNewPassword(e.target.value)
  passwordOnChangeConfirm = e => this.props.user.setNewPasswordConfirm(e.target.value)

  onClick = (e) => {
    e.preventDefault();
    if (this.props.user.newPassword !== '' && this.props.user.newPasswordConfirm !== '') {
      this.props.user.resetPassword()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div id="login-background">
          <div id="sidebar">
            <div className="head">
              <img src='../img/logo_unicolor.svg' alt='Logo Pr€t Pro' />
            </div>
            <div className="color-bar"></div>
            <form className="login-content">
              <Message zone='login' close={true} />
              <h1>Saisissez votre nouveau mot de passe</h1>

              <label htmlFor="new_password">Nouveau mot de passe :</label>
              <input type={this.props.ui.seePassword ? "text" : "password"} pattern=".{6,}" required title="6 characters minimum" onChange={this.passwordOnChange} placeholder="Votre nouveau mot de passe" />
              <label htmlFor="new_password">Confirmation du nouveau mot de passe :</label>
              <input type={this.props.ui.seePassword ? "text" : "password"} pattern=".{6,}" required title="6 characters minimum" onChange={this.passwordOnChangeConfirm} placeholder="Confirmer votre nouveau mot de passe" />
              <span className="see-password" onClick={() => { this.props.ui.toggleSeePassword() }}><span>Voir les mots de passe</span><i className="fa fa-eye-slash"></i></span>

              <button onClick={this.onClick} type="submit" className="button button-primary button-login">Envoyer ma demande</button>
              <Link to="/login" className="link">Annuler</Link>
            </form>
          </div>
        </div>
        <div className="color-bar"></div>
      </React.Fragment>
    );
  }
}

export default NewPasswordPage;
