import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import ContactTable from './ContactTable'

@inject('contacts', 'header')
@observer
class ContactListView extends Component {
  state = {
    page: []
  }

  constructor(props) {
    super(props)
    this.paging = this.paging.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    return {
      page: []
    };
  }

  componentDidMount() {
    document.title = "Vos contacts"
    this.props.header.setHeaderPage('Contacts')
    this.props.contacts.list(this.state)
    this.setState({
      page: []
    })
    if (this.props.match && this.props.match.params && this.props.match.params.typeId !== undefined && this.props.match.params.functionId !== this.props.contacts.typeId) {
      this.props.contacts.setTypeId(this.props.match.params.typeId)
    }
    if (this.props.match && this.props.match.params && this.props.match.params.functionId !== undefined && this.props.match.params.functionId !== this.props.contacts.mainType) {
      this.props.contacts.setTypeId(this.props.match.params.typeId)
    }
  }

  paging() {
    const { getSizeListAll, getPagination } = this.props.contacts;

    if (getSizeListAll !== 0 && this.state && this.state.page) {
      if (Math.ceil(getSizeListAll / 30) === 1 && this.state.page.length !== 0) {
        this.setState({ page: [] })
      } else if (this.state.page[1] === undefined) {
        if (Math.ceil(getSizeListAll / 30) > 5) {
          this.state.page.push(<li key={1} id={'page-1'} className={getPagination === 1 ? 'active' : undefined} onClick={() => { this.setPage(1) }}>{1}</li>)
          if (getPagination > 3) {
            this.state.page.push(<li key={'-1'} onClick={() => { this.setPage(getPagination - 1) }}>{'<<'}</li>)
          }
          for (let j = -2; j < 3; j++) {
            if (getPagination + j !== 1 && getPagination + j > 1 && getPagination + j <= Math.ceil(getSizeListAll / 30)) {
              this.state.page.push(<li key={getPagination + j} className={getPagination === getPagination + j ? 'active' : undefined} onClick={() => { this.setPage(getPagination + j) }}>{getPagination + j}</li>)
            }
          }
          if (getPagination < Math.ceil(getSizeListAll / 30) - 3) {
            this.state.page.push(<li key={'+1'} onClick={() => { this.setPage(getPagination + 1) }}>{'>>'}</li>)
          }

          if (Math.ceil(getSizeListAll / 30) - getPagination >= 3) {
            this.state.page.push(<li key={Math.ceil(getSizeListAll / 30)} onClick={() => { this.setPage(Math.ceil(getSizeListAll / 30)) }}>{Math.ceil(getSizeListAll / 30)}</li>)
          }
        } else {
          for (let j = 0; j < (Math.ceil(getSizeListAll / 30)); j++) {
            if (this.state.page[j] === undefined) {
              if (getPagination === j + 1) {
                this.state.page.push(<li className='active' key={j} onClick={() => { this.setPage(j + 1) }}>{j + 1}</li>)
              } else {
                this.state.page.push(<li key={j} onClick={() => { this.setPage(j + 1) }}>{j + 1}</li>)
              }
            }
          }
        }
      }
      return (this.state.page)
    }
  }

  setPage(nbPage) {
    this.props.contacts.setPagination(nbPage);
    this.setState({ page: [] })
  }

  render() {
    const { getContacts, getSizeListAll, getSizeListByType,loadingList } = this.props.contacts
    return (
      <React.Fragment>
        <div id="contacct-list-view">
          {loadingList && <div className="empty-info">Chargement en cours...</div>}
          {getContacts.length === 0 && !loadingList && <div className="empty-info">Aucun contact</div>}
          {getContacts.length !== 0 && !loadingList &&
            <React.Fragment>
              <ContactTable getContact={getContacts} getSizeListAll={getSizeListAll} {...this.props} />
              <div className="info-footer">
                <div className="clearfix">
                  <ul className="pagination">
                    {this.paging()}
                  </ul>
                </div>
                <div className="total-count">Contacts trouvés :  {getSizeListByType} </div>
              </div>
            </React.Fragment>}
        </div>
      </React.Fragment>
    )
  }
}

export default ContactListView;
