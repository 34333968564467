import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import LoginPage from './Login/LoginPage'
import PasswordLostPage from './Login/PasswordLostPage'
import NewPasswordPage from './Login/NewPasswordPage'
import Layout from './Layout'
import PrivateRoute from './PrivateRoute';

@withRouter
export default class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route exact path="/login" component={LoginPage} {...this.props} />
          <Route exact path="/password-lost" component={PasswordLostPage} {...this.props} />
          <Route exact path="/reset-password/:token" component={NewPasswordPage} {...this.props} />
          <PrivateRoute path="/" component={Layout} {...this.props} />

          <Route render={() => <h1>Not Found</h1>} />
        </Switch>
      </React.Fragment>
    );
  }
}
