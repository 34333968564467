import React, { Component } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";
import _ from 'lodash';
import helpers from "../../../helpers";

@inject("contacts", "dossiers")
@observer
class Search extends Component {

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      popupVisible: false
    };
  }

  componentDidMount() {
    if (this.props.type === 'dossier') {
      this.handleSearchDebounced = _.debounce(function () {
        this.props.dossiers.search(this.state.query);
      }, 500);
    } else {
        this.handleSearchDebounced = _.debounce(function () {
          this.props.contacts.search(this.state.query);
        }, 500);
    }
  }

  handleClick(event) {
    if (!this.state.popupVisible) {
      // attach/remove event handler
      if (this.props.type === 'dossier') {
        this.props.dossiers.search(event.target.value);
      } else {
        this.props.contacts.search(event.target.value);
      }
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      popupVisible: !prevState.popupVisible,
    }));
  }

  handleOutsideClick(e) {
    this.setState(prevState => ({
      popupVisible: true,
    }));
    this.handleClick();
  }

  onChange(event) {
    if (this.props.type === 'dossier') {
      this.props.dossiers.searchText = event.target.value
    } else {
      this.props.contacts.searchText = event.target.value
    }
    this.setState({query: event.target.value});
    this.handleSearchDebounced()
  }

  componentWillUnmount() {
    if (this.props.type === 'dossier') {
      this.props.dossiers.clearSearchResult()
    } else {
      this.props.contacts.clearSearchResult()
    }
  }

  render() {
      const { getSearchResultContact } = this.props.contacts
      const { getSearchResultDossier } = this.props.dossiers

    return (
      <React.Fragment>
        <div id="search" ref={node => { this.node = node; }}>
          <input
            type="text"
            name="search_dossier"
            autoComplete="off"
            id="searchbox"
            className="input-search"
            placeholder="Rechercher"
            onChange={this.onChange.bind(this)}
            onClick={this.handleClick}
          />
          {this.state.popupVisible && this.props.type === "dossier" && (
            <ul className="suggestions open">
              {getSearchResultDossier.map((search, idx) =>
                <li key={idx}><Link to={'/dossiers/' + search.id}><span
                  dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML( search.name )}/></Link></li>
              )}
            </ul>
          )}
          {this.state.popupVisible && this.props.type !== "dossier" && (
            <ul className="suggestions open">
              {getSearchResultContact.map((search, idx) =>
                <li key={idx}><Link to={'/contacts/' + search.id}>{search.account ? search.firstName + ' ' + search.lastName + ' (' + search.account.name + ') ' : search.firstName + ' ' + search.lastName}</Link></li>
              )}
            </ul>
          )}
        </div>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default Search;
