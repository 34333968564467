import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'
import { inject, observer } from "mobx-react"
import helpers from '../../../helpers'
import { FormHelperText } from "@material-ui/core";
import LoanFilesUpdateBankForm from "../../../stores/LoanFileForm/LoanFilesUpdateBankForm"

@inject('ui', 'dossiers', 'authStore')
@observer
class CounterGuarantee extends Component {
    constructor(props) {
      super(props)
      this.state= {
        opened: false
      }
    }

    onHeaderClick = () => {
      this.setState({
        opened: !this.state.opened
      })
    }

    onSubHeaderClick = (id) => {
        if (!this.state[id]) {// Si notre element est masqué
            this.setState({
                [id]: true
            })
        } else { //S'il est affiché
            this.setState({
                [id]: false
            })
        }
    }
    subContentClasses = (id) => {
        var result = 'section-content'
        if (this.state[id]) {
            result += ' open'
        }
        return result
    }

    componentDidMount() {
        this.form = new LoanFilesUpdateBankForm(this.props)
        this.updateForm = this.updateForm.bind(this)
    }

    updateForm(bank) {
        this.props.ui.toggleModifBank()
        this.form.$("id").value = bank.id
        this.form.$("firstName").value = bank.localContactLastName
        this.form.$("lastName").value = bank.localContactFirstName
        this.form.$("email").value = bank.localContactEmail
        this.form.$("phone").value = bank.localContactPhone
        if (bank.internalNotes !== null) { this.form.$("noteIntern").value = bank.internalNotes }
        if (bank.customersNotes !== null) { this.form.$("noteClient").value = bank.customersNotes }
    }

    render() {
        const { label, banks, loading } = this.props
        const { form } = this
        const { opened } = this.state
        let contentClasses = 'section-content'
        if (opened) {
          contentClasses = contentClasses + ' open'
        }

        return (
            <React.Fragment>
                {banks && <section className={contentClasses}>

                    <label className="accordion__heading" htmlFor="accordion-garantie" onClick={this.onHeaderClick}>
                        <i className="prp-ext-bank"></i>
                        {label}
                        {banks[0] === undefined && loading && <div className="loader visible"></div>}
                    </label>

                    <div className="accordion__content">
                        {banks && !banks[0] ?
                            <div className="empty-info no-border">Siagi non interrogée</div>
                            :
                            <React.Fragment>
                                {banks && Array.isArray(banks) && banks.map((banks, idx) =>
                                    <React.Fragment key={idx}>
                                        {banks.banks.map((bank, idx) =>
                                            <React.Fragment key={idx}>
                                                {bank.name === "Siagi" &&
                                                    <section className={this.subContentClasses(bank.id)}>
                                                        {!bank.response &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank no-arrow" : "accordion__heading sub-menu bank"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("refus") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank refus no-arrow" : "accordion__heading sub-menu bank refus"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("banque retenue") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) ? "accordion__heading sub-menu bank banque-retenue no-arrow" : "accordion__heading sub-menu bank banque-retenue"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("accord de") !== -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) ? "accordion__heading sub-menu bank accord no-arrow" : "accordion__heading sub-menu bank accord"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }
                                                        {bank.response && bank.response.toLowerCase().indexOf("accord de") === -1 &&
                                                            bank.response.toLowerCase().indexOf("banque retenue") === -1 &&
                                                            bank.response.toLowerCase().indexOf("refus") === -1 &&
                                                            <label
                                                                className={(this.props.authStore.level >= 4 && this.props.authStore.level <= 6) || this.props.authStore.level === 2 ? "accordion__heading sub-menu bank no-arrow" : "accordion__heading sub-menu bank"}
                                                                htmlFor={'accordion-bank-' + bank.id}
                                                                onClick={() => this.onSubHeaderClick(bank.id)}
                                                            >
                                                                <span className="bank-name">{bank.name}</span>
                                                                <span className="reponse">{bank.response}</span>
                                                            </label>
                                                        }

                                                        {((this.props.authStore.level >= 4 && this.props.authStore.level <= 6)
                                                            ||
                                                            (this.props.authStore.level === 2 && bank.response &&
                                                                (bank.response.toLowerCase().indexOf("refus") === -1 || (bank.response.toLowerCase().indexOf("accord de") !== -1 && bank.response.toLowerCase().indexOf("banque retenue") !== -1 && bank.response.toLowerCase().indexOf("refus") !== -1))
                                                            )
                                                        )
                                                            ?
                                                            null
                                                            :
                                                            <div className="accordion__content">
                                                                {!this.props.ui.modifBank ?
                                                                    <React.Fragment>
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Nom du contact local</span>
                                                                                <ContentText text={bank.localContactLastName} />
                                                                            </div>
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Prénom du contact local</span>
                                                                                <ContentText text={bank.localContactFirstName} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Email du contact local</span>
                                                                                <ContentText text={bank.localContactEmail} />
                                                                            </div>
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Téléphone du contact local</span>
                                                                                <ContentText text={bank.localContactPhone} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Notes internes</span>
                                                                                {bank.internalNotes ?
                                                                                    <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(bank.internalNotes.replace(/\n/g, "<br />"))} />
                                                                                    : '-'}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row-contact">
                                                                            <div className="contact-element">
                                                                                <span className="contact-name">Notes client</span>
                                                                                {bank.customersNotes ?
                                                                                    <span dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(bank.customersNotes.replace(/\n/g, "<br />"))} />
                                                                                    : '-'}
                                                                            </div>
                                                                        </div>
                                                                        {this.props.authStore.level !== 2 && <button className="button button-primary" type="button" onClick={() => { this.updateForm(bank) }}>Modifier</button>}
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <form onSubmit={form.onSubmit}>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("firstName").id}>
                                                                                        {form.$("firstName").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("firstName").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("firstName").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("firstName").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("lastName").id}>
                                                                                        {form.$("lastName").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("lastName").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("lastName").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("lastName").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("email").id}>
                                                                                        {form.$("email").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("email").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("email").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("email").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("phone").id}>
                                                                                        {form.$("phone").label}
                                                                                    </label>
                                                                                    <input
                                                                                        {...form.$("phone").bind()}
                                                                                        type="text"
                                                                                        className="input_text" />
                                                                                    {form.$("phone").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("phone").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("noteIntern").id}>
                                                                                        {form.$("noteIntern").label}
                                                                                    </label>
                                                                                    <textarea
                                                                                        {...form.$("noteIntern").bind()}
                                                                                        className="input_text" />
                                                                                    {form.$("noteIntern").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("noteIntern").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row-contact">
                                                                                <div className="contact-element">
                                                                                    <label className="contact-name" htmlFor={form.$("noteClient").id}>
                                                                                        {form.$("noteClient").label}
                                                                                    </label>
                                                                                    <textarea
                                                                                        {...form.$("noteClient").bind()}
                                                                                        className="input_text" />
                                                                                    {form.$("noteClient").error !== null && (
                                                                                        <FormHelperText error={true} className="field-error">
                                                                                            {form.$("noteClient").error}
                                                                                        </FormHelperText>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                            <button className="button button-primary" type="button" onClick={this.updateForm.bind(this)}>Annuler</button>
                                                                            <button className="button button-primary" type="submit">Valider</button>
                                                                        </form>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                        }
                                                    </section>
                                                }
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        }
                    </div>
                </section>}
            </React.Fragment>
        );
    }
}


export default CounterGuarantee;
