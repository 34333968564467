import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  annualNetIncome: {
    name: "annualNetIncome",
    id: "annualNetIncome",
    label: "Revenus net annuels informations",
    rules: "string",
    type: "text"
  },
  incomeComment: {
    name: "incomeComment",
    id: "incomeComment",
    label: "Revenus commentaire",
    rules: "string",
    type: "text"
  },
  loanExpenses: {
    name: "loanExpenses",
    id: "loanExpenses",
    label: "Charges d’emprunts personnels annuels informations",
    rules: "string",
    type: "text"
  },
  loanExpensesComment: {
    name: "loanExpensesComment",
    id: "loanExpensesComment",
    label: "Charges d’emprunts commenatire",
    rules: "string",
    type: "text"
  },
  percentageIndebtedness: {
    name: "percentageIndebtedness",
    id: "percentageIndebtedness",
    label: "Pourcentage d'endettement informations",
    rules: "string",
    type: "text"
  },
  percentageIndebtednessComment: {
    name: "percentageIndebtednessComment",
    id: "percentageIndebtednessComment",
    label: "Pourcentage d'endettement commentaire",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};

class situationAfterInstallation_v2 extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default situationAfterInstallation_v2;
