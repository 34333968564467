import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import helpers from "../../../helpers";

@inject("dossiers", "authStore", "ui", "picklist")
@observer
class FilterByTypeLoanFile extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.setState({ showResults: false, target: "" });
    this.props.dossiers.listFilterTypeDossier();
  }

  onClick(target) {
    if (!this.props.dossiers.loading) {
      this.props.dossiers.setStepId(target)
      this.props.history.push({ pathname: "/dossiers/step/" + target })
      if (target === this.state.target) {
        this.setState({ showResults: !this.state.showResults, target: target });
      } else {
        this.setState({ showResults: true, target: target });
      }
      this.props.authStore.level === 3 && this.props.ui.suggestions === null && this.props.picklist.listFilterBankResponse()

      this.forceUpdate();
    }
  }

  targetActive(target = '', mainTarget) {
    if (!this.props.dossiers.loading) {
      this.props.dossiers.setTypeId(target)
      this.props.dossiers.mainType = mainTarget
      if (target === '') {
        this.props.history.push({ pathname: "/dossiers" })
      } else {
        this.props.history.push({ pathname: "/dossiers/status/" + target })
      }
      this.props.authStore.level === 3 && this.props.ui.suggestions === null && this.props.picklist.listFilterBankResponse()

    }
  }

  renderClass(contactMainFunctionsId) {
    var className = ''
    if (this.state) {
      if ((this.state.showResults && this.state.target === contactMainFunctionsId) || this.props.dossiers.stepId === contactMainFunctionsId) {
        className += 'show'
      }
    }
    if (contactMainFunctionsId === this.props.dossiers.mainType || this.props.dossiers.stepId === contactMainFunctionsId) {
      className += ' active'
    }
    return className
  }

  render() {
    const { getFilterTypeDossiers, getSizeListTypes } = this.props.dossiers;
    const { authStore } = this.props
    return (
      <React.Fragment>
        <ul className="dossier-type">
          <React.Fragment>
            {getFilterTypeDossiers.map((statusDossiers, idx) => ( //MainFunction
              <React.Fragment key={idx}>
                {((statusDossiers.statuses.map((f) => f.loanFilesCount).reduce((a, b) => a + b, 0) !== 0) || authStore.level >= 10) && //S'il y a des dossiers ou level > 8
                  <li className={this.renderClass(statusDossiers.id)} >
                    <div className="label-container" onClick={() => { this.onClick(statusDossiers.id) }} >
                      <i className="icon prp-ext-empty-file" />
                      <div className="label">
                        <span className="title">
                          {statusDossiers.label}
                        </span>
                        <br />
                        <span className="infos">{statusDossiers.statuses.map((f) => f.loanFilesCount).reduce((a, b) => a + b, 0)} dossiers</span>
                      </div>
                    </div>
                    <ul>
                      {statusDossiers.statuses.map( //Function
                        (statuses, idx) => (
                          (statuses.loanFilesCount !== 0 || authStore.level >= 10) &&
                          <React.Fragment key={idx}>
                            <li onClick={() => { this.targetActive(statuses.id, statusDossiers.id) }} className={statuses.id === this.props.dossiers.typeId ? 'active' : null}>
                              <i className="icon prp-ext-empty-file" />
                              <div>
                                <div className="title">
                                  {helpers.getStatusLabel(statuses.label)}
                                </div>
                                <span className="infos">{statuses.loanFilesCount} dossiers</span>
                              </div>
                            </li>
                          </React.Fragment>
                        )
                      )}
                    </ul>
                  </li>
                }
              </React.Fragment>
            ))}

            <li onClick={() => { this.targetActive('', null) }} className={'' === this.props.dossiers.typeId && '' === this.props.dossiers.stepId ? 'active' : null}>
              <div className="label-container" >
                <i className="icon prp-ext-empty-file" />
                <div>
                  <span className="title">Tous</span>
                  <br />
                  <span className="infos">{getSizeListTypes} dossiers</span>
                </div>
              </div>
            </li>
          </React.Fragment>
        </ul>
      </React.Fragment>
    );
  }
}

export default FilterByTypeLoanFile;
