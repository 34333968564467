import React, { Component } from 'react';
import { inject, observer } from 'mobx-react'

@inject('dossiers')
@observer
class Billings extends Component {

    constructor(props) {
        super(props);
        this.props.dossiers.listAllStatuses();
        this.changeStatus = this.changeStatus.bind(this);
    }

    changeStatus(label) {
        this.props.dossiers.getAllStatuses.map((status) => {
            if (status.label === label) {
                this.props.dossiers.changeStatus(this.props.match.params.loanFileId, status["@id"])
            }
            return ''
        })
    }

    render() {
        const { loanFileStatusLabel } = this.props
        const { loadingStatus } = this.props.dossiers

        return (
            <React.Fragment>
                <div className="message-loanFile">
                    {loadingStatus && <div className="loader visible"></div>}
                    {loanFileStatusLabel !== 'Candidat(e) à contacter' &&
                        <div className="btn btn-simple" onClick={() => this.changeStatus('Prospect à contacter')}>
                            <i className="prp-ext-phone"></i>
                            A contacter
                        </div>
                    }
                    {loanFileStatusLabel !== 'Candidat(e) à contacter - Message laissé' &&
                        <div className="btn btn-simple" onClick={() => this.changeStatus('Prospect à contacter | Message laissé')}>
                            <i className="fa fa-comment"></i>
                            Message laissé
                        </div>
                    }
                    {loanFileStatusLabel !== 'En attente de confirmation client' &&
                        <div className="btn btn-simple" onClick={() => this.changeStatus('En attente de confirmation client')}>
                            <i className="fa fa-pause"></i>
                            En attente de confirmation
                        </div>
                    }
                    {loanFileStatusLabel !== 'Lettre de mission signée' &&
                        <div className="btn btn-simple" onClick={() => this.changeStatus('Lettre de mission signée')}>
                            <i className="fa fa-pencil"></i>
                            Découverte dossier en cours
                        </div>
                    }
                </div>
            </React.Fragment>
        );
    }
}


export default Billings;
