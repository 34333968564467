import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import { Link } from "react-router-dom";

@inject('surveyStore', 'header')
@observer
class StartSurvey extends Component {

    componentDidMount() {
        document.title = "Questionnaire - Fiche de renseignements et état patrimonial"
        this.props.header.setHeaderPage('Fiche de renseignements', 'desktop')
    }

    render() {
        const { id }= this.props.match.params
        return (
            <React.Fragment>
                <div className="survey-start">
                    <img src='../../img/logo.png' alt='Logo Pr€t Pro' />
                    <h1>FICHE DE RENSEIGNEMENTS ET ETAT PATRIMONIAL<br /><u className="red">MERCI DE BIEN LIRE CE QUI SUIT</u></h1>
                    <h2>INTRODUCTION</h2>
                    <p>Madame, Monsieur,</p>
                    <p>Merci de prendre connaissance de ce document dans son intégralité et, surtout, de le remplir avec précision.</p>
                    <p className="no-space-bottom"><strong>Ce document est très important</strong> car il sera le socle pour :</p>
                    <ol className="no-margin">
                        <li>Constituer votre dossier de Financement.</li>
                        <li>Constituer votre dossier d’Assurances</li>
                    </ol>
                    <p className="no-space-top">
                        Il va nous permettre de mieux vous connaitre et de découvrir en profondeur votre dossier et l’ensemble de vos besoins.<br />
                        Les banquiers qui le recevront pourront alors mieux apprécier votre projet et se positionner plus favorablement qu’ils ne le feraient sans ce document.
                    </p>
                    <p><strong className="red no-space-bottom">Nous vous demandons de remplir ce document (les cases bleues) directement dans Word de façon à ce que les informations saisies soient claires et lisibles.</strong></p>
                    <p>Vous nous retournerez ensuite ce document correctement rempli par mail (toujours dans le format word)</p>

                    <h2>A REMPLIR PAR CHACUN DES ASSOCIES</h2>
                    <p>Page 2 et 3 : <span className="red">Etat patrimonial à remplir <strong>(directement dans word)</strong> pour chacun des associés.</span></p>

                    <h2>A NE REMPLIR QU’UNE SEULE FOIS</h2>
                    <p>Pages suivantes : Précisions sur votre projet. Soyez le plus précis dans vos réponses. On ne vous le dira jamais assez, plus vous êtes précis, plus vous donnez de la visibilité à votre dossier, plus les banquiers pourront se positionner favorablement.</p>
                    <p>Si vous avez des questions ou si vous hésitez sur certains points, nous restons bien évidemment à votre disposition pour en parler. Vous disposez de notre outil de transfert de vos documents via notre site internet. En même temps, vous pourrez échanger avec notre service de back office via le module de tchat intégré.</p>

                    <h3>RAPPELS</h3>
                    <ol>
                        <li>Etre le plus précis possible dans vos réponses en prenant le temps de remplir ce document</li>
                        <li><strong className="red">Document à remplir sur word et à nous renvoyer par mail pour nous permettre d’avoir des informations bien écrites et lisibles</strong></li>
                    </ol>
                    <p>La direction de PR€T PRO</p>

                    <p>
                        <strong>PR€T PRO -</strong> Enseigne nationale de courtage en Financements Professionnels<br />
                        60 Avenue Charles de Gaulle<br />
                        92200 Neuilly sur Seine<br />
                        <strong>RCS Nantes : 498 082 395<br />
                            N° Orias : 13003860 (<a href="https://www.orias.fr/">www.orias.fr</a>)<br />
                            Déclaration CNIL : 1988348<br />
                            SAS PRET PRO au capital de 193 330 €</strong>
                    </p>
                    <p className="strong small">
                        Vos données personnelles : <br />
                        Les informations recueillies à partir de nos formulaires font l’objet d’un traitement informatique destiné à PRET PRO pour les finalités suivantes : Montage de votre dossier de financement et/ou d’assurances / Envoi en banques et/ou compagnies d’assurances / Recherche d'un financement professionnel et/ou d’une solution d’assurances.<br />
                        Les destinataires des données sont : Les banques, les compagnies d’assurances et les services de PRET PRO.<br />
                        Conformément à la <a href="https://www.cnil.fr/fr/loi-78-17-du-6-janvier-1978-modifiee">loi « informatique et libertés » du 6 janvier 1978 modifiée</a>, vous disposez d’un <a href="https://www.cnil.fr/fr/le-droit-dacces">droit d’accès</a> et <a href="https://www.cnil.fr/fr/le-droit-de-rectification">de rectification</a> aux informations qui vous concernent. <br />
                        Vous pouvez accéder aux informations vous concernant en vous adressant à :<br />
                        PR€T PRO - Service Gestion des Données / 12 Avenue Carnot - Cité des Congrès / 44000 Nantes<br />
                        Vous pouvez également, pour des motifs légitimes, <a href="https://www.cnil.fr/fr/le-droit-dopposition">vous opposer au traitement des données vous concernant.</a><br />
                        Pour en savoir plus, <a href="https://www.cnil.fr/fr/comprendre-vos-droits">consultez vos droits sur le site de la CNIL</a>.
                    </p>

                    <Link to={`/survey/${id}`} className="button button-primary text-center">J'ai compris</Link>
                </div>
            </React.Fragment>
        );
    }
}

export default StartSurvey;
