import { observable, action, computed } from 'mobx'
import axios from 'axios'
import { API_PRETPRO_WP } from '../config/_entrypoint'

export class HomeStore {
  @observable loadingNews = false
  @observable postWp = new Map()
  @observable loadingEvents = false
  @observable eventWp = new Map()

  constructor(stores) {
    this.stores = stores
  }

  @computed get getAllPost() {
    return Array.from(this.postWp.values())
  }

  @action setNewPost = data => {
    data &&
      data.forEach(data => {
        this.postWp.set(data.id, data)
      })
  }

  @action news() {
    this.loadingNews = true
    this.stores.authStore.checkToken()
    axios
      .get(
        `${API_PRETPRO_WP}/wp-json/wp/v2/posts?order=desc&orderby=date&per_page=5`
      )
      .then(res => {
        this.setNewPost(res.data)
      })
      .catch(err => {
        throw err
      })
      .finally(
        action(() => {
          this.loadingNews = false
        })
      )
  }

  @computed get getAllEvent() {
    return Array.from(this.eventWp.values())
  }

  @action setNewEvent = data => {
    data &&
      data.forEach(data => {
        this.eventWp.set(data.content, data)
      })
  }

  @action events() {
    this.loadingEvents = true
    this.stores.authStore.checkToken()
    axios
      .get(
        `${API_PRETPRO_WP}/wp-json/pretpro/events/?next_events=true&api_call=true`
      )
      .then(res => {
        this.setNewEvent(res.data)
      })
      .catch(err => {
        throw err
      })
      .finally(
        action(() => {
          this.loadingEvents = false
        })
      )
  }
}

export default HomeStore
