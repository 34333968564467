import React, { Component } from 'react';
import { observer } from "mobx-react"

@observer
class SurveyInputTable extends Component {

    renderTable = (currentForm) => {
        const { size } = this.props
        var column = -1
        var row = -1
        return currentForm.map((data, idx) => {
            column++
            if (column % size === 0) {
                row++
            }
            return (
                <React.Fragment key={idx}>
                    {column % size === 0 &&
                        <tr>
                            {this.props.titleLeft && <td>{this.props.titleLeft[row]}</td>}
                            {this.getInput(currentForm, column)}
                        </tr>
                    }
                </React.Fragment>
            )
        })
    }

    getInput = (currentForm, column) => {
        var result = []
        for (let index = 0; index < this.props.size; index++) {
            result.push(
                <td key={index}>
                    <textarea
                        {...this.props.form.$(currentForm[column + index].value.name).bind()}
                        className={currentForm[column + index].value.value ? "input_text" : "input_text empty"}
                    />
                </td>
            )
        }
        return result
    }

    render() {
        const { form, titleTop } = this.props
        if (form && form.fields) {
            this.currentForm = Array.from(form.fields._data.values())
        }
        return (
            <React.Fragment>
                <table>
                    <thead>
                        <tr>
                            {this.props.titleLeft && <th></th>}
                            {titleTop && titleTop.map((data, idx) => {
                                return (
                                    <th key={idx}>{data}</th>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {this.currentForm && this.renderTable(this.currentForm)}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default SurveyInputTable;
