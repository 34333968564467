class helpers {

    static dangerouslySetInnerHTML (dangerousHtml)
    {
        return {__html: dangerousHtml};
    }

    static getStatusLabel (label){
      if(label == 'Lettre de mission signée' ) {
       label = 'Découverte dossier en cours'
      }

      return label
    }
}

export default helpers
