import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  lastName: {
    name: "lastName",
    id: "lastName",
    label: "Nom (et nom de jeune fille si Madame)",
    rules: "string",
    type: "text"
  },
  firstName: {
    name: "firstName",
    id: "firstName",
    label: "Prénom usuel",
    rules: "string",
    type: "text"
  },
  birthDate: {
    name: "birthDate",
    id: "birthDate",
    label: "Date de naissance",
    rules: "string",
    type: "text"
  },
  placeBirth: {
    name: "placeBirth",
    id: "placeBirth",
    label: "Lieu de naissance",
    rules: "string",
    type: "text"
  },
  mobilePhone: {
    name: "mobilePhone",
    id: "mobilePhone",
    label: "Téléphone Portable",
    rules: "string",
    type: "text"
  },
  email: {
    name: "email",
    id: "email",
    label: "Email",
    rules: "string",
    type: "text"
  },
  socialPlan: {
    name: "socialPlan",
    id: "socialPlan",
    label: "Régime social - Sécurité sociale ou RSI (Régime Social des Indépendants)",
    rules: "string",
    type: "text"
  },
  familyStatus: {
    name: "familyStatus",
    id: "familyStatus",
    label: "Situation de Famille : Célibataire / Marié(e) / Pacsé(e) / Veuf(e) / Divorcé(e)",
    rules: "string",
    type: "text"
  },
  spouseName: {
    name: "spouseName",
    id: "spouseName",
    label: "Nom du conjoint avec nom de jeune fille si Madame",
    rules: "string",
    type: "text"
  },
  spouseFirstName: {
    name: "spouseFirstName",
    id: "spouseFirstName",
    label: "Prénom usuel",
    rules: "string",
    type: "text"
  },
  spouseBirthDate: {
    name: "spouseBirthDate",
    id: "spouseBirthDate",
    label: "Date de naissance",
    rules: "string",
    type: "text"
  },
  spousePlaceBirth: {
    name: "spousePlaceBirth",
    id: "spousePlaceBirth",
    label: "Lieu de naissance",
    rules: "string",
    type: "text"
  },
  spouseMobilePhone: {
    name: "spouseMobilePhone",
    id: "spouseMobilePhone",
    label: "Téléphone Portable",
    rules: "string",
    type: "text"
  },
  spouseEmail: {
    name: "spouseEmail",
    id: "spouseEmail",
    label: "Email",
    rules: "string",
    type: "text"
  },
  spouseSocialPlan: {
    name: "spouseSocialPlan",
    id: "spouseSocialPlan",
    label: "Régime social - Sécurité sociale ou RSI (Régime Social des Indépendants)",
    rules: "string",
    type: "text"
  },
  spousefamilyStatus: {
    name: "spousefamilyStatus",
    id: "spousefamilyStatus",
    label: "Situation de Famille : Célibataire / Marié(e) / Pacsé(e) / Veuf(e) / Divorcé(e)",
    rules: "string",
    type: "text"
  },
  weddingDate: {
    name: "weddingDate",
    id: "weddingDate",
    label: "Si vous êtes pacsé ou marié, merci de nous le préciser ainsi que la date du mariage ou du pacs.",
    rules: "string",
    type: "text"
  },
  matrimonialRegime: {
    name: "matrimonialRegime",
    id: "matrimonialRegime",
    label: "Si mariage, votre régime matrimonial : Sans contrat / Avec contrat / Régime Pacs",
    rules: "string",
    type: "text"
  },
  attachedChildren: {
    name: "attachedChildren",
    id: "attachedChildren",
    label: "Si vous avez des enfants, à qui sont-ils rattachés ?",
    rules: "string",
    type: "text"
  },
  dependentChildren: {
    name: "dependentChildren",
    id: "dependentChildren",
    label: "Nombre d’enfants à charge",
    rules: "string",
    type: "text"
  },
  firstChild: {
    name: "firstChild",
    id: "firstChild",
    label: "Nom / Prénom / Sexe / Date de naissance du 1er enfant",
    rules: "string",
    type: "text"
  },
  secondChild: {
    name: "secondChild",
    id: "secondChild",
    label: "Nom / Prénom / Sexe / Date de naissance du 2ème enfant",
    rules: "string",
    type: "text"
  },
  thirdChild: {
    name: "thirdChild",
    id: "thirdChild",
    label: "Nom / Prénom / Sexe / Date de naissance du 3ème enfant",
    rules: "string",
    type: "text"
  },
  fourthChild: {
    name: "fourthChild",
    id: "fourthChild",
    label: "Nom / Prénom / Sexe / Date de naissance du 4ème enfant",
    rules: "string",
    type: "text"
  },
  currentHomeAddress: {
    name: "currentHomeAddress",
    id: "currentHomeAddress",
    label: "Votre adresse personnelle actuelle",
    rules: "string",
    type: "text"
  },
  futurePersonalAddress: {
    name: "futurePersonalAddress",
    id: "futurePersonalAddress",
    label: "Votre adresser personnelle future (en cas de déménagement)",
    rules: "string",
    type: "text"
  },
  currentRent: {
    name: "currentRent",
    id: "currentRent",
    label: "Si locataire, votre loyer actuel",
    rules: "string",
    type: "text"
  },
  futureRent: {
    name: "futureRent",
    id: "futureRent",
    label: "Si locataire, votre loyer futur",
    rules: "string",
    type: "text"
  },
  comments: {
    name: "comments",
    id: "comments",
    label: "Commentaires (le cas échéant)",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    // console.log('SUCCESS', form.stores)
    form.stores.updateBloc(form)
    // this.stores.dossiers.updateLoanFileBank(form, form.$("id").value);
    // if (form.submitCb) {
    //   form.submitCb(form);
    // }
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
// validatorjs.useLang('fr');

class FamilySituation extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default FamilySituation;
