import React, { Component } from 'react';
import { inject, observer } from "mobx-react"
import Message from '../commons/Message'
import Dropzone from 'react-dropzone'

@inject('dossiers', 'header', 'uploadForm')
@observer
class UploadFilePage extends Component {

  componentDidMount() {
    this.props.header.setHeaderPage('TÉLÉCHARGER DES DOCUMENTS', 'desktop')
    this.props.dossiers.list()
  }

  componentDidUpdate() {
    if (this.props.dossiers.getDossiers && this.props.dossiers.getDossiers[0] && this.props.dossiers.getDossiers[0].id !== undefined && this.props.uploadForm.$("LoanFileId").value !== this.props.dossiers.getDossiers[0].id) {
      this.props.uploadForm.$("LoanFileId").value = this.props.dossiers.getDossiers[0].id
    }
  }

  onDrop = (files) => {
    this.props.uploadForm.$("LoanFileId").value = this.props.dossiers.getDossiers[0].id
    this.props.uploadForm.$("Upload").files = files
  }

  render() {
    const { getDossiers, loadingUpload } = this.props.dossiers
    const { uploadForm } = this.props
    return (
      <React.Fragment>
        <div id="upload" className="uploadIntoDetailPage">
          <h1>{getDossiers && getDossiers[0] && getDossiers[0].name ? getDossiers[0].name : 'Chargement...'}</h1>
          <div className="upload-block">
            <Message zone='changePassword' close={true} />
            {loadingUpload && <div className="loader visible"></div>}
            <span className="label">
              <Dropzone onDrop={this.onDrop} {...uploadForm.$("Upload").bind()} uploadMultiple={true}>
                {({ getRootProps, getInputProps, isDragActive }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={'dropzone'}
                    >
                      <span className="button button-primary input-file">Ajouter des fichiers</span>
                      <input {...getInputProps()} {...uploadForm.$("Upload").bind()} />
                      {
                        isDragActive ?
                          <span className="title">Drop files here...</span>
                          :
                            uploadForm.$("Upload").files ?
                            <div className="title">
                              {uploadForm.$("Upload").files.map((file, idx) =>
                                <React.Fragment key={idx}>
                                  <span>{file.name}</span>
                                </React.Fragment>
                              )}
                            </div>
                              :
                              <span className="title">Glisser/déposer des fichiers ici</span>
                      }
                    </div>
                  )
                }}
              </Dropzone>
            </span>
          </div>
          <button className="button button-primary" onClick={uploadForm.onSubmit}>Valider</button>
        </div>
      </React.Fragment>
    );
  }
}

export default UploadFilePage;
