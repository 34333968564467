import MobxReactForm from 'mobx-react-form'
import validatorjs from 'validatorjs'

const plugins = { dvr: validatorjs }

const fields = {
  incomeBeforeInstallation: {
    name: 'incomeBeforeInstallation',
    id: 'incomeBeforeInstallation',
    label: 'Revenus avant travaux',
    rules: 'string',
    type: 'text'
  },
  incomeAfterInstallation: {
    name: 'incomeAfterInstallation',
    id: 'incomeAfterInstallation',
    label: 'Revenus après travaux',
    rules: 'string',
    type: 'text'
  },
  incomeComment: {
    name: 'incomeComment',
    id: 'incomeComment',
    label: 'Revenus commentaire',
    rules: 'string',
    type: 'text'
  },
  loanExpensesBeforeInstallation: {
    name: 'loanExpensesBeforeInstallation',
    id: 'loanExpensesBeforeInstallation',
    label: 'Charges d’emprunts avant travaux',
    rules: 'string',
    type: 'text'
  },
  loanExpensesAfterInstallation: {
    name: 'loanExpensesAfterInstallation',
    id: 'loanExpensesAfterInstallation',
    label: 'Charges d’emprunts après travaux',
    rules: 'string',
    type: 'text'
  },
  loanExpensesComment: {
    name: 'loanExpensesComment',
    id: 'loanExpensesComment',
    label: 'Charges d’emprunts commenatire',
    rules: 'string',
    type: 'text'
  },
  percentageIndebtednessBeforeInstallation: {
    name: 'percentageIndebtednessBeforeInstallation',
    id: 'percentageIndebtednessBeforeInstallation',
    label: "Pourcentage d'endettement avant travaux",
    rules: 'string',
    type: 'text'
  },
  percentageIndebtednessAfterInstallation: {
    name: 'percentageIndebtednessAfterInstallation',
    id: 'percentageIndebtednessAfterInstallation',
    label: "Pourcentage d'endettement après travaux",
    rules: 'string',
    type: 'text'
  },
  percentageIndebtednessComment: {
    name: 'percentageIndebtednessComment',
    id: 'percentageIndebtednessComment',
    label: "Pourcentage d'endettement commentaire",
    rules: 'string',
    type: 'text'
  }
}

const hooks = {
  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }
}

class SituationAfterWorks extends MobxReactForm {
  stores

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks })
    this.stores = stores
    this.formName = formName
  }
}

export default SituationAfterWorks
