import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'

class FrontOffice extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const { firstName, lastName, email, mobilePhone, label, loading } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        <section className={contentClasses}>

          <label className="accordion__heading" htmlFor="accordion-frontoffice" onClick={this.onHeaderClick}>
            <i className="prp-ext-contact-man"></i>
            {label}
            {(firstName === undefined || lastName === undefined || email === undefined) && loading && <div className="loader visible"></div>}
          </label>

          <div className="accordion__content">
            {!firstName && !lastName && !email && !mobilePhone ?
              <div className="empty-info no-border">Aucun frontoffice attaché au dossier</div>
              :
              <React.Fragment>
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Nom</span>
                    {firstName && lastName && <ContentText text={firstName + ' ' + lastName} />}
                    {!firstName && lastName && <ContentText text={lastName} />}
                    {firstName && !lastName && <ContentText text={firstName} />}
                    {!firstName && !lastName && <ContentText text={''} />}
                  </div>
                </div>
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Email</span>
                    <ContentText text={email} />
                  </div>
                </div>
                <div className="row-contact">
                  <div className="contact-element">
                    <span className="contact-name">Téléphone mobile</span>
                    <ContentText text={mobilePhone} />
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </section>
      </React.Fragment>
    );
  }
}


export default FrontOffice;
