import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  LoanFileId: {
    name: "LoanFileId",
    id: "LoanFileId",
    label: "LoanFileId",
    type: "file",
    rules: "required"
  },
  Upload: {
    name: "Upload",
    id: "Upload",
    label: "Upload",
    type: "file",
    rules: "required",
    multiple: true
  }
};

const hooks = {
  onSuccess(form) {
    //console.log('onSuccess Dossiers', form.$("Upload").value, form.$("Upload").files, form.$("Upload"))
    this.stores.dossiers.uploadFile(form)
    form.$("Upload").state.extra = []
  },
  onError(form) {
    if (form.$("Upload").files !== undefined) {
      this.stores.dossiers.uploadFile(form)
    } else {
      //console.log('onError Dossiers', form.$("Upload").files, form.$("LoanFileId").value);
    }
  }
};
validatorjs.useLang('fr');

class Upload extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default Upload;
