import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
  id: {
    name: "id",
    id: "id",
    label: "id",
    type: "hidden",
    rules: "required"
  },
  infoBank1: {
    name: "infoBank1",
    id: "infoBank1",
    label: "Notes client",
    rules: "string"
  },
  infoBank2: {
    name: "infoBank2",
    id: "infoBank2",
    label: "Notes client",
    rules: "string"
  }
};

const hooks = {
  onSuccess(form) {
      this.stores.dossiers.updateLoanFileBankManger(form, form.$("id").value);
  }
};
validatorjs.useLang('fr');

class LoanFilesUpdateBankManagerForm extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default LoanFilesUpdateBankManagerForm;
