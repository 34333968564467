import React, { Component } from 'react'
import { Switch, Link, withRouter } from 'react-router-dom'
import { inject } from 'mobx-react'
import { URL_PRETPRO_MAP } from '../../config/_entrypoint'

import PrivateRoute from '../PrivateRoute'
import Message from '../commons/Message'

import OutilListsPage from './OutilListsPage'
import OutilMapPage from './OutilMapPage'

@withRouter
@inject('authStore')
class LayoutOutils extends Component {
  render() {
    const { authStore } = this.props
    return (
      <React.Fragment>
        <div id="filter">
          <ul className="list">
            {authStore.level !== 2 ? (
              <React.Fragment>
                <li>
                  {authStore.xSwitchUser !== null ? (
                    <a
                      href={
                        URL_PRETPRO_MAP +
                        '/' +
                        authStore.getToken() +
                        '/' +
                        authStore.getRefreshToken() +
                        '/' +
                        authStore.xSwitchUser
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      Carte
                    </a>
                  ) : (
                    <a
                      href={
                        URL_PRETPRO_MAP +
                        '/' +
                        authStore.getToken() +
                        '/' +
                        authStore.getRefreshToken()
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link"
                    >
                      Carte
                    </a>
                  )}
                </li>
                <li>
                  <Link to="/outils/documents" className="link">
                    Documents
                  </Link>
                </li>
              </React.Fragment>
            ) : (
              <li>
                <Link to="/outils/upload" className="link">
                  Upload
                </Link>
              </li>
            )}
          </ul>
        </div>
        <section className="wrapper-content for-mobile">
          <Message zone="Dossiers" close={true} />
          <Switch>
            <PrivateRoute
              exact
              path="/outils"
              component={OutilListsPage}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/outils/carte"
              component={OutilMapPage}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/outils/documents"
              component={OutilListsPage}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/outils/upload"
              component={OutilListsPage}
              {...this.props}
            />
          </Switch>
        </section>
      </React.Fragment>
    )
  }
}

export default LayoutOutils
