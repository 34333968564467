import React, { Component } from 'react'
import ContentText from '../../commons/ContentText'

class Client extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  render() {
    const {
      label,
      client,
      companyName,
      streetNumber,
      streetName,
      additionalAddress,
      postalCode,
      city,
      loading
    } = this.props
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    return (
      <React.Fragment>
        {client && (
          <section className={contentClasses}>

            <label className="accordion__heading" htmlFor="accordion-client" onClick={this.onHeaderClick}>
              <i className="prp-ext-contact"></i>
              {label}
              {(client.firstName === undefined ||
                client.lastName === undefined ||
                client.email === undefined) &&
                loading && <div className="loader visible"></div>}
            </label>

            <div className="accordion__content">
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Nom</span>
                  <ContentText text={client.firstName} />
                </div>
                <div className="contact-element">
                  <span className="contact-name">Prénom</span>
                  <ContentText text={client.lastName} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Email</span>
                  <ContentText text={client.email} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Téléphone</span>
                  <ContentText text={client.phone} />
                </div>
                <div className="contact-element">
                  <span className="contact-name">Mobile</span>
                  <ContentText text={client.mobilePhone} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Fax</span>
                  <ContentText text={'-'} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Raison sociale</span>
                  <ContentText text={companyName} />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Secteur d'activité</span>
                  <ContentText
                    text={client.account && client.account.activitySector}
                  />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Activité précise</span>
                  <ContentText
                    text={client.account && client.account.preciseActivity}
                  />
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Adresse</span>
                  <span className="contact-content">
                    {streetNumber && streetNumber}
                    {streetName && ' ' + streetName}
                    {additionalAddress && ' ' + additionalAddress}
                    {!streetNumber && !streetName && !additionalAddress && '-'}
                  </span>
                </div>
              </div>
              <div className="row-contact">
                <div className="contact-element">
                  <span className="contact-name">Code postal</span>
                  <ContentText text={postalCode} />
                </div>
                <div className="contact-element">
                  <span className="contact-name">Ville</span>
                  <ContentText text={city} />
                </div>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    )
  }
}

export default Client
