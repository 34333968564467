import React, { Component } from 'react';
import PrivateNavItem from '../NavItem/PrivateNavItem';
import { inject, observer } from "mobx-react"

@inject('header', 'authStore')
@observer
class Navbar extends Component {

  render() {
    return (
      <nav id="menu" className={this.props.header.getStateMenuMobile === true ? 'open' : ''}>
        <PrivateNavItem title="Accueil" href="/" position="0 0" {...this.props} />
        <PrivateNavItem title="Dossiers" href="/dossiers" position="0 -3rem" {...this.props} />
        <PrivateNavItem forbiddenLevelAccess="4,5,6" title="Contacts" href="/contacts" position="0 -6rem" {...this.props} />
        <PrivateNavItem minLevelAccess="7" title="Outils" href="/outils" position="0 -9rem" {...this.props} />
        <PrivateNavItem minLevelAccess="2" maxLevelAccess="2" title="Envoi documents" href="/upload" position="0 -15rem" size="1.4rem" {...this.props} />
      </nav>
    );
  }
}

export default Navbar;
