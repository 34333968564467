import React, { Component } from 'react'
import { Switch, withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import Header from './commons/Header/Header'
import Navbar from './commons/Navbar/Navbar'
import ChangePassword from './commons/ChangePassword'
import SwitchUser from './commons/SwitchUser'
import Crisp from './commons/Crisp'
import PrivateRoute from './PrivateRoute'

import LayoutLoanFiles from './LoanFiles/LayoutLoanFiles'
import LayoutContact from './Contacts/LayoutContact'
import LayoutOutils from './Outils/LayoutOutils'
import UploadFilePage from './Upload/UploadFilePage'
import LayoutSurvey from './Survey/LayoutSurvey'
import HomePage from './Home/HomePage'

@withRouter
@inject('authStore', 'ui', 'header')
@observer
class Layout extends Component {
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }
  componentDidUpdate() {
    this.props.ui.setLocation(this.props.location.pathname)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = event => {
    if (
      (!event.target.className ||
        (event.target.className !== 'target-dp' && event.target.className !== 'dropdown-toggle')
      ) &&
      this.props.header.isHidden
    ) {
      this.props.header.toggleHidden()
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header fullname={this.props.authStore.username} {...this.props} />
        <div className={this.props.ui.getLayoutClass}>
          {this.props.ui.changePassword && <ChangePassword />}
          {this.props.ui.switchUser && <SwitchUser {...this.props} />}
          <Navbar {...this.props} />
          <Switch>
            <PrivateRoute
              path="/dossiers"
              component={LayoutLoanFiles}
              {...this.props}
            />
            <PrivateRoute
              path="/contacts"
              component={LayoutContact}
              {...this.props}
            />
            <PrivateRoute
              path="/outils"
              component={LayoutOutils}
              {...this.props}
            />
            <PrivateRoute
              exact
              path="/upload"
              component={UploadFilePage}
              {...this.props}
            />
            <PrivateRoute
              path="/survey"
              component={LayoutSurvey}
              {...this.props}
            />
            <PrivateRoute exact path="/" component={HomePage} {...this.props} />
          </Switch>
          {this.props.authStore.values.level !== 10 &&
            this.props.authStore.values.level !== '' && <Crisp />}
          {this.props.authStore.values.impersonatedLevel !== 10 &&
          this.props.authStore.values.impersonatedLevel !== '' && <Crisp />}
        </div>
      </React.Fragment>
    )
  }
}

export default Layout
