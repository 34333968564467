import React, { Component } from 'react';
import { observer } from "mobx-react"

@observer
class SurveyMessage extends Component {
    render() {
        const { title, msg, type, multiligne } = this.props
        return (
            < div className={"message " + type} >
                {title && <h3>{title}</h3>}
                {
                    multiligne ?
                        <React.Fragment>
                            {msg.split("/n").map((line, idx) => (
                                <p key={idx}>{line}</p>
                            ))}
                        </React.Fragment>
                        :
                        <p>{msg}</p>
                }

            </div >
        )
    }
}

export default SurveyMessage;
