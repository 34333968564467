import { observable, action, computed } from 'mobx'

export class SurveyStore {

    @observable loading = false
    @observable userInputs = {}

    constructor(stores) {
        this.stores = stores
    }

    @computed get getUserInputs() {
        return this.userInputs
    }

    @action updateBloc = async (form) => {
        const values = Array.from(form.fields._data.values())
        await values.forEach(data => {
            if (data.value.value) {
                this.userInputs[`${form.formName}_${data.value.name}`] = data.value.value
            }
        })
    }
}

export default SurveyStore;
