import React, { Component } from 'react';
import ContentText from '../../commons/ContentText'
import {Link} from 'react-router-dom'
import {URL_PRETPRO_MAP} from "../../../config/_entrypoint";

class Specifics extends Component {
    constructor(props) {
      super(props)
      this.state= {
        opened: false
      }
    }

    onHeaderClick = () => {
      this.setState({
        opened: !this.state.opened
      })
    }

    render() {
        const { label, parentAccount, brandLink } = this.props
        const { opened } = this.state
        let contentClasses = 'section-content'
        if (opened) {
          contentClasses = contentClasses + ' open'
        }

        return (

            <React.Fragment>
                <section className={contentClasses}>

                    <label className="accordion__heading" htmlFor="accordion-specifics" onClick={this.onHeaderClick}>
                        <i className="prp-franchise-open"></i>
                        {label}
                    </label>

                    <div className="accordion__content">
                        <div className="row-contact">
                            <div className="contact-element">
                                <span className="contact-name">Nom du franchiseur</span>
                                <ContentText text={parentAccount && parentAccount.name} />
                            </div>
                        </div>
                        <div className="row-contact">
                            <div className="contact-element">
                                <span className="contact-name">Nom du partenariat</span>
                                <ContentText text={parentAccount && parentAccount.partners && parentAccount.partners[0] && parentAccount.partners[0].name} />
                            </div>
                        </div>
                        <div className="row-contact">
                            <div className="contact-element">
                             <span className="contact-name">Lien vers le dossier Franchiseur</span>
                               <a href={brandLink} target="_blank"><ContentText text={brandLink} /></a>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>

        );
    }
}


export default Specifics;
