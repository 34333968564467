import { Component } from 'react';
import { inject, observer } from "mobx-react"

@inject('user', 'ui', 'authStore')
@observer
class Crisp extends Component {

  componentDidMount() {
    const scriptClipchamp = document.createElement("script")
    scriptClipchamp.src = "/js/crisp.js"
    scriptClipchamp.async = true
    document.body.appendChild(scriptClipchamp)
  }

  render() {
    return (
      null
    );
  }
}


export default Crisp;
