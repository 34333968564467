import React, { Component } from 'react';
import { Switch, Link, withRouter } from 'react-router-dom';
import { inject, observer } from "mobx-react"

import Filter from '../commons/Filter/Filter';
import Search from '../commons/Filter/Search'
import FilterByTypeLoanFile from '../commons/Filter/FilterByTypeLoanFile'
import FilterOrder from '../commons/Filter/FilterOrder'
import FilterBankResponse from '../commons/Filter/FilterBankResponse'
import FilterDate from '../commons/Filter/FilterDate'
import PrivateRoute from '../PrivateRoute';
import Message from '../commons/Message'
import Suggestions from '../commons/Suggestions'

import LoanFilesListPage from './LoanFilesListPage'
import LoanFilesDetailPage from './LoanFilesDetailPage'

@withRouter
@inject('authStore', 'dossiers', 'ui', 'picklist')
@observer
class LayoutDossier extends Component {

  clickFilter () {
    this.props.ui.setLocation('null') //Responsive design
    this.props.dossiers.list()
    this.props.dossiers.listFilterTypeDossier()
    this.props.history.push('/dossiers')
    this.props.authStore.level === 3 && this.props.ui.suggestions === null && this.props.picklist.listFilterBankResponse()
  }

  render() {
    return (
      <React.Fragment>
        <Filter>
          <div id="filter">
            <Search type="dossier" />
            <FilterDate />
            <button type="submit" className="button button-primary search-button" onClick={this.clickFilter.bind(this)}>
              Valider
            </button>
            <div className="dates-filter">
            <FilterOrder {...this.props}/>
            {this.props.ui.suggestions === null ?
              <FilterByTypeLoanFile {...this.props}/>
              :
              <Suggestions type="dossiers" {...this.props}/>
            }
              {this.props.authStore.level === 3 && this.props.ui.suggestions === null && <FilterBankResponse />}
            </div>
          </div>
        </Filter>
        <section className="wrapper-content for-mobile">
          <Message zone='Dossiers' close={true} />
          <Switch>
            <PrivateRoute exact path="/dossiers" component={LoanFilesListPage} {...this.props} />
            <PrivateRoute exact path="/dossiers/status/:typeId" component={LoanFilesListPage} {...this.props} />
            <PrivateRoute exact path="/dossiers/step/:stepId" component={LoanFilesListPage} {...this.props} />
            <PrivateRoute exact path="/dossiers/:loanFileId" component={LoanFilesDetailPage} {...this.props} />
          </Switch>
        </section>
      </React.Fragment>
    )
  }
}

export default LayoutDossier;
