import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  legalForm: {
    name: "legalForm",
    id: "legalForm",
    label: "Forme juridique de votre entreprise (SCI ou autres)",
    rules: "string",
    type: "text"
  },
  socialReason: {
    name: "socialReason",
    id: "socialReason",
    label: "Raison sociale (Nom) de votre entreprise",
    rules: "string",
    type: "text"
  },
  amountOverallInvestment: {
    name: "amountOverallInvestment",
    id: "amountOverallInvestment",
    label: "Montant approximatif de l’investissement global",
    rules: "string",
    type: "text"
  },
  amountContribution: {
    name: "amountContribution",
    id: "amountContribution",
    label: "Montant approximatif de votre apport",
    rules: "string",
    type: "text"
  },
  AreaLocal: {
    name: "AreaLocal",
    id: "AreaLocal",
    label: "Superficie développée de votre local (sol et niveaux)",
    rules: "string",
    type: "text"
  },
  valueWorks: {
    name: "valueWorks",
    id: "valueWorks",
    label: "Valeur des travaux (le cas échéant) que vous envisagez de réaliser",
    rules: "string",
    type: "text"
  },
  remarks: {
    name: "remarks",
    id: "remarks",
    label: "Remarques à nous faire sur votre projet",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class PremisesPurchasedBuilt extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default PremisesPurchasedBuilt;
