import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";

const plugins = { dvr: validatorjs };

const fields = {
  natureOne: {
    name: "natureOne",
    id: "natureOne",
    label: "Nature 1",
    rules: "string",
    type: "text"
  },
  juridicalNatureOne: {
    name: "juridicalNatureOne",
    id: "juridicalNatureOne",
    label: "Nature Juridique de la proprété 1",
    rules: "string",
    type: "text"
  },
  addressOne: {
    name: "addressOne",
    id: "addressOne",
    label: "Adresse 1",
    rules: "string",
    type: "text"
  },
  currentEstimateOne: {
    name: "currentEstimateOne",
    id: "currentEstimateOne",
    label: "Estimation actuelle 1",
    rules: "string",
    type: "text"
  },
  remainingCapitalOne: {
    name: "remainingCapitalOne",
    id: "remainingCapitalOne",
    label: "Caputal restant du 1",
    rules: "string",
    type: "text"
  },
  mortgageOne: {
    name: "mortgageOne",
    id: "mortgageOne",
    label: "Hypothèque oui/non 1",
    rules: "string",
    type: "text"
  },
  mortgageAmountOne: {
    name: "mortgageAmountOne",
    id: "mortgageAmountOne",
    label: "Montant hypothéqué 1",
    rules: "string",
    type: "text"
  },
  natureTwo: {
    name: "natureTwo",
    id: "natureTwo",
    label: "Nature 2",
    rules: "string",
    type: "text"
  },
  juridicalNatureTwo: {
    name: "juridicalNatureTwo",
    id: "juridicalNatureTwo",
    label: "Nature Juridique de la proprété 2",
    rules: "string",
    type: "text"
  },
  addressTwo: {
    name: "addressTwo",
    id: "addressTwo",
    label: "Adresse 2",
    rules: "string",
    type: "text"
  },
  currentEstimateTwo: {
    name: "currentEstimateTwo",
    id: "currentEstimateTwo",
    label: "Estimation actuelle 2",
    rules: "string",
    type: "text"
  },
  remainingCapitalTwo: {
    name: "remainingCapitalTwo",
    id: "remainingCapitalTwo",
    label: "Caputal restant du 2",
    rules: "string",
    type: "text"
  },
  mortgageTwo: {
    name: "mortgageTwo",
    id: "mortgageTwo",
    label: "Hypothèque oui/non 2",
    rules: "string",
    type: "text"
  },
  mortgageAmountTwo: {
    name: "mortgageAmountTwo",
    id: "mortgageAmountTwo",
    label: "Montant hypothéqué 2",
    rules: "string",
    type: "text"
  },
  natureThree: {
    name: "natureThree",
    id: "natureThree",
    label: "Nature 3",
    rules: "string",
    type: "text"
  },
  juridicalNatureThree: {
    name: "juridicalNatureThree",
    id: "juridicalNatureThree",
    label: "Nature Juridique de la proprété 3",
    rules: "string",
    type: "text"
  },
  addressThree: {
    name: "addressThree",
    id: "addressThree",
    label: "Adresse 3",
    rules: "string",
    type: "text"
  },
  currentEstimateThree: {
    name: "currentEstimateThree",
    id: "currentEstimateThree",
    label: "Estimation actuelle 3",
    rules: "string",
    type: "text"
  },
  remainingCapitalThree: {
    name: "remainingCapitalThree",
    id: "remainingCapitalThree",
    label: "Caputal restant du 3",
    rules: "string",
    type: "text"
  },
  mortgageThree: {
    name: "mortgageThree",
    id: "mortgageThree",
    label: "Hypothèque oui/non 3",
    rules: "string",
    type: "text"
  },
  mortgageAmountThree: {
    name: "mortgageAmountThree",
    id: "mortgageAmountThree",
    label: "Montant hypothéqué 3",
    rules: "string",
    type: "text"
  },
  natureFour: {
    name: "natureFour",
    id: "natureFour",
    label: "Nature 4",
    rules: "string",
    type: "text"
  },
  juridicalNatureFour: {
    name: "juridicalNatureFour",
    id: "juridicalNatureFour",
    label: "Nature Juridique de la proprété 4",
    rules: "string",
    type: "text"
  },
  addressFour: {
    name: "addressFour",
    id: "addressFour",
    label: "Adresse 4",
    rules: "string",
    type: "text"
  },
  currentEstimateFour: {
    name: "currentEstimateFour",
    id: "currentEstimateFour",
    label: "Estimation actuelle 4",
    rules: "string",
    type: "text"
  },
  remainingCapitalFour: {
    name: "remainingCapitalFour",
    id: "remainingCapitalFour",
    label: "Caputal restant du 4",
    rules: "string",
    type: "text"
  },
  mortgageFour: {
    name: "mortgageFour",
    id: "mortgageFour",
    label: "Hypothèque oui/non 4",
    rules: "string",
    type: "text"
  },
  mortgageAmountFour: {
    name: "mortgageAmountFour",
    id: "mortgageAmountFour",
    label: "Montant hypothéqué 4",
    rules: "string",
    type: "text"
  },
  natureFive: {
    name: "natureFive",
    id: "natureFive",
    label: "Nature 5",
    rules: "string",
    type: "text"
  },
  juridicalNatureFive: {
    name: "juridicalNatureFive",
    id: "juridicalNatureFive",
    label: "Nature Juridique de la proprété 5",
    rules: "string",
    type: "text"
  },
  addressFive: {
    name: "addressFive",
    id: "addressFive",
    label: "Adresse 5",
    rules: "string",
    type: "text"
  },
  currentEstimateFive: {
    name: "currentEstimateFive",
    id: "currentEstimateFive",
    label: "Estimation actuelle 5",
    rules: "string",
    type: "text"
  },
  remainingCapitalFive: {
    name: "remainingCapitalFive",
    id: "remainingCapitalFive",
    label: "Caputal restant du 5",
    rules: "string",
    type: "text"
  },
  mortgageFive: {
    name: "mortgageFive",
    id: "mortgageFive",
    label: "Hypothèque oui/non 5",
    rules: "string",
    type: "text"
  },
  mortgageAmountFive: {
    name: "mortgageAmountFive",
    id: "mortgageAmountFive",
    label: "Montant hypothéqué 5",
    rules: "string",
    type: "text"
  }
};

const hooks = {

  onSuccess(form) {
    form.stores.updateBloc(form)
  },
  onError(form) {
    console.log('onError', form.formName, form)
  }

};
validatorjs.useLang('fr');

class RealEstate extends MobxReactForm {
  stores;

  constructor(stores, formName) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
    this.formName = formName
  }
}

export default RealEstate;
