import MobxReactForm from "mobx-react-form";
import validatorjs from "validatorjs";
import dvr from 'mobx-react-form/lib/validators/DVR';

const plugins = { dvr: dvr(validatorjs) };

const fields = {
  id: {
    name: "id",
    id: "id",
    label: "id",
    type: "hidden"
  },
  Comment: {
    name: "Comment",
    id: "Comment",
    label: "Commentaire libre",
    rules: "string"
  }
};

const hooks = {
  onSuccess(form) {
    if(form.$("id").value){
      this.stores.dossiers.updateLoanFileNotes(form, form.$("id").value);
    } else {
      const id = this.stores.dossiers.getLoanFileId;
      this.stores.dossiers.createLoanFileNote(form, id);
    }

  },
  onError(form) {
    console.log('onError Dossiers', form);
  }
};
validatorjs.useLang('fr');

class LoanFilesUpdateNoteForm extends MobxReactForm {
  stores;

  constructor(stores) {
    super({ fields }, { plugins, hooks });
    this.stores = stores;
  }
}

export default LoanFilesUpdateNoteForm;
