import React, { Component } from 'react'
import { inject } from 'mobx-react'
import helpers from '../../../helpers'
import Message from '../../commons/Message'
import Dropzone from 'react-dropzone'
import _ from 'lodash'


@inject('picklist', 'authStore', 'dossiers', 'uploadForm')
class DocumentManagers extends Component {
  constructor(props) {
    super(props)
    this.state= {
      opened: false
    }
  }

  onHeaderClick = () => {
    this.setState({
      opened: !this.state.opened
    })
  }

  componentDidMount() {
    this.props.dossiers.lastNumber = ''
  }
  setLastNumber(number) {
    this.props.dossiers.lastNumber = number
  }
  onDrop = files => {
    this.props.uploadForm.$('LoanFileId').value = this.props.getLoanFileIdId
    let uploadField = this.props.uploadForm.$('Upload')
    if(!uploadField.state.extra.length){
      uploadField.state.extra = []
    }
    uploadField.state.extra = uploadField.state.extra.concat(files)
    uploadField.files  = uploadField.state.extra
    this.forceUpdate();
  }

  onFileDialog = field => e => {
    const files = _.map(e.target.files); // workaround to get all files
    this.onDrop(files);
  }

  removeFile = index => e =>{
    e.stopPropagation()
    let uploadField = this.props.uploadForm.$('Upload')
    uploadField.state.extra.splice(index, 1)
    uploadField.files  = uploadField.state.extra
    this.forceUpdate();
  }

  checkIfManagersEmpty = documentManagers => {
    for (let i = 0; i < documentManagers.length; i++) {
      if (documentManagers[i].documents.length){
        return false
      }
    }
    return true
  }

  render() {
    const { label, documentManagers, uploadForm, loading } = this.props
    const { loadingUpload } = this.props.dossiers
    const { opened } = this.state
    let contentClasses = 'section-content'
    if (opened) {
      contentClasses = contentClasses + ' open'
    }

    if (
      !this.props.uploadForm.$('LoanFileId').value &&
      this.props.getLoanFileIdId
    ) {
      this.props.uploadForm.$('LoanFileId').value = this.props.getLoanFileIdId
    }

    return (
      <React.Fragment>
        {documentManagers && (
          <section className={contentClasses} id="attachments">

            <label
              className="accordion__heading"
              htmlFor="accordion-piece-dossier"
              onClick={this.onHeaderClick}
            >
              <i className="prp-ext-attachment"></i>
              {label}
              {documentManagers[0] === undefined && loading && (
                <div className="loader visible"></div>
              )}
            </label>

            <div className="accordion__content">
              {this.props.authStore.level <= 6 &&
                this.props.authStore.level >= 4 && (
                  <React.Fragment>
                    <div className="uploadIntoDetailPage">
                      <div className="upload-block">
                        <Message zone="changePassword" close={true} />
                        {loadingUpload && (
                          <div className="loader visible">loader</div>
                        )}
                        <span className="label">
                          <Dropzone
                            onDrop={this.onDrop}
                            multiple
                          >
                            {({
                              getRootProps,
                              getInputProps,
                              isDragActive
                            }) => {
                              return (
                                <div {...getRootProps()} className={'dropzone'}>
                                  <span className="button button-primary input-file">
                                    Ajouter des fichiers
                                  </span>
                                  <input
                                    {...getInputProps()}
                                    {...uploadForm.$('Upload').bind()}
                                    onChange={this.onFileDialog(uploadForm.$('Upload'))}
                                  />
                                  {isDragActive ? (
                                    <span className="title">
                                      Drop files here...
                                    </span>
                                  ) : uploadForm.$('Upload').files ? (
                                    <div className="title">
                                      {uploadForm
                                        .$('Upload')
                                        .files.map((file, idx) => (
                                          <React.Fragment key={idx}>
                                            <div className={"file-name"} >{file.name}<div className="button remove-file" onClick={this.removeFile(idx)}>suppr.</div></div>
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  ) : (
                                    <span className="title">
                                      Glisser/déposer des fichiers ici
                                    </span>
                                  )}
                                </div>
                              )
                            }}
                          </Dropzone>
                        </span>
                      </div>
                      <button
                        className="button button-primary"
                        onClick={uploadForm.onSubmit}
                      >
                        Valider
                      </button>
                    </div>
                  </React.Fragment>
                )}
              {(this.checkIfManagersEmpty(documentManagers)) ? (
                <div className="empty-info no-border">Aucun document</div>
              ) : (
                <React.Fragment>
                  {documentManagers &&
                    Array.isArray(documentManagers) &&
                    documentManagers.map((documents, idx) => (
                      <React.Fragment key={idx}>
                        <div className="row-col-2">
                          {documents.documents.map((doc, idx) => {
                            return (
                              <React.Fragment key={idx}>
                                {this.props.dossiers &&
                                  this.props.dossiers.lastNumber !==
                                    doc.documentTypeId.substr(0, 2) &&
                                  this.props.dossiers.lastNumber !== '' && (
                                    <hr />
                                  )}
                                <div
                                  className={'element status-doc-' + doc.status}
                                >
                                  <span className="contact-name">
                                    {
                                      this.props.picklist.nameDocument[
                                        doc.documentTypeId
                                      ]
                                    }
                                  </span>
                                  <span className="contact-content">
                                    <i className="prp-global icon"></i>
                                    <span
                                      dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                                        doc.value
                                      )}
                                    />
                                  </span>
                                  {doc.note &&
                                    <span
                                      dangerouslySetInnerHTML={helpers.dangerouslySetInnerHTML(
                                        doc.note
                                      )}
                                    />
                                  }
                                </div>
                                {this.setLastNumber(
                                  doc.documentTypeId.substr(0, 2)
                                )}
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </React.Fragment>
                    ))}
                </React.Fragment>
              )}
            </div>
          </section>
        )}
      </React.Fragment>
    )
  }
}

export default DocumentManagers
